import React, { useEffect, useState } from "react"
import SweetAlert from 'react-bootstrap-sweetalert'
import { store } from "react-notifications-component"
import { useSelector } from "react-redux"

import back1 from "../../../assets/img/backgrounds/1back.jpg"
import { BackButton } from "../../../components/buttons/BackButton"
import { URL_TO_IMAGES } from "../../../config/api.config"
import { createPortfolio, recusaDoCliente } from "../../../services/portfolios/index"
import { cepMask, cnpjMask, phoneMask } from "../../../uteis/mask"
import logo from "./logo.jpg"

const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}

const targetLink = link => (link.includes('http') ? link : 'http://' + link)

export default ({ item, setLoading, status, runServices, carregandoStatus, id }) => {
  const user = useSelector(state => state.auth.user)
  const [isFornecedor, setIsFornecedor] = useState(false)
  const [businessHour, setBusinessHour] = useState([])

  const seguir = async (setMyFornecedor = false, type = null) => {
    setLoading(true)

    const dataFornecedor = {
      company_id: item.id,
      type: user.companies[0].profile === 2 ? "" : type,
    }

    await createPortfolio(dataFornecedor)
      .then(async response => {
        avisar("Sucesso", response.data.resposta.conteudo.message, "success")
        await runServices()
      })
      .catch(error => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              avisar("Ops!!", message, "danger")
            })
          })
          return
        }
        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const cancelarPedido = async () => {
    setLoading(true)
    await recusaDoCliente(status.id)
      .then(async response => {
        avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
        await runServices()
      })
      .catch(error => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              avisar("Ops!!", message, "danger")
            })
          })
          return
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const confirmar = async (type) => {
    seguir(true, type)
    setIsFornecedor(false)
  }
  const cancelar = () => {
    setIsFornecedor(false)
  }

  const configBusinessHours = (business_hours) => {
    if (business_hours.length > 0) {
      const oldHour = business_hours[0]
      setBusinessHour([
        { nome: "Segunda-feira", tipo: oldHour.week_day_status_0, de: oldHour.opening_0, ate: oldHour.closing_0 },
        { nome: "Terça-feira", tipo: oldHour.week_day_status_1, de: oldHour.opening_1, ate: oldHour.closing_1 },
        { nome: "Quarta-feira", tipo: oldHour.week_day_status_2, de: oldHour.opening_2, ate: oldHour.closing_2 },
        { nome: "Quinta-feira", tipo: oldHour.week_day_status_3, de: oldHour.opening_3, ate: oldHour.closing_3 },
        { nome: "Sexta-Feira", tipo: oldHour.week_day_status_4, de: oldHour.opening_4, ate: oldHour.closing_4 },
        { nome: "Sábado", tipo: oldHour.week_day_status_5, de: oldHour.opening_5, ate: oldHour.closing_5 },
        { nome: "Domingo", tipo: oldHour.week_day_status_6, de: oldHour.opening_6, ate: oldHour.closing_6 }
      ])
    }
  }

  useEffect(() => {
    if (item.business_hours) {
      configBusinessHours(item.business_hours)
    }
  }, [item.business_hours])

  return (
    <div className="bg-white">

      {(isFornecedor &&
        <SweetAlert warning showCancel
          confirmBtnText="Sim"
          confirmBtnBsStyle="outline-success"
          cancelBtnBsStyle="outline-warning"
          cancelBtnText="Não"
          title="Adicionar essa empresa como?"
          customButtons={
            <React.Fragment>
              <button className="btn btn-outline-danger m-2 mr-5" onClick={cancelar}>Cancelar</button>
              <button className="btn btn-primary m-2" onClick={() => confirmar('comprador')}>Cliente</button>
              {
                item.profile === '1' && (
                  <>
                    <button className="btn btn-primary m-2" onClick={() => confirmar('fornecedor')}>Fornecedor</button>
                    <button className="btn btn-success m-2" onClick={() => confirmar('ambos')}>Ambos</button>
                  </>
                )
              }
            </React.Fragment>
          }
        >
        </SweetAlert>
      )}

      <div className="profile-header">
        <div className="profile-header-cover" style={{ backgroundImage: `url(${localStorage.getItem('background-perfil-empresa') || back1})` }}></div>
        <div className="profile-header-content">

          <div className="companyName d-flex align-items-center justify-content-center flex-wrap">
            <div className="profile-header-img">
              <img src={item?.company_logo ? URL_TO_IMAGES + '/' + item?.company_logo.replace('public', '') : logo} alt="" />

            </div>
            <div className="ml-3">
              <h4 className="m-t-10 m-b-2">{item.company_name}</h4>
              <small>CNPJ: {cnpjMask(item.cnpj)}</small>
              <p className="m-b-5">
                {
                  item.companies_types && item.companies_types.map(type => (
                    <span key={type.id} className="badge badge-default badge-secondary m-3">{type.description}</span>
                  ))
                }
              </p>
              <p>
                {item.facebook && (
                  <button className="btn btn-light mr-1 btn-icon btn-circle btn-sm">
                    <a href={`${targetLink(item.facebook)}`} alt='Link do perfil da empresa no(a)' target='_blank' rel="noopener noreferrer">
                      <i className="fab fa-facebook-f"> </i>
                    </a>
                  </button>
                )}
                {item.twitter && (
                  <button className="btn btn-light ml-1 mr-1 btn-icon btn-circle btn-sm">
                    <a href={`${targetLink(item.twitter)}`} alt='Link do perfil da empresa no(a)' target='_blank' rel="noopener noreferrer">
                      <i className="fab fa-twitter"> </i>
                    </a>
                  </button>
                )}
                {item.instagram && (
                  <button className="btn btn-light ml-1 mr-1 btn-icon btn-circle btn-sm">
                    <a href={`${targetLink(item.instagram)}`} alt='Link do perfil da empresa no(a)' target='_blank' rel="noopener noreferrer">
                      <i className="fab fa-instagram"> </i>
                    </a>
                  </button>
                )}
                {item.linkedin && (
                  <button className="btn btn-light ml-1 mr-1 btn-icon btn-circle btn-sm">
                    <a href={`${targetLink(item.linkedin)}`} alt='Link do perfil da empresa no(a)' target='_blank' rel="noopener noreferrer">
                      <i className="fab fa-linkedin"> </i>
                    </a>
                  </button>
                )}
                {item.youtube && (
                  <button className="btn btn-light ml-1 btn-icon btn-circle btn-sm">
                    <a href={`${targetLink(item.youtube)}`} alt='Link do perfil da empresa no(a)' target='_blank' rel="noopener noreferrer">
                      <i className="fab fa-youtube"> </i>
                    </a>
                  </button>
                )}
              </p>
            </div>
          </div>

          <div className="profile-review">
            <BackButton />
            {item.whatsapp && (
              <a href={`https://api.whatsapp.com/send?phone=55${item.whatsapp.replace(/\D/g, '')}&text=`}
                target="_blank" rel="noopener noreferrer"
                className="btn btn-green mb-2 btn-icon btn-circle btn-lg">
                <i className="fab fa-whatsapp"></i>
              </a>
            )}
            {
              item.avg && (
                <h5> <i className="far fa-star"></i> {item.avg}</h5>
              )
            }
            {
              (carregandoStatus && id) ? (
                <button className="btn btn-xs btn-gray">Carregando status da solicitação...</button>
              ) : status.status === 1 ? (
                <button className="btn btn-xs btn-blue">Seguindo</button>
              ) : status.status === 0 ? (
                <button className="btn btn-xs btn-red" onClick={cancelarPedido}>Pendente</button>
              ) : id ? (<button className="btn btn-xs btn-yellow" onClick={() => parseInt(user.companies[0].profile) === 1 ? setIsFornecedor(true) : seguir()}>Seguir</button>) : null
            }

          </div>

        </div>

      </div>

      <h1 className="page-header">
        <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
      </h1>


      <div className="p-20">
        {
          item.economic_activities && item.economic_activities.map(active => (
            <span key={active.id} className="border-bottom btn btn-circle btn-default btn-sm m-b-5 m-r-5">{active.description}</span>
          ))
        }
      </div>
      <hr />
      <div className="p-20">
        <p className="m-r-15 text-inverse-lighter">
          {/* <strong>Endereço</strong>: Aurelio Rodrigues da Silva rg, Alameda Rio Negro, 1500 - Alphaville Industrial, Barueri - SP, 06454-040 */}
          {`
                        ${item.address ? item.address + ', ' : ''}
                        ${item.neighborhood ? item.neighborhood + ', ' : ''}
                        ${item.complement ? item.complement : ''}
                        ${item.city ? ' - ' + item?.city?.name + '/' : ''}${item.uf ? item?.uf?.sigla_uf + ', ' : ''}
                        ${item.postal_code ? cepMask(item.postal_code) : ''}
                    `}
        </p>
        <p className="m-r-15 text-inverse-lighter">
          {item.phone && (<>
            <i className="fa fa-phone-square-alt fa-fw fa-lg m-r-3"></i>Comercial: {phoneMask(item.phone)} |
          </>)}
          {item.financial_phone && (<>
            <i className="fa fa-phone-square-alt fa-fw fa-lg m-r-3"></i>Financeiro: {phoneMask(item.financial_phone)} |
          </>)}
          {item.email && (<>
            <i className="fa fa-envelope fa-fw fa-lg m-r-3"></i> {item.email} |
          </>)}
          {item.website && (<>
            <a href={item.website.includes('http') ? item.website : 'http://' + item.website}
              target="_blank" rel="noopener noreferrer"
            >
              <i className="fas fa-globe"></i> Site
            </a>
          </>)}
        </p>

      </div>
      <hr />
      <div className="pl-3">
        {
          businessHour.length > 0 && businessHour.map((hour, i) => (
            <span key={hour.nome} className="text-inverse-lighter"><strong>{hour.nome}:</strong> {hour.tipo === "Definir Horario" ? (hour.de ? 'de ' + hour.de + ' as ' + hour.ate : hour.tipo) : hour.tipo} {i + 1 !== businessHour.length ? ' | ' : ''}</span>
          ))
        }
      </div>
    </div>
  )
}
