import React from "react"
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

export function BackButton() {
  const history = useHistory();
  return (
    <Button
      color="primary"
      size="small"
      style={{
        width: "65px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 5px",
        position: "absolute",
        top: "0.5rem",
        right: "1rem",
      }}
      onClick={() => history.goBack()}
    >
      <i className="fa fa-arrow-left"></i>
      Voltar
    </Button>
  );
}
