import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'reactstrap';
import DropdownLanguage from './dropdown/language.jsx';
import DropdownMegaMenu from './dropdown/mega.jsx';
import DropdownProfile from './dropdown/profile.jsx';
import SearchForm from './search/form.jsx';

import { useCarrinho } from '../../hooks/useCarrinho.jsx';
import { PageSettings } from './../../config/page-settings.js';

function Header(props) {

	const [collapseMegaMenu, setCollapseMegaMenu] = useState(false)
	const [tooltipCotacaoOpen, setTooltipCotacaoOpen] = useState(false)
	const [cotacao, setCotacao] = useState(null)
  
  const { carrinhoTotal } = useCarrinho();

	const toggleMegaMenu = () => {
		setCollapseMegaMenu(!collapseMegaMenu)
	}

	const toggleTooltipCotacaoOpen = () => {
		setTooltipCotacaoOpen(!tooltipCotacaoOpen)
	}

	const setHasCotacao = () => {
		if (localStorage.getItem('cotacao-produtos')) {
			const dataJson = JSON.parse(localStorage.getItem('cotacao-produtos'))
			setCotacao(dataJson)
		} else {
			setCotacao(null)
		}
	}

	useEffect(() => {
		setHasCotacao()
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localStorage.getItem('cotacao-produtos')])

	return (
		<PageSettings.Consumer>
			{({ toggleMobileSidebar, toggleRightSidebar, toggleMobileRightSidebar, toggleMobileTopMenu, pageHeaderLanguageBar, pageHeaderMegaMenu, pageTwoSidebar, pageTopMenu, pageSidebar, pageSidebarToggled }) => (
				<div id="header" className="header navbar-inverse">
					<div className="navbar-header">
						{pageTwoSidebar && (
							<button type="button" className="navbar-toggle pull-left" onClick={toggleMobileRightSidebar}>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
						)}
						<Link to="/" className="navbar-brand">
							<img src={require("../../assets/img/logo-horizontal.svg")} />
						</Link>

						{pageTopMenu && pageSidebar && (
							<button type="button" className="navbar-toggle pt-0 pb-0 mr-0 collapsed" onClick={toggleMobileTopMenu}>
								<span className="fa-stack fa-lg text-inverse">
									<i className="far fa-square fa-stack-2x"></i>
									<i className="fa fa-cog fa-stack-1x"></i>
								</span>
							</button>
						)}
						{!pageSidebar && pageTopMenu && (
							<button type="button" className="navbar-togglea" onClick={toggleMobileTopMenu}>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
								<span className="icon-bar"></span>
							</button>
						)}

					</div>

					{pageHeaderMegaMenu && (
						<DropdownMegaMenu collapse={toggleMegaMenu} />
					)}

					<ul className="navbar-nav navbar-right">
						<SearchForm />
						{/*<DropdownNotification />*/}

						{pageHeaderLanguageBar && (
							<DropdownLanguage />
						)}
            
						{
							carrinhoTotal ? (
								<li className="d-flex align-items-center">
									<Link to={`/carrinho`}>
										<i id="TooltipCotacao" className="fa fa-shopping-cart"
											style={{ fontSize: "1.5rem", color: "#8896a1", cursor: "pointer" }}
										></i>
                    <span>{carrinhoTotal}</span>
									</Link>
								</li>
							) : null
						}

						{
							cotacao && (
								<li className="d-flex align-items-center">
									<Link to={`/cotacao/cotar/${cotacao[0].toShow.produto.company_id}`}>
										<i id="TooltipCotacao" className="fas fa-file-invoice"
											style={{ fontSize: "1.5rem", color: "#8896a1", cursor: "pointer" }}
										></i>
									</Link>
									<Tooltip placement="bottom" isOpen={tooltipCotacaoOpen} target="TooltipCotacao" toggle={toggleTooltipCotacaoOpen}>
										Cotação
										</Tooltip>
								</li>
							)
						}

						<DropdownProfile />

						{pageTwoSidebar && (
							<li className="divider d-none d-md-block"></li>
						)}

						{pageTwoSidebar && (
							<li className="d-none d-md-block">
								<Link to="/" onClick={toggleRightSidebar} className="f-s-14">
									<i className="fa fa-th"></i>
								</Link>
							</li>
						)}
					</ul>
					{pageSidebar && (
						<button type="button" className="navbar-toggle aqui" onClick={toggleMobileSidebar}>
							{
								!pageSidebarToggled ?
									<>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
										<span className="icon-bar"></span>
									</>
									:
									<>
										<i className="f-s-19 fal fa-times"></i>
									</>
							}


						</button>
					)}
				</div>
			)}
		</PageSettings.Consumer>
	)
}


export default Header;
