import { useCallback, useEffect, useState } from "react";
import { getCompanyType, getEconomicActivity } from "../../../services/companies";
import { getCities, getCountries, getUfs } from "../../../services/regions";

function useModalFiltro() {
    const [atividades, setAtividades] = useState([]);
    const [tipos, setTipos] = useState([]);
    const [paises, setPaises] = useState([]);
    const [estados, setEstados] = useState([]);
    const [municipios, setMunicipios] = useState([]);

    const [loading, setLoading] = useState(false);
    const [loadingUfs, setLoadingUfs] = useState(false);
    const [loadingMunicipios, setLoadingMunicipios] = useState(false);

    const pegaAtividades = async () => {
        setLoading(true);
        await getEconomicActivity()
            .then((response) => {
                setAtividades(response.data.resposta.conteudo.companiesType);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };
    const pegaTipos = async () => {
        setLoading(true);
        await getCompanyType()
            .then((response) => {
                setTipos(response.data.resposta.conteudo.companiesType);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoading(false));
    };
    const pegaPaises = async () => {
        await getCountries()
            .then((response) => {
                setPaises(response.data.resposta.conteudo.countries.data);
            })
            .catch((error) => console.log(error));
    };

    const pegaEstados = useCallback(async (idCountry) => {
        setLoadingUfs(true)
        await getUfs(idCountry)
            .then((response) => {
                setEstados(response.data.resposta.conteudo.ufs);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoadingUfs(false));
    }, []);
    const pegaMunicipios = useCallback(async (idUf) => {
        setLoadingMunicipios(true)
        await getCities(idUf, "all")
            .then((response) => {
                setMunicipios(response.data.resposta.conteudo.city);
            })
            .catch((error) => console.log(error))
            .finally(() => setLoadingMunicipios(false));
    }, []);

    useEffect(() => {
        pegaAtividades();
        pegaTipos();
        pegaPaises();
    }, []);

    return {
        atividades,
        tipos,
        paises,
        estados,
        municipios,
        loading,
        loadingUfs,
        loadingMunicipios,
        pegaEstados,
        pegaMunicipios
    }
}

export {
    useModalFiltro
}