import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  Modal,
  ModalHeader,
  Button,
  ModalFooter,
  ModalBody,
  Input,
  Row,
} from "reactstrap";
import Loader from "../../../components/loader";
import { setTypeRequested } from "../../../store/pesquisa/action";
import { orderBy } from "../../../uteis";
import { useModalFiltro } from "../hooks/useModalFiltro";

import "./style.css";

const ModalFiltro = ({
  modal,
  toggle,
}) => {
  const { params: oldParams } = useSelector((state) => state.search);
  const [params, setParams] = useState(oldParams);

  const dispatch = useDispatch();

  const {
    atividades,
    tipos,
    paises,
    estados,
    municipios,
    loading,
    loadingUfs,
    loadingMunicipios,
    pegaEstados,
    pegaMunicipios
  } = useModalFiltro();

  const newParams = (newParams = {}) => {    
    dispatch(setTypeRequested({...oldParams, ...newParams}));
  }

  useEffect(() => {
    const pais = paises.find(pais => pais.namePortuguese.toLowerCase() === oldParams?.pais) || {}
    if (pais?.id) {
      pegaEstados(pais?.id)
    }
    setParams({
      ...oldParams,
      atividade: !!oldParams?.atividade ? { description: oldParams?.atividade?.toUpperCase()} : null,
      tipo: oldParams.tipo?.map(tipo => {
        return { description: tipo }
      }),
      pais,
      uf: '',
      cidade: '',
    })
  }, [oldParams, paises, pegaEstados]);
 
  useEffect(() => {
    if (estados.length > 0 && oldParams?.uf) {
      const uf = estados.find(uf => uf.sigla_uf.toLowerCase() === oldParams?.uf) || {}
      if (uf?.id) {
        pegaMunicipios(uf?.id)
      }
      setParams({
        ...params,
        uf,
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estados, oldParams.uf, pegaMunicipios]);

  useEffect(() => {
    if (municipios.length > 0 && oldParams?.cidade) {
      setParams({
        ...params,
        cidade: municipios.find(mun => mun.name.toLowerCase() === oldParams?.cidade),
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.uf, municipios, oldParams.cidade]);

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className={"modal-dialog modal-dialog-right "}
    >
      <Loader loading={loading} />
      <ModalHeader toggle={toggle}>Pesquisa avançada</ModalHeader>
      <ModalBody>
        <Row className="mt-2 mb-2">
          <div className="col-md-12">
            <div className="form-group">
              <label className="opacity-5 mb-1">Atividade econômica</label>
              {atividades && (
                <Select
                  value={params?.atividade}
                  options={orderBy(atividades, "label")}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(event) => {
                    setParams({ ...params, atividade: event ? event : "" });
                  }}
                  getOptionValue={(option) => option["description"]}
                  getOptionLabel={(option) => `${option.description}`}
                />
              )}
            </div>
          </div>
          <div className="col-md-12">
            <label className="opacity-5 mb-1">Tipo da empresa</label>
            {tipos && (
              <Select
                value={params?.tipo?.length > 0 ? params?.tipo : []}
                options={orderBy(tipos, "label")}
                name="colors"
                isMulti
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(event) => {
                  setParams({ ...params, tipo: event ? event : [] });
                }}
                getOptionValue={(option) => option["description"]}
                getOptionLabel={(option) => `${option.description}`}
              />
            )}
          </div>
          <hr />
          <div className="col-md-12">
            <div className="form-group">
              <label className="opacity-5 mb-1">Pais</label>
              <Input
                type="select"
                name="select"
                className="form-round"
                value={params?.pais?.id}
                onChange={(event) => {
                  pegaEstados(event.target.value);
                  setParams({
                    ...params,
                    pais: paises.find(
                      (pais) => pais.id === parseInt(event.target.value)
                    ),
                  });
                }}
              >
                <option>Todos</option>
                {paises &&
                  paises.map((pais) => (
                    <option key={pais.id} value={pais.id}>
                      {pais.namePortuguese}
                    </option>
                  ))}
              </Input>
            </div>
          </div>
          <div className="col-md-3">
            <div className="form-group">
              <label className="opacity-5 mb-1">Estado</label>
              <Input
                type="select"
                name="select"
                className="form-round"                
                value={params?.uf?.id || params?.uf}
                onChange={(event) => {
                  pegaMunicipios(event.target.value);
                  setParams({
                    ...params,
                    uf: estados.find(
                      (estado) => estado.id === parseInt(event.target.value)
                    ),
                  });
                }}
                disabled={estados.length === 0}
              >
                {loadingUfs ? (
                  <option>Carregando...</option>
                ) : (
                  <>
                    <option>Todos</option>
                    {estados &&
                      estados.map((estado) => (
                        <option key={estado.id} value={estado.id}>
                          {estado.name}
                        </option>
                      ))}
                  </>
                )}
              </Input>
            </div>
          </div>
          <div className="col-md-9">
            <div className="form-group">
              <label className="opacity-5 mb-1">Cidade</label>
              <Input
                type="select"
                name="select"
                className="form-round"                
                value={params?.cidade?.id || params?.cidade}
                onChange={(event) => {
                  setParams({
                    ...params,
                    cidade: municipios.find(
                      (municipio) =>
                        municipio.id === parseInt(event.target.value)
                    ),
                  });
                }}
                disabled={municipios.length === 0}
              >
                {loadingMunicipios ? (
                  <option>Carregando...</option>
                ) : (
                  <>
                    <option>Todos</option>
                    {municipios &&
                      municipios.map((municipio) => (
                        <option key={municipio.id} value={municipio.id}>
                          {municipio.name}
                        </option>
                      ))}
                  </>
                )}
              </Input>
            </div>
          </div>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            toggle();
            newParams({
              atividade: params?.atividade?.description?.toLowerCase() || null,
              cidade: params?.cidade?.name?.toLowerCase() || null,
              uf: params?.uf?.sigla_uf?.toLowerCase() || null,
              pais: params?.pais?.namePortuguese?.toLowerCase() || null,
              tipo: params?.tipo?.map((tipo) => tipo.description) || null,
            });
          }}
        >
          Filtrar
        </Button>{" "}
        <Button
          color="danger"
          onClick={() => {
            toggle();            
            newParams({
              atividade: null,
              cidade: null,
              uf: null,
              pais: null,
              tipo: null,
            });
          }}
        >
          Limpar
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ModalFiltro;
