import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BackButton } from "../../components/buttons/BackButton";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import { createImages, updateImages } from "../../services/service";
import { VariationName } from "../variacao/components";
import { useVariacao } from "../variacao/hooks/useVariacao";
import VariationListItem from "./components/VariationListItem";
import ModalServicoVariacaoForm from "./form";
import { useVariacaoServico } from "./hooks/useVariacaoServico";

export default function VariacaoServico() {
  const [deletar, setDeletar] = useState(null);
  const [valores, setValores] = useState({
    variations: [{}],
    price: "",
    photos: [],
  });
  const [modalServicoVariacao, setModalServicoVariacao] = useState(false);

  const {
    params: { id },
  } = useRouteMatch();

  const { variacoes } = useVariacao();
  const {
    servico,
    servicoVariacoes,
    getServicoVariacoes,
    createVariationsServices,
    updateVariationsServices,
  } = useVariacaoServico({ id });

  const contextType = useContext(PageSettings);

  const history = useHistory();

  const confirmar = async () => {
    setDeletar(null);

    await handleSubmit(null, true);
  };

  const resetForm = () => {
    setValores({
      variations: [{}],
      price: "",
      photos: [],
    });
  };
  const toggleModalServicoVariacao = () => {
    setModalServicoVariacao(!modalServicoVariacao);
  };

  const handleSubmit = async (event, isDelete) => {
    if (event) {
      event.preventDefault();
    }

    let price = valores.price;
    if (typeof price === "string") {
      const newPrice = valores.price.split(",");
      newPrice[0] = newPrice[0].replace("R$ ", "").replace(".", "");
      price = Number(newPrice.join("."));
    }

    const filesOrder = valores.photos;

    const formdatData = new FormData();
    formdatData.append("price", price);
    formdatData.append("service_id", servico.id);

    const currentImages = {
      _method: valores.edit ? "put" : "post",
      is_variation: 1,
      current_images: [],
      current_images_2: [],
    };

    filesOrder.forEach(async (file, index) => {
      if (file?.id) {
        currentImages.current_images.push(`id: ${file.id}, order: ${index}`);
        return;
      }
      file.order = index;
      if (servico.id) {
        const newImages = new FormData();
        newImages.append("service_id", servico.id);
        newImages.append("images[]", file);
        newImages.append("is_variation", 1);
        newImages.append("variation_id", valores.id);
        if (valores.id) {
          await createImages(newImages).then((response) => {
            currentImages.current_images.push(
              `id: ${response.data.resposta.conteudo.images[0].id}, order: ${file.order}`
            );
          });
        }
      }
      formdatData.append("images[]", file);
    });

    valores.variations.forEach(async (vari, index) => {
      if ("variation_id" in vari) {
        formdatData.append("variations[]", vari.variation_id);
        return;
      }
      formdatData.append("variations[]", vari.id);
    });

    for (let pair of formdatData.entries()) {
      if (pair[1] === "null" || pair[1] === null) {
        formdatData.delete(pair[0]);
      }
    }

    formdatData.append("active", 1);

    if (isDelete) {
      formdatData.append("active", 0);
      updateVariationsServices(valores.id, formdatData, async () => {
        await getServicoVariacoes();
      });
      return;
    }

    if (valores.edit) {
      await updateImages(currentImages, servico.id).then((response) => {
        updateVariationsServices(valores.id, formdatData, async () => {
          await getServicoVariacoes();
          toggleModalServicoVariacao();
        });
      });
      return;
    }

    createVariationsServices(formdatData, async () => {
      await getServicoVariacoes();
      toggleModalServicoVariacao();
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={() => setDeletar(null)}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}

      <TitlePage
        title={`Variações do Serviço: ${servico?.name}`}
        desc="Aqui você adiciona variações ao Serviço."
        icon="fas fa-box"
      />

      <BackButton />

      <div className="row">
        <div className="col-md-12">
          <Row className="justify-content-end mt-3">
            {modalServicoVariacao && (
              <ModalServicoVariacaoForm
                open={modalServicoVariacao}
                toggle={toggleModalServicoVariacao}
                handleSubmit={handleSubmit}
                variacoes={variacoes}
                valores={valores}
                setValores={setValores}
              />
            )}
            <Col md="4" lg="4" xl="2">
              <button
                className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
                onClick={() => {
                  resetForm();
                  toggleModalServicoVariacao();
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>
        </div>
        <div className="col-md-12">
          {servicoVariacoes?.data && servicoVariacoes?.data.length > 0 ? (
            servicoVariacoes.data.map((servVariation) => (
              <VariationListItem
                key={servVariation.id}
                servVariation={servVariation}
                callbackEdit={() => {
                  setValores({
                    ...servVariation,
                    edit: true,
                    variations: servVariation?.services_variations,
                    photos: servVariation?.services_images || [],
                  });
                  toggleModalServicoVariacao();
                }}
                callbackDelete={() => {
                  setValores({
                    ...servVariation,
                    edit: true,
                    variations: servVariation?.services_variations,
                    photos: [],
                  });
                  setDeletar({ ...servVariation, active: 0 });
                }}
              />
            ))
          ) : (
            <VariationName>Nenhuma variação criada!</VariationName>
          )}
        </div>
      </div>
    </div>
  );
}
