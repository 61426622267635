import React from "react";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import DropFiles from "../../components/dropFiles";
import { moneyMask } from "../../uteis/mask";
import { VariationField } from "./components/VariationField";

const ModalProdutoVariacaoForm = ({
  open,
  toggle,
  variacoes,
  handleSubmit,
  valores,
  setValores,
}) => {
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Adicionar nova Variação ao serviço</ModalHeader>
      <ModalBody>
        <Row className="mt-2 mb-2">
          <Form style={{ width: "100%" }}>
            {valores.variations.map((_, idx) => (
              <VariationField
                index={idx}
                variacoes={variacoes}
                valores={valores}
                setValores={setValores}
              />
            ))}

            <div className="form-group">
              {valores.variations.length < variacoes.length ? (
                <Button
                  onClick={() =>
                    setValores({
                      ...valores,
                      variations: [...valores.variations, {}],
                    })
                  }
                >
                  Adicionar Variação
                </Button>
              ) : null}
            </div>

            <div className="form-group">
              <DropFiles
                setFiles={(files) => setValores({ ...valores, photos: files })}
                files={valores.photos}
                setLoading={() => {}}
                type="produto"
                valores={valores}
              />
            </div>

            <div className="form-group">
              <label className="opacity-5 mb-1">Preço</label>
              <input
                placeholder="Preço padrão"
                type="text"
                value={moneyMask(valores.price, true)}
                onChange={(e) =>
                  setValores({
                    ...valores,
                    price: moneyMask(e.target.value),
                  })
                }
                className="form-control"
              />
            </div>
          </Form>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button type="submit" onClick={handleSubmit} color="success">
          Salvar
        </Button>{" "}
        <Button color="danger" onClick={toggle}>
          Cancelar
        </Button>{" "}
      </ModalFooter>
    </Modal>
  );
};

export default ModalProdutoVariacaoForm;
