import React, { useState, useEffect, useContext } from "react";
import Loader   from "../../components/loader";
import Comentario from "../../components/comentarios/comentario"
import {PageSettings} from "../../config/page-settings"

import ModalResponder from "./modal/modalResponder"
import {Link} from "react-router-dom"
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Panel,
  PanelFooter
} from "../../components/panel/panel.jsx";
import TitlePage      from "../../components/pages/title"
import {Row, Col} from "reactstrap"
const Conversa = ( props ) => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const [activeTab, setActiveTab] = useState("1");
  const [modalProduto, toggleModal] = useState(false);
  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
  
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return ()=>{
      contextType.handleSetPageContentFullHeight(false);
      contextType.handleSetPageContentFullWidth(false);
    }
  }, []);

  const toggleModalResponder = () =>{
    toggleModal(!modalProduto)
  }

  return (
    <>
    <Loader loading={loading}/>
    <ModalResponder  modalProduto={modalProduto} toggleModal={toggleModalResponder} /> 
    <TitlePage icon="far fa-comments" title="Avaliação cliente" desc="Gerencie seus comentarios" />
    <Row>
      <Col sm={12}>
      <Panel theme="default" className="bg-light">
            <div className="bg-white">
            <p className="p-15 pb-0" style={{fontWeight: "bold"}}>Avaliação</p>

              <Comentario  />
              <hr />
              <div className="well p-10">
                <div className="alert alert-aqua">
                <p className="text-black text-bold">
                    Considerações finais - <span className="text-muted">Será liberao para postagem em 29/01/2021</span></p>
                    Aenean mollis arcu sed turpis accumsan dignissim. Etiam vel tortor at risus tristique convallis. Donec adipiscing euismod arcu id euismod. Suspendisse potenti. Aliquam lacinia sapien ac urna placerat, eu interdum mauris viverra.
                </div>
              </div>

              </div>
							<PerfectScrollbar className="chats" style={{height: '360px'}} options={{suppressScrollX: true}}>
                <h4 className="mt-3">Chat - Converse com a empresa</h4>
                <p className="text-muted">Chat liberado até 22/02/2021</p>
                <hr />
								<div className="left">
									<span className="date-time">ontem as 11:23pm</span>
									<Link  className="name">Fulano de Tal</Link>
									<Link  className="image"><img alt=""  src="/assets/img/user/user-13.jpg"/></Link>
									<div className="message">
										Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat. Praesent mattis interdum arcu eu feugiat.
									</div>
								</div>
								<div className="right">
									<span className="date-time">08:12am</span>
									<Link  className="name"><span className="label label-primary">Eu</span></Link>
									<Link  className="image"><img alt="" src="/assets/img/user/user-13.jpg" /></Link>
									<div className="message">
										Nullam posuere, nisl a varius rhoncus, risus tellus hendrerit neque.
									</div>
								</div>


							</PerfectScrollbar>

							<PanelFooter>
								<form name="send_message_form" data-id="message-form">
									<div className="input-group">
										<input type="text" className="form-control" name="message" placeholder="Sua mensagem..." />
										<span className="input-group-append">
											<button className="btn btn-green" type="button"><i className="fa fa-paper-plane"></i></button>
										</span>
									</div>
								</form>
							</PanelFooter>
						</Panel>
      </Col>
    </Row>
  </>

  );
};

export default Conversa;
