import { useCallback, useEffect, useState } from "react";
import { getPaymentCompanyMethods, getPaymentMethods, selectPaymentMethod } from "../../../services/order";
import { notification } from "../../../uteis/notifications";

export function useFormasPagamento({ companyId }) {
  const [loading, setLoading] = useState(true);

  const [formasPagamento, setFormasPagamento] = useState([]);
  const [formasPagamentoEmpresa, setFormasPagamentoEmpresa] = useState([]);

  const pegaFormasPagamentos = useCallback(async () => {
    setLoading(true);
    await getPaymentMethods()
      .then((response) => {
        const formas = response.data.resposta.conteudo.paymentMethods;
        setFormasPagamento(formas)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  const pegaFormasPagamentosEmpresa = useCallback(async () => {
    setLoading(true);
    await getPaymentCompanyMethods(companyId)
      .then((response) => {
        const formas = response.data.resposta.conteudo.paymentCompanyMethods;
        setFormasPagamentoEmpresa(formas)
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [companyId]);

  const selecionaFormaPagamento = async (formaId) => {
    setLoading(true);
    await selectPaymentMethod({ id: formaId })
      .then(async (response) => {
        const pedido = response.data.resposta.conteudo;        
        pegaFormasPagamentosEmpresa();
        notification("Sucesso", pedido.message, "success");
      })
      .catch((error) => notification("Erro", "Não foi possível cancelar esse pedido.", "danger"));
  };

  useEffect(() => {
    if (companyId) return;
    
    pegaFormasPagamentos();
  }, [companyId, pegaFormasPagamentos]);

  useEffect(() => {
    pegaFormasPagamentosEmpresa();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pegaFormasPagamentosEmpresa]);

  return { selecionaFormaPagamento, formasPagamento, formasPagamentoEmpresa, loading }
}
