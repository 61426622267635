import { call, put, takeEvery, all, fork } from "redux-saga/effects";
import * as types from "./types";
import { advancedSearch } from "../../services/general";
import { URL_TO_IMAGES } from "../../config/api.config";

function clean(obj) {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === []
    ) {
      delete obj[propName];
    }
  }
  return obj;
}

function getResultedSeach(action) {
  const params = action.payload;
  return new Promise((resolve, reject) => {
    advancedSearch(clean(params))
      .then((response) => {
        const { companies, products, services } =
          response.data.resposta.conteudo.data;
        const image = (path) =>
          path ? URL_TO_IMAGES + "/" + path.replace("public", "") : "";
        const imagemProd = (images) =>
          images[0]?.path
            ? `${URL_TO_IMAGES}/${images
                .find((img) => img.featured_image === 1)
                ?.path.replace("public", "")}`
            : "";
        switch (params.pesquisar) {
          case "clientes":
            return resolve({
              ...companies,
              data: companies.data.map((item) => ({
                ...item,
                tipo: "empresa",
                imagem: image(item.company_logo),
              })),
            });

          case "produtos":
            return resolve({
              ...products,
              data: products.data.map((item) => ({
                ...item,
                tipo: "produto",
                imagem: imagemProd(item.images),
              })),
            });
          case "servicos":
            return resolve({
              ...services,
              data: services.data.map((item) => ({
                ...item,
                tipo: "servico",
                imagem: imagemProd(item.images),
              })),
            });
          default:
            return resolve({
              ...companies,
              data: companies.data.map((item) => ({
                ...item,
                tipo: "empresa",
                imagem: image(item.company_logo),
              })),
            });
        }
      })
      .catch((error) => console.log(reject(error)));
  });
}

function* tryIt(action) {
  try {
    const result = yield call(getResultedSeach, action);
    yield put({ type: types.SET_SEARCH_DATA_SUCCESSED, payload: result });
  } catch (error) {}
}

function* currentPage(action) {
  try {
    yield put({
      type: types.SET_SEARCH_PAGE_PARAMS_SUCCESS,
      payload: action.payload,
    });
  } catch (error) {}
}

function* watch() {
  yield takeEvery(types.SET_SEARCH_DATA_REQUESTED, tryIt);
}

function* watchSetType() {
  yield takeEvery(types.SET_SEARCH_TYPE_PARAMS, setType);
}

function* watchSetPage() {
  yield takeEvery(types.SET_SEARCH_PAGE_PARAMS, currentPage);
}

function* setType(action) {
  yield put({
    type: types.SET_SEARCH_TYPE_PARAMS_SUCCESS,
    payload: action.payload,
  });
}

function* dataFronSeach() {
  yield all([
    yield fork(watch),
    yield fork(watchSetType),
    yield fork(watchSetPage),
  ]);
}

export default dataFronSeach;
