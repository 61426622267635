import React, { useContext, useEffect, useState } from "react";
import Loader from "../../components/loader";
import ProdutoModal from "./modal/novoProduto";

import { PageSettings } from "../../config/page-settings";
import Header from "./parts/header";
import Produto from "./parts/produto";
import Sobre from "./parts/sobre";

import { store } from "react-notifications-component";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { getCompanie } from "../../services/companies";
import { createEvaluations } from "../../services/evaluations";
import { getProduct } from "../../services/product/index";
import { getService } from "../../services/service";
import { getCompanie as getCompanieStore } from "../../store/auth/action";
import { orderBy } from '../../uteis/index';
const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}

const ProdutosSingle = (props) => {

  const { type } = props
  const contextType = useContext(PageSettings)
  const [loading, setLoading] = useState(false)
  const [modalProduto, toggleModal] = useState(false)
  const [item, setItem] = useState({})
  const [empresa, setEmpresa] = useState({})
  const dispatch = useDispatch()
  const { user, current_companie } = useSelector(state => state.auth)

  const productId = props.match.params.id

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getItem = async () => {
    setLoading(true)
    const itemId = productId

    const itemService = type === "servico" ? getService : getProduct

    await itemService(itemId)
      .then(response => {
        const data = response.data.resposta.conteudo[type === "servico" ? 'service' : 'product']
        if (data.images.length > 0) {
          data.images = orderBy(data.images, 'order')
        }

        let variations = [];
        if (data?.variations?.length) {
          const keyVariations = type === "servico" ? "variations_service" : "variations_product"
          const keyVariationsItemId = type === "servico" ? "variation_service_id" : "variation_product_id"
          const keyImagesVariations = type === "servico" ? "services_images" : "products_images"

          variations = data.variations.map(prodVar => {
            const idProdVar = prodVar.id;
            const images = prodVar?.[keyImagesVariations]
              .filter(img => {
                const imgVariation = img?.[keyVariations]
                return imgVariation?.[keyVariationsItemId] === idProdVar;
              });

            return {
              ...prodVar,
              products_images: images,
              images
            }
          })
        }

        setItem({
          ...data,
          variations
        })
        getEmpresa(data.company_id)
      })
      .catch(error => {
        if (error.response?.data?.resposta?.conteudo?.message) {
          avisar("Ops!!", error.response?.data?.resposta?.conteudo?.message, "danger")
          return
        }
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              avisar("Ops!!", message, "danger")
            })
          })
          return
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getEmpresa = async (empresaId) => {
    setLoading(true)
    await getCompanie(empresaId).then(response => {
      setEmpresa(response.data.resposta.conteudo.empresa)
    })
      .catch(error => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              avisar("Ops!!", message, "danger")
            })
          })
          return
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const createComent = async ({ title, comment, stars }, callback) => {
    if (current_companie.id === empresa.id)
      return avisar("Você não pode avaliar sua própria empresa", "Tente avaliando outra empresa", "danger")
    const input = {
      customer_id: user.id,
      [type === "servico" ? "service_id" : "product_id"]: productId,
      title: title,
      description: comment,
      // rating: String(stars),
      // score: String(stars),
      status: "ACTIVE",
      stars: String(stars)
    }
    if (!comment) return avisar("Texto do comentário obrigatório.", "Texto não pode ser vazio", "danger")

    await createEvaluations(input)
      .then(async response => {
        avisar("Comentário adicionado", "Comentário adicionado com sucesso.", "success")
        if (callback) callback()
        await getItem()
      })
      .catch(err => {
        if (err && err.response && err.response.data?.resposta?.conteudo?.message) {
          avisar("Algo deu errado!", err.response.data.resposta.conteudo.message, "danger")
        }
      })
  }

  useEffect(() => {
    contextType.closeMobileSiderBar()
    contextType.handleSetPageContentFullHeight(true)
    contextType.handleSetPageContentFullWidth(true)
    window.scrollTo(0, 0)
    loadData()
    getItem()

    return () => {
      contextType.handleSetPageContentFullHeight(false)
      contextType.handleSetPageContentFullWidth(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleModalProduto = () => {
    toggleModal(!modalProduto)
  }
  const loadData = () => {

    if (user)
      dispatch(getCompanieStore(user.companies[0].id))
  }

  return (
    <>
      <Loader loading={loading} />
      <ProdutoModal modalProduto={modalProduto} toggleModal={toggleModalProduto} />
      <div className="profile mb-1 bg-white">
        <Header type={type} produto={item} empresa={empresa} />

        {/* <MaisProdutos type={type} /> */}
      </div>
      <Produto type={type} produto={item} empresa={empresa} openModal={toggleModal} />
      <Sobre
        type={type}
        produto={item}
        createComent={createComent}
        loadingAvaliacoes={loading}
        getItem={getItem}
        avisar={avisar}
        setLoading={setLoading}
        loading={loading}
      />
    </>
  );
};

export default withRouter(ProdutosSingle);
