import { useCallback, useEffect, useState } from "react";
import {
  createVariations,
  getVariation,
  getVariations,
  updateVariations,
} from "../../../services/variations";
import { notification } from "../../../uteis/notifications";

export const initialVariacaoValues = {
  name: "",
  options: [],
};
export function useVariacao() {
  const [loading, setLoading] = useState(false);
  const [variacao, setVariacao] = useState(initialVariacaoValues);
  const [variacoes, setVariacoes] = useState([]);  

  const getVariacao = useCallback(async (variationId) => {
    const variationApi = await getVariation(variationId);
    setVariacao(variationApi.data.resposta.conteudo.variation);
  }, []);

  const getVariacoes = useCallback(async () => {
    const variationsApi = await getVariations();
    setVariacoes(variationsApi.data.resposta.conteudo.variations.data);
  }, []);

  const handleSubmit = async (values) => {
    return await (values.id ? updateVariations(values) : createVariations(values)).then(
      (response) => {
        const msg = response.data?.resposta?.conteudo?.message;

        notification("Sucesso", msg, "success")
        setVariacao(initialVariacaoValues);
        getVariacoes();
      }
    );
  };

  useEffect(() => {
    getVariacoes();
  }, [getVariacoes]);

  return {
    variacao,
    variacoes,
    setVariacao,
    setVariacoes,
    getVariacao,
    getVariacoes,
    handleSubmit,
  };
}
