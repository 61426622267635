import { URL_TO_IMAGES } from "../config/api.config";

export const prepararTela = () =>{
    document.body.classList.add("index-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
      document.body.classList.remove("sidebar-collapse");
    };
}

export const getBase64Image = function (img) {
    return new Promise( (resolve, reject) =>{
        
        const image  = new Image();
        image.crossOrigin = "Anonymous";
        image.src = img;
        image.onload = function(){
            var canvas = document.createElement("canvas");
            canvas.width = image.width;
            canvas.height = image.height;
            var ctx = canvas.getContext("2d");
            ctx.drawImage(image, 0, 0);
            const data = canvas.toDataURL("image/png")
            return resolve(data);
        }
        image.onerror = ()=>{
            reject("um erro ocorreu ao carregar iagem")
        }

    } )

  }


export const validaCpfCnpj = function validaCpfCnpj(val) {
  if (val.length === 14) {
      var cpf = val.trim();
   
      cpf = cpf.replace(/\./g, '');
      cpf = cpf.replace('-', '');
      cpf = cpf.split('');
      
      var v1 = 0;
      var v2 = 0;
      var aux = false;
      
      for (var i = 1; cpf.length > i; i++) {
          if (cpf[i - 1] != cpf[i]) {
              aux = true;   
          }
      } 
      
      if (aux === false) {
          return false; 
      } 
      
      for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
          v1 += cpf[i] * p; 
      } 
      
      v1 = ((v1 * 10) % 11);
      
      if (v1 === 10) {
          v1 = 0; 
      }
      
      if (v1 != cpf[9]) {
          return false; 
      } 
      
      for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
          v2 += cpf[i] * p; 
      } 
      
      v2 = ((v2 * 10) % 11);
      
      if (v2 === 10) {
          v2 = 0; 
      }
      
      if (v2 != cpf[10]) {
          return false; 
      } else {   
          return true; 
      }
  } else if (val.length === 18) {
      var cnpj = val.trim();
      
      cnpj = cnpj.replace(/\./g, '');
      cnpj = cnpj.replace('-', '');
      cnpj = cnpj.replace('/', ''); 
      cnpj = cnpj.split(''); 
      
      var v1 = 0;
      var v2 = 0;
      var aux = false;
      
      for (var i = 1; cnpj.length > i; i++) { 
          if (cnpj[i - 1] != cnpj[i]) {  
              aux = true;   
          } 
      } 
      
      if (aux === false) {  
          return false; 
      }
      
      for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
          if (p1 >= 2) {  
              v1 += cnpj[i] * p1;  
          } else {  
              v1 += cnpj[i] * p2;  
          } 
      } 
      
      v1 = (v1 % 11);
      
      if (v1 < 2) { 
          v1 = 0; 
      } else { 
          v1 = (11 - v1); 
      } 
      
      if (v1 != cnpj[12]) {  
          return false; 
      } 
      
      for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
          if (p1 >= 2) {  
              v2 += cnpj[i] * p1;  
          } else {   
              v2 += cnpj[i] * p2; 
          } 
      }
      
      v2 = (v2 % 11); 
      
      if (v2 < 2) {  
          v2 = 0;
      } else { 
          v2 = (11 - v2); 
      } 
      
      if (v2 != cnpj[13]) {   
          return false; 
      } else {  
          return true; 
      }
  } else {
      return false;
  }
}

export const orderBy = (items, orderBy) => {
    return items.sort(function (a, b) {
        if (a[orderBy] > b[orderBy]) {
            return 1;
        }
        if (a[orderBy] < b[orderBy]) {
            return -1;
        }
        return 0;
    });
}

export const convertIntToFloadDot = value => {
    value = value.toString().split('')
    value.splice(value.length - 2, 0, '.')
    return parseFloat(value.join(''))
}

export const linkImage = (image) => {
  return URL_TO_IMAGES + '/' + image.replace('public', '')
}
