import React, { useEffect, useState } from 'react'
import { Button } from 'reactstrap'
import DropFiles from "../../../components/dropFiles"
import CollapseItem from "../../../components/itemAcoddion"
import VideoEmbed from "../../../components/videoEmbed"
import BasicInfo from "./basicInfo"
import Complemento from "./complemento"
import Especificacao from "./especificao"

import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'

import { useHistory } from "react-router-dom"

import { useCallback } from 'react'
import { store } from 'react-notifications-component'
import { URL_TO_IMAGES } from '../../../config/api.config'
import { getUnitsMeasurement } from '../../../services/general'
import { createImages, createProduct, updateImages } from '../../../services/product'
import { createBrand, getBrands } from '../../../services/product/brands'
import { createCategory, getCategories } from '../../../services/product/cotegories'
import { orderBy } from '../../../uteis'
import { stringHtmlDataToDraftState } from '../../../uteis/draft-jsCustom'

const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 5000,
      onScreen: true
    }
  });
}
// separar responsabilidades
// fotos preview
// deletar foto
// proximo item
const NovoProduto = (props) => {
  const { setLoading, isCopy } = props
  let history = useHistory();

  //1
  const [infoAbrir, setInfoAbrir] = useState(true)
  const [filesOpen, setFilesOpen] = useState(false)
  const [abrirVideo, setAbrirVideo] = useState(false)

  const [abrirEspecificacao, setAbrirEspecificacao] = useState(false)
  const [abrirComplemento, setAbrirComplemento] = useState(false)
  const [btnSalvar, setBtnSalvar] = useState(true)

  //form
  // 1
  const [nomeProduto, setNomeProduto] = useState("")
  const [ativo, setAtivo] = useState(false)
  const [categoria, setCategoria] = useState("")
  const [categorias, setCategorias] = useState([])
  const [marca, setMarca] = useState("")
  const [marcas, setMarcas] = useState([])
  const [priceType, setPriceType] = useState("cotation")
  const [defaultPrice, setDefaultPrice] = useState("")
  const [descricao, setDescricao] = useState(EditorState.createEmpty())
  // 2
  const [files, setFiles] = useState([])
  // 3
  const [videoURL, setVideoURL] = useState("")
  // 4
  const [especificacao, setEspecificacao] = useState(EditorState.createEmpty())
  // 5
  const [gtinEan, setGtinEan] = useState("")
  const [codigo, setCodigo] = useState("")
  const [unidadeMedida, setUnidadeMedida] = useState("")
  const [unidadesMedidas, setUnidadesMedidas] = useState([])

  const productId = props.produto && !isCopy ? props.produto.id : null
  const salvarProduto = async () => {

    props.setLoading(true)

    const formdatProduto = new FormData()
    formdatProduto.append("name", nomeProduto)
    formdatProduto.append("active", ativo ? 1 : 0)
    formdatProduto.append("description", draftToHtml(convertToRaw(descricao.getCurrentContent())))

    formdatProduto.append("video_link", videoURL)

    formdatProduto.append("technical_specifications", draftToHtml(convertToRaw(especificacao.getCurrentContent())))

    formdatProduto.append("gtin_ean", gtinEan)
    formdatProduto.append("code", codigo)
    formdatProduto.append("unit_measurement_id", unidadeMedida)

    formdatProduto.append("price_type", priceType)

    if (typeof defaultPrice === 'string') {
      const newDefaultPrice = defaultPrice.split(',');
      newDefaultPrice[0] = newDefaultPrice[0].replace('R$ ', '').replace('.', '');
      formdatProduto.append("default_price", Number(newDefaultPrice.join('.')))
    } else {
      formdatProduto.append("default_price", defaultPrice)
    }

    const filesOrder = orderBy(files, 'order')

    const currentImages = {
      _method: isCopy ? "post" : "put",
      current_images: []
    }

    filesOrder.forEach(async (file, index) => {
      if (file?.id) {
        // formdatProduto.append("current_images[]", [`id: ${file.id}`, `order: ${index}`])
        currentImages.current_images.push(`id: ${file.id}, order: ${index}`)
        return
      }
      file.order = index
      if (productId) {
        const newImages = new FormData()
        newImages.append("product_id", productId)
        newImages.append("images[]", file)
        await createImages(newImages)
          .then(response => {
            currentImages.current_images.push(`id: ${response.data.resposta.conteudo.images[0].id}, order: ${file.order}`)
          })
      }
      formdatProduto.append("images[]", file)
    })

    if (props.produto && !isCopy) {
      formdatProduto.append("_method", "put")
    }

    await Promise.all([getCategorias()], [getMarcas()]).then(async response => {
      const objectCategoria = await createCategory({ name: categoria }).then(response => {
        return response.data.resposta.conteudo?.category || response.data.resposta.conteudo.categoryProduct
      })
      const objectMarca = await createBrand({ name: marca }).then(response => {
        return response.data.resposta.conteudo?.marca || response.data.resposta.conteudo.brandProduct
      })
      formdatProduto.append("category_products_id", objectCategoria.id)
      formdatProduto.append("brand_id", objectMarca.id)
      if (props.produto && !isCopy) {
        await updateImages(currentImages, productId)
          .then(response => {
            createProduto(formdatProduto, productId)
          })
        return
      }
      if (isCopy) {
        formdatProduto.append("current_images", JSON.stringify(currentImages.current_images))
      }
      createProduto(formdatProduto, productId)
    })
  }

  const createProduto = async (formdata, productId) => {
    for (let pair of formdata.entries()) {
      if (pair[1] === 'null' || pair[1] === null) {
        formdata.delete(pair[0])
      }
    }

    return await createProduct(formdata, productId)
      .then(response => {
        if (response) {
          avisar("Produto salvo com sucesso!", "Seu produto foi salvo e já esta disponivel na plataforma", "success")
          history.push("/catalogo/produtos")
        }
      })
      .catch(error => {
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            avisar("Ops!!", error.response.data.resposta?.conteudo?.error, "danger")
          }

          if (messages) {
            Object.keys(messages).forEach(key => {
              messages[key].forEach(message => {
                avisar("Ops!!", message, "danger")
              })
            })
          }
          return
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        props.setLoading(false)
      })
  }

  const isEditar = useCallback(async () => {
    if (!props?.produto) {
      return
    }

    setNomeProduto(props.produto?.name || '')
    setAtivo(props.produto?.active || false)
    setMarca(props.produto?.brand?.name || '')
    setCategoria(props.produto?.category?.name || '')
    setVideoURL(props.produto?.video_link || '')
    setPriceType(props.produto?.price_type || '')
    setDefaultPrice(props.produto?.default_price || '')

    if (props.produto.images && !isCopy) {
      const files = orderBy(props.produto.images, 'order').map(file => {
        file.preview = `${URL_TO_IMAGES}${file.path.replace('public', '')}`

        delete file['order']

        return file
      })
      setFiles(files)
    }

    setDescricao(stringHtmlDataToDraftState(props.produto.description))
    setEspecificacao(stringHtmlDataToDraftState(props.produto.technical_specifications))

    setGtinEan(props.produto?.gtin_ean || '')
    setCodigo('')
    setUnidadeMedida(props.produto?.unit_measurement_id || '')
  }, [isCopy, props.produto])

  const fecharTodos = () => {
    setInfoAbrir(false)
    setFilesOpen(false)
    setAbrirVideo(false)
    setInfoAbrir(false)
    setAbrirEspecificacao(false)
    setAbrirComplemento(false)
  }

  const liberarBotao = () => {
    setBtnSalvar(false)
  }
  const validar = () => {
    let valid = true;

    if (!nomeProduto) {
      avisar("Ops!!", "Campos 'Nome' é obrigátorio", "danger")
      valid = false
    }
    if (!descricao.getCurrentContent().hasText()) {
      avisar("Ops!!", "Campos 'Descrição' é obrigátorio", "danger")
      valid = false
    }
    if (!marca) {
      avisar("Ops!!", "Campos 'Marca' é obrigátorio", "danger")
      valid = false
    }
    if (!categoria) {
      avisar("Ops!!", "Campos 'Categoria' é obrigátorio", "danger")
      valid = false
    }

    setBtnSalvar(!valid)
    return valid
  }
  const irFotos = () => {

    if (validar()) {
      fecharTodos()
      liberarBotao()
      setFilesOpen(true)
    }

  }

  const irVideo = () => {
    fecharTodos()
    setAbrirVideo(true)
  }

  const irEspecificacoesTecnicas = () => {
    fecharTodos()
    setAbrirEspecificacao(true)
  }

  const irComplemento = () => {
    fecharTodos()
    setAbrirComplemento(true)
  }
  const getCategorias = async (searchItem = '') => {
    await getCategories(searchItem).then(response => {
      setCategorias(response.data.resposta.conteudo.categoryProduct.data)
    })
      .catch(error => {
        if (error && error.response?.data?.message) {
          avisar("Ops!!", error.response.data.message, "danger")
          return
        }
        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
  }
  const getMarcas = async (searchItem = '') => {
    await getBrands(searchItem).then(response => {
      setMarcas(response.data.resposta.conteudo.brand.data)
    })
      .catch(error => {
        if (error && error.response?.data?.message) {
          avisar("Ops!!", error.response.data.message, "danger")
          return
        }
        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
  }

  const getUnidades = useCallback(async (searchItem = '') => {
    setLoading(true)
    await getUnitsMeasurement(searchItem).then(response => {
      setUnidadesMedidas(response.data.resposta.conteudo.unitMeasurement)
    })
      .catch(error => {
        if (error && error.response?.data?.message) {
          avisar("Ops!!", error.response.data.message, "danger")
          return
        }
        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => setLoading(false))
  }, [setLoading]);

  useEffect(() => {
    isEditar()
    getUnidades()
  }, [getUnidades, isEditar])

  useEffect(() => {
    if (filesOpen || abrirVideo || abrirEspecificacao || abrirComplemento) {
      if (!validar()) {
        fecharTodos()
        setInfoAbrir(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filesOpen, abrirVideo, abrirEspecificacao, abrirComplemento])

  return (
    <>
      <div>
        <div id="accordion" className="accordion">
          <CollapseItem posicao={1} nome="Informações básicas - Obrigatórios" open={infoAbrir} func={setInfoAbrir}>
            <BasicInfo
              nome={nomeProduto}
              setNome={setNomeProduto}

              ativo={ativo}
              setAtivo={setAtivo}

              marca={marca}
              setMarca={setMarca}

              categoria={categoria}
              setCategoria={setCategoria}

              descricao={descricao}
              setDescricao={setDescricao}

              categorias={categorias}
              setCategorias={setCategorias}

              searchCategoria={getCategorias}

              marcas={marcas}
              setMarcas={setMarcas}

              priceType={priceType}
              setPriceType={setPriceType}

              defaultPrice={defaultPrice}
              setDefaultPrice={setDefaultPrice}

              productId={productId}
              hasVariation={!!props?.produto?.variations.length}

              searchMarca={getMarcas}

              func_acao={irFotos}
            />
          </CollapseItem>
          <CollapseItem nome="Cadastar as fotos" posicao={2} open={filesOpen} func={setFilesOpen}>
            {
              files && (
                <DropFiles
                  func_acao={irVideo}
                  setFiles={setFiles}
                  files={files}
                  setLoading={props.setLoading}
                  isUpdate={productId && !isCopy}
                  create={createImages}
                  type="produto"
                />
              )
            }
          </CollapseItem>
          <CollapseItem nome="Video do produto" open={abrirVideo} posicao={3} func={setAbrirVideo}>
            <VideoEmbed url={videoURL} setURL={setVideoURL} func_acao={irEspecificacoesTecnicas} />
          </CollapseItem>

          <CollapseItem posicao={4} nome="Especificações Técnicas" open={abrirEspecificacao} func={setAbrirEspecificacao}>
            <Especificacao
              func_acao={irComplemento}
              especificacao={especificacao}
              setEspecificacao={setEspecificacao}
            />
          </CollapseItem>
          <CollapseItem posicao={5} nome="Complemento" open={abrirComplemento} func={setAbrirComplemento}>
            <Complemento
              gtinEan={gtinEan}
              setGtinEan={setGtinEan}
              codigo={codigo}
              setCodigo={setCodigo}
              unidadeMedida={unidadeMedida}
              setUnidadeMedida={setUnidadeMedida}
              unidadesMedidas={unidadesMedidas}
            />
          </CollapseItem>
        </div>
        <Button className="mt-3" color="secondary" onClick={salvarProduto} disabled={btnSalvar}>Salvar</Button>
      </div>
    </>
  );
}

export default NovoProduto;
