import React, { useEffect, useState } from "react";
import { Button, Input, Row } from "reactstrap";
import Loader from "../../components/loader";
import { createAddress, updateAddress } from "../../services/adresses";
import { getCities, getUfs } from "../../services/regions";
import { cepMask } from "../../uteis/mask";
import { notification } from "../../uteis/notifications";

export function FormEndereco({pegaEnderecos, isEditData, editData = null, toggle}) {
  const [endereco, setEndereco] = useState(isEditData ? {...editData, uf_id: editData?.city?.uf_id} : {});
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);

  const [estados, setEstados] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const pegaUFs = async (idPais) => {
    setLoading(true);
    await getUfs(idPais)
      .then((response) => {
        setEstados(response.data.resposta.conteudo.ufs);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  const pegaCidades = async (idUf) => {
    setLoading(true);
    await getCities(idUf, "all")
      .then((response) => {
        setMunicipios(response.data.resposta.conteudo.city);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };
  
  const handleNewEndereco = async () => {
    setLoadingSave(true);
    const calls = {updateAddress, createAddress}
    await calls[editData ? 'updateAddress' : 'createAddress'](endereco)
      .then(() => {
        notification("Sucesso", "Endereço cadastrado!", "success")
        setEndereco({})
        setMunicipios([])

        pegaEnderecos();
        toggle();
      })
      .finally(() => setLoadingSave(true))
  };

  useEffect(() => {
    pegaUFs(33);

    const ufId = endereco?.uf_id
    if (ufId) {
      pegaCidades(ufId);
    }
  }, [endereco.uf_id]);

  return (
    <>
      <Loader loading={loading} />
      <Row>
        <div className="col-md-12">
          <div className="checkbox checkbox-css mb-2">
            <input
              id="default"
              name="default"
              type="checkbox"
              onChange={() => {
                setEndereco({
                  ...endereco,
                  default: !endereco?.default ? 1 : 0,
                })
              }}
              checked={!!endereco?.default}
              disabled={loadingSave}
            />
            <label
              style={{ paddingLeft: "20px" }}
              htmlFor="default"
            >
              Endereço Padrão
            </label>
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <label className="opacity-5 mb-1">CEP</label>
            <input
              name="postal_code"
              placeholder="CEP"
              type="text"
              className="form-control"
              value={endereco?.postal_code || ""}
              maxLength={10}
              onChange={(event) =>
                setEndereco({
                  ...endereco,
                  postal_code: cepMask(event.target.value),
                })
              }
              disabled={loadingSave}
            />
          </div>
        </div>
        <div className="col-md-8">
          <div className="form-group">
            <label className="opacity-5 mb-1">Endereço</label>
            <input
              name="address"
              placeholder="Rua, Bairro, Av."
              type="text"
              className="form-control"
              value={endereco?.address || ""}
              onChange={(event) =>
                setEndereco({ ...endereco, address: event.target.value })
              }
              disabled={loadingSave}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">Rua</label>
            <input
              name="neighborhood"
              placeholder="Rua"
              type="text"
              className="form-control"
              value={endereco?.neighborhood || ""}
              onChange={(event) =>
                setEndereco({ ...endereco, neighborhood: event.target.value })
              }
              disabled={loadingSave}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">Número</label>
            <input
              name="number"
              placeholder="Número"
              type="text"
              className="form-control"
              value={endereco?.number || ""}
              onChange={(event) =>
                setEndereco({ ...endereco, number: event.target.value })
              }
              disabled={loadingSave}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <label className="opacity-5 mb-1">Complemento</label>
            <input
              name="complement"
              placeholder="Complemento"
              type="text"
              className="form-control"
              value={endereco?.complement || ""}
              onChange={(event) =>
                setEndereco({ ...endereco, complement: event.target.value })
              }
              disabled={loadingSave}
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">Estado</label>
            <Input
              type="select"
              name="select"
              className="form-round"
              value={endereco?.uf_id || ""}
              onChange={(event) => {
                pegaCidades(parseInt(event.target.value));
                setEndereco({
                  ...endereco,
                  uf_id: event.target.value,
                  country_id: 33,
                });
              }}
              disabled={loadingSave}
            >
              <option>Escolha um Estado...</option>
              {estados &&
                estados.map((estado) => (
                  <option key={estado.id} value={estado.id}>
                    {estado.name}
                  </option>
                ))}
            </Input>
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group">
            <label className="opacity-5 mb-1">Cidade</label>
            <Input
              type="select"
              name="select"
              className="form-round"
              value={endereco?.city_id || ""}
              onChange={(event) =>
                setEndereco({ ...endereco, city_id: event.target.value })
              }
              disabled={loadingSave}
            >
              <option>Escolha um Municipio...</option>
              {municipios &&
                municipios.map((municipio) => (
                  <option key={municipio.id} value={municipio.id}>
                    {municipio.name}
                  </option>
                ))}
            </Input>
          </div>
        </div>
      </Row>
      <Row>
        <Button color="info" size="sm" block onClick={handleNewEndereco} disabled={loadingSave}>
          {
            loadingSave ? "Salvando endereço..." : isEditData ? "Alterar" : "Cadastrar"
          }
        </Button>
      </Row>
    </>
  );
}
