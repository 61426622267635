import lottie from "lottie-web";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Card, CardBody } from "reactstrap";

import styled from "styled-components";
import animationOrder from "../../../assets/json/order";
import { statusEnumToText } from "../../../uteis/orders";

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap-reverse;
`

export function LastOrdersCard({ title, subtitle, tableData, client }) {
  const containerOrderAnimation = useRef(null)
  
  useEffect(() => {
    lottie.loadAnimation({
      container: containerOrderAnimation.current,
      renderer: "svg",
      autoplay: true,
      animationData: animationOrder
    })
  }, [])

  return (
    <Card className="mt-3">
      <CardBody>
        <Header>
          <div>
            <h4 className="card-title mb-0">{title}</h4>
            <p className="text-muted">{subtitle}</p>
          </div>
          <div style={{ maxWidth: 50 }} ref={containerOrderAnimation}></div>
        </Header>

        <div>
          <div className="d-flex justify-content-center">
            <div className="table-responsive">
              {tableData.length === 0 ? (
                <h6 className="card-title">Nenhum pedido foi feito</h6>
              ) : (
                <table className="table table-invoice">
                  <thead>
                    <tr>
                      <th className="text-left" width="100%">
                      </th>
                      {/* <th className="text-right" width="20%"></th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <span className="text-inverse m-r-4">
                            Pedido: {item.id}
                          </span>
                          <span className="text-inverse">
                            Data:{" "}
                            {new Date(item.created_at).toLocaleDateString()}
                          </span>
                          <br />
                          <div>
                            <small>
                              <strong>Empresa</strong>:{" "}
                              {client
                                ? item.customer.company_name
                                : item.supplier.company_name}
                            </small>
                            <small style={{ marginLeft: "10px" }}>
                              <strong>Status</strong>:{" "}
                              {statusEnumToText[item.current_status.name].toUpperCase()}
                            </small>
                          </div>
                        </td>
                        {/* <td>
                          {client ? (
                            <a href={`/clientes-pedidos/criados#${item.id}`}>
                              Ver pedido
                            </a>
                          ) : (
                            <a href={`/pedidos/criados#${item.id}`}>
                              Ver pedido
                            </a>
                          )}
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </CardBody>

      <div className="card-footer bg-transparent border-top">
        <div className="text-center">
          <Link
            to={`${client ? "/clientes-pedidos/criados" : "/pedidos/criados"}`}
            className="btn btn-dark waves-effect waves-light"
          >
            {" "}
            Ver {client ? "meus pedidos" : "pedidos solicitados"}
          </Link>
        </div>
      </div>
    </Card>
  );
}
