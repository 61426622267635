import { API } from "../../config/api.config";
import api from "../api";

api.defaults.headers["Content-Type"] = `application/json`

const baseUrlCompanie = `${API}/auth/evaluations`

export const createEvaluations = (input) => {
    return new Promise( (resolve, reject) =>{
        api.defaults.headers.data = {...input}
        api.post(`${baseUrlCompanie}`, input)
                .then(result => {
                    resolve(result.data)})
                .catch( err => reject(err) )
    } )
}

export const deleteEvaluations = (id) => api.delete(`${baseUrlCompanie}/${id}`)
export const updateEvaluations = (id, value) => api.put(`${baseUrlCompanie}/${id}`, value)
export const getEvaluations = (params) => api.get(`${baseUrlCompanie}`, {params})
export const getCustomerEvaluations = (params) => api.get(`/auth/customer-evaluations`, {params})
export const getMyEvaluations = (params) => api.get(`/auth/my-evaluations`, {params})
