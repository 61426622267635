import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { AdicionarCarrinho } from "../../../components/adicionarCarrinho";
import { URL_TO_IMAGES } from "../../../config/api.config";
import { moneyMask } from "../../../uteis/mask";
import { VariationSelect } from "./variationSelect";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};


const Produto = ({ produto, empresa, type, toggleModalCarrinho, ...props }) => {
  const [imagens, setImagens] = useState([]);
  const [activeVariation, setActiveVariation] = useState(null);

  const stringProduto = `${
    produto?.code ? "Código : " + produto.code + " | " : ""
  }
        ${produto?.gtin_ean ? "GTIN/EAN: " + produto.gtin_ean + " | " : ""}
        ${
          produto?.category?.name
            ? "Categoria: " + produto.category?.name + " |"
            : ""
        }
        ${produto?.brand?.name ? "Marca: " + produto.brand.name : ""}`;

  const stringServico = `${
    produto?.code ? "Código : " + produto.code + " | " : ""
  }
        ${
          produto?.category?.name
            ? "Categoria: " + produto.category?.name + " |"
            : ""
        }`;

  const infoTopProduto =
    props.type !== "servico" ? stringProduto : stringServico;

  useEffect(() => {
    const arrImages = [];
    if (produto?.images) {
      arrImages.push(...produto.images);
    }
    if (produto?.video_link && produto?.video_link !== "null") {
      arrImages.push(produto.video_link);
    }
    setImagens(arrImages);

    if (produto?.variations?.length) {
      const firstVariation = produto.variations[0];
      setActiveVariation(firstVariation);
      if (type !== "servico") {
        setImagens(firstVariation?.products_images.filter(img => img.variations_product.variation_product_id === firstVariation.id) || []);
      } else {
        setImagens(firstVariation?.services_images.filter(img => img.variations_service.variation_service_id === firstVariation.id) || []);
      }
    }
  }, [produto, type]);

  return (
    <Container fluid={true} className="product  bg-white ">
      <Row>
        <Col>
          <h1 className="page-header mb-2">
            {produto.name} <br />
            <small>{infoTopProduto}</small>
          </h1>
        </Col>
      </Row>
      <Row>
        <Col className="" md={8} lg={6}>
          <Carousel responsive={responsive}>
            {activeVariation?.images || imagens ? (
              (activeVariation?.images ? activeVariation.images : imagens).map(
                (image) => {
                  if (typeof image !== "string") {
                    return (
                      <div key={image.id} className="product-image mt-2">
                        <div
                          className="product-main-image type-img"
                          data-id="main-image"
                        >
                          <img
                            src={`${URL_TO_IMAGES}/${image.path.replace(
                              "public",
                              ""
                            )}`}
                            alt={`Foto do Produto: ${produto.name}`}
                            className="img-fluid m-auto"
                          />
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div key={image} className="product-image mt-2">
                      <div className="embed-responsive embed-responsive-21by9 type-img">
                        <ReactPlayer
                          width={"100%"}
                          url={image}
                          onError={() =>
                            console.log(
                              "Video erro",
                              "url fornecida é invalida",
                              "danger"
                            )
                          }
                        />
                      </div>
                    </div>
                  );
                }
              )
            ) : (
              <div className="product-image mt-2">
                <div
                  style={{
                    backgroundImage: `url(/assets/img/default-product.svg)`,
                    width: "100%",
                    height: "300px",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </div>
            )}
          </Carousel>
        </Col>
        <Col className="d-flex align-items-center" md={4} lg={6}>
          <div className="product-info product-pd mt-3">
            <div className="product-info-header ">
              <h4>Descricão:</h4>

              <div
                dangerouslySetInnerHTML={{ __html: produto.description }}
                style={{
                  maxHeight: "400px",
                  overflow: "auto",
                  marginBottom: "1rem",
                }}
              ></div>
              {produto?.unit_measurement && (
                <p>
                  <strong>Unidade de medida: </strong>{" "}
                  {produto?.unit_measurement?.name_portuguese} (
                  {produto?.unit_measurement?.acronym})
                </p>
              )}
              <div className="rating mt-2 mb-3">
                <p className="mr-2 text-orange">Nota: {produto.avg}</p>
                {[1, 2, 3, 4, 5].map((item) => (
                  <span
                    key={item}
                    className={`star ${item <= (produto.avg || 0) && "active"}`}
                  ></span>
                ))}
              </div>

              {produto?.default_price || activeVariation?.price ? (
                <div style={{ fontSize: "2rem" }}>
                  {moneyMask(
                    activeVariation?.price || produto.default_price,
                    true
                  )}
                </div>
              ) : null}

              {type !== "servico" &&
              (produto?.price_type === "precification" ||
                !!produto?.variations_price) ? (
                <AdicionarCarrinho
                  item={{
                    ...produto,
                    company: empresa,
                    selectedPrice:
                      activeVariation?.price ?? produto.default_price,
                    variationSelected: activeVariation,
                  }}
                  toggleModalCarrinho={toggleModalCarrinho}
                />
              ) : null}

              {type !== "servico" &&
                !produto?.default_price &&
                !toggleModalCarrinho && (
                  <Link
                    to={`/cotacao/cotar/${parseInt(produto.company_id)}?produto=${produto.id}`}
                    className="btn btn-outline-primary btn-block"
                  >
                    <i className="fa fa-plus mr-2"></i>
                    Adicionar a Cotação
                  </Link>
                )}
            </div>

            {!!activeVariation ? (
              <div className="prodict-info-header mt-2">
                <h4>Variações:</h4>
                {produto.variations.map((prodVariation) => (
                  <VariationSelect
                    key={prodVariation.id}
                    typeCompany={type}
                    prodVariation={prodVariation}
                    activeVariation={activeVariation}
                    setActiveVariation={setActiveVariation}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Produto;
