import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Loader from "../../components/loader";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import { useFormasPagamento } from "./hooks/useFormasPagamento";

const FormasPagamento = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
`

export function Configuracao() {
  const {
    loading,
    formasPagamento,
    formasPagamentoEmpresa,
    selecionaFormaPagamento
  } = useFormasPagamento({});

  
  const contextType = useContext(PageSettings);

  useEffect(() => {
    contextType.closeMobileSiderBar()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Loader loading={loading} />

      <TitlePage
        title="Configuração dos pedidos de clientes"
        desc="Aqui você define as propriedades dos pedidos de seus clientes"
        icon="fa fa-shopping-cart"
      />
      <div className="row">
        <div className="col-md-12">
          Formas de Pagamento que você aceita
          <FormasPagamento>
            {formasPagamento.map((forma) => {
              return (
                <div key={forma.id} className="checkbox checkbox-css">
                  <input
                    name={forma.name}
                    type="checkbox"
                    id={forma.name}
                    onChange={() => selecionaFormaPagamento(forma.id)}
                    checked={formasPagamentoEmpresa.some((empresa) => empresa.payment_method_id === forma.id && empresa.active)}
                  />
                  <label style={{ paddingLeft: "20px" }} htmlFor={forma.name}>
                    {forma.name}
                  </label>
                </div>
              );
            })}
          </FormasPagamento>
        </div>
        {/* <div className="col-md-12">
          <label>
            Valor minímo que cada pedido pode ter
          </label>
          <input
            name="valor-minimo"
            id="valor-minimo"
            type="text"
          />
        </div> */}
      </div>
    </>
  );
}
