import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import { cnpjMask, moneyMask } from "../../uteis/mask";

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  > h3 {    
    display: flex;
    flex-direction: column;
    margin-bottom: 0 !important;
  }
`;

const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: .9rem;
  margin-bottom: .9rem;
  span {
    font-weight: 700;
    margin-right: 5px;
  }
`

const DetailsOrder = styled.div`
  border: 1px solid #c5cdd3;
  border-radius: 5px;
  padding: .5rem 1rem;
  > div {
    :nth-child(1), :last-child {
      display: flex;
      gap: 1rem;
      flex-wrap: wrap;
    }
    span {
      font-weight: 700;
    }
  }
`

const TotalValue = styled.div`
  font-size: 1rem;
`

const formatValue = (totalValue, deliveryValue) => {
  if (deliveryValue) {
    return <><span>Valor + entrega:</span> {moneyMask(totalValue, true)} + {moneyMask(deliveryValue, true)} = {moneyMask(totalValue+deliveryValue, true)}</>
  }
  return <><span>Valor:</span> {moneyMask(totalValue, true)}</>
}

export function Header({
  company,
  data,
  numeroPedido,
  enderecoEndrega,
  enderecoFaturamento,
  formaPagamento,
  status,
  totalValue,
  deliveryValue,
  children
}) {
  return (
    <HeaderStyled>
      <div>
        <h3>
          <Link
            to={`/empresa/${company?.id}`}
            style={{
              textDecoration: "underline",
            }}
          >
            {company?.company_name}
          </Link>
        </h3>
        <CompanyInfo>
          {company?.cnpj ? (
            <div>
              <span>CNPJ:</span>
              {cnpjMask(company.cnpj)}
            </div>
          ) : null}

          {company?.cnpj ? (
            <div>
              <span>Telefone:</span>
              {company.phone}
            </div>
          ) : null}

          {company?.whatsapp ? (
            <div>
              <span>Whatsapp:</span>
              {company.whatsapp}
            </div>
          ) : null}

          {company?.email ? (
            <div>
              <span>E-mail:</span>
              {company.email}
            </div>
          ) : null}
        </CompanyInfo>
        <DetailsOrder>
          <div>
            <div>
              <span>Criado em:</span> {new Date(data).toLocaleDateString()}
            </div>
            <div>
              <span>Pedido Nº:</span> {numeroPedido}
            </div>
            <div>
              <span>Forma de pagamento:</span> {formaPagamento}
            </div>
          </div>
          <div>
            <span>Entrega:</span> {enderecoEndrega}
          </div>
          <div>
            <span>Faturamento:</span> {enderecoFaturamento}
          </div>
          <div>
            <div>{status}</div>
          </div>
          <TotalValue>{formatValue(totalValue, deliveryValue)}</TotalValue>
        </DetailsOrder>
      </div>

      {children}
    </HeaderStyled>
  );
}
