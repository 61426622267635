import moment from "moment"
import React from "react"
import { Link } from "react-router-dom"
import { URL_TO_IMAGES } from '../../config/api.config'

// 
const ItemCotado = ({ cotacao, tipo, companyOrCompany2 }) => {
    switch (tipo) {
        case "cliente":
            return (
                <div className="col-sm-6 col-xl-4 mb-4">
                    <Link to={cotacao.link}>
                        <div className="card position-relative">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="text-lg-center">
                                            <div className="avatar-sm mr-3 mx-lg-auto mb-2 float-left float-lg-none position-relative mt-3">
                                                <span variant="success" className={`mt-1 mr-1 badge badge-secondary badge-soft-success position-absolute`} style={{ top: -22, right: 0 }}>Cliente</span>

                                                {/* <img className="img-circle img-fluid" src="/assets/img/user/user-13.jpg" alt="" /> */}
                                                <img
                                                    className="img-circle img-fluid"
                                                    src={cotacao[companyOrCompany2 || 'company2']?.company_logo ? URL_TO_IMAGES+'/'+cotacao[companyOrCompany2 || 'company2']?.company_logo.replace('public', '') : "/assets/img/user/user-13.jpg"}
                                                    alt=""
                                                    style={{
                                                        width: '48px',
                                                        height: '48px'
                                                    }}
                                                />
                                            </div>
                                            <a className="text-muted" href="/invoices-list">Status</a><br />
                                            <span variant="success" className={`mt-1 mr-1 badge badge-secondary ${cotacao.status === "Cotado" ? "badge-success" : "badge-secondary"}`}>{cotacao.status}</span>
                                            {cotacao.data && cotacao.status === "Cotado" ?
                                                <>
                                                    {
                                                        moment(new Date(cotacao.valido)).diff(moment(new Date()), 'days') >= 0 ?
                                                            <span className={`mt-1 badge badge-soft-success wbreak`}>Válido até {moment(new Date(cotacao.valido)).format('DD/MM/YY')}</span> :
                                                            <span className={`mt-1 badge badge-soft-danger wbreak`}>Vencido em {moment(new Date(cotacao.valido)).format('DD/MM/YY')}</span>
                                                    }
                                                </>
                                                : null
                                            }
                                            {cotacao.status !== "Cotado" ? <p className="mb-2 text-muted mt-2">Solicitado em {cotacao.data}</p> : null}

                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div>
                                            <h5 className="mb-0 ">{cotacao.para}</h5>
                                            <p className="mb-2 text-muted">CNPJ: {cotacao.cnpj}</p>
                                            <a className="d-block text-primary mb-2" href="/invoices-detail">Cotação #{cotacao.number}</a>
                                            <ul className="list-inline mb-0">
                                                {cotacao.preco &&
                                                    <li className="list-inline-item mr-3">
                                                        <h5 className="font-size-14 text-muted" id="amountTooltip"><i className="bx bx-money mr-1 text-primary" />R$ {cotacao.preco}</h5>
                                                    </li>
                                                }

                                            </ul>
                                            <button className="btn btn-secondary btn-sm">Ver cotação</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
        default:
            return (
                <div className="col-sm-6 col-xl-4 mb-4">
                    <Link to={cotacao.link}>
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="text-lg-center">
                                            <div className="avatar-sm mr-3 mx-lg-auto mb-2 mt-3 float-left float-lg-none position-relative">
                                                {/* <img className="img-circle img-fluid" src="/assets/img/user/user-13.jpg" alt="" /> */}
                                                <img
                                                    className="img-circle img-fluid"
                                                    src={cotacao[companyOrCompany2 || 'company2']?.company_logo ? URL_TO_IMAGES+'/'+cotacao[companyOrCompany2 || 'company2']?.company_logo.replace('public', '') : "/assets/img/user/user-13.jpg"}
                                                    alt=""
                                                    style={{
                                                        width: '48px',
                                                        height: '48px'
                                                    }}
                                                />
                                        
                                                <span variant="success" className={`mt-1 mr-1 badge badge-secondary badge-soft-warning position-absolute`} style={{ top: -22, right: -10 }}>Fornecedor</span>
                                            </div>
                                            <a className="text-muted" href="/invoices-list">Status</a><br />
                                            <span variant="success" className={`mt-1 mr-1 badge badge-secondary ${cotacao.status === "Cotado" ? "badge-success" : cotacao.status === "Pendente" ? "badge-warning" : "badge-secondary"}`}>{cotacao.status}</span>
                                            {cotacao.data && cotacao.status === "Cotado" ?
                                                <>
                                                    {
                                                        moment(new Date(cotacao.valido)).diff(moment(new Date()), 'days') >= 0 ?
                                                            <span className={`mt-1 badge badge-soft-success wbreak`}>Válido até {moment(new Date(cotacao.valido)).format('DD/MM/YY')}</span> :
                                                            <span className={`mt-1 badge badge-soft-danger wbreak`}>Vencido em {moment(new Date(cotacao.valido)).format('DD/MM/YY')}</span>
                                                    }
                                                </>
                                                : null
                                            }
                                            {cotacao.status !== "Cotado" ? <p className="mb-2 text-muted mt-2">Solicitado em {cotacao.data}</p> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div>
                                            <h5 className="mb-0 ">{cotacao.para}</h5>
                                            <p className="mb-2 text-muted">CNPJ: {cotacao.cnpj}</p>
                                            <a className="d-block text-primary mb-2" href="/invoices-detail">Cotação #{cotacao.number}</a>
                                            <ul className="list-inline mb-0">
                                                {cotacao.preco &&
                                                    <li className="list-inline-item mr-3">
                                                        <h5 className="font-size-14 text-muted" id="amountTooltip"><i className="bx bx-money mr-1 text-primary" />R$ {cotacao.preco}</h5>
                                                    </li>
                                                }

                                            </ul>
                                            <button className="btn btn-secondary btn-sm">Ver cotação</button>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Link>
                </div>
            )
    }
}

export default ItemCotado