import styled from "styled-components";

export const Base = styled.button`
  color: ${(props) => props.theme.colors.white};
  width: 30px;
  height: 30px;
  border: none;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  align-items: center;
  padding: 10px 15px;
`;
