const IS_DEV_ENVIRONMENT = process.env.REACT_APP_AMPLI_ENV !== "production"

export const API = IS_DEV_ENVIRONMENT ? "https://apih.acheifornecedor.com/api/v1.0.0" : "https://api2.acheifornecedor.com/api/v1.0.0";
export const API_FORGET = IS_DEV_ENVIRONMENT ? "https://apih.acheifornecedor.com/api/forgot" : "https://api2.acheifornecedor.com/api/v1.0.0";

export const BASE_URL_PRODUCTS = "auth/products";
export const BASE_URL_SERVICES = "auth/services";

export const URL_TO_IMAGES = IS_DEV_ENVIRONMENT ? "http://apih.acheifornecedor.com/storage" : "http://api2.acheifornecedor.com/storage";

export const apiConfig = {
  method: "POST",
  headers: {
    "Content-type": "application/json; charset=UTF-8",
  },
};
