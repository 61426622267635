import { API } from '../../config/api.config';
import { status as statusEnum } from '../../uteis/orders';
import api from "../api";


export const getOrder = (orderId) => api.get(`${API}/auth/orders/${orderId}`);
export const getOrders = (params = null, getCustomer = false) =>
  api.get(`${API}/auth/${getCustomer ? 'orders-customers' : 'orders'}/status/${params.status ?? statusEnum.created}`);
export const getLastOrders = (clientId) => api.get(`${API}/auth/orders/last-orders/${clientId}`);

export const createOrder = (values) => api.post(`${API}/auth/orders`, values);
export const updateOrder = (values) => api.put(`${API}/auth/orders/${values.id}`, values);
export const deleteOrder = (orderId) => api.delete(`${API}/auth/orders/${orderId}`);

export const alterOrder = (id, values) => api.patch(`${API}/auth/orders/status/${id}`, values);
export const addPaymentVoucher = (values) => api.post(`${API}/auth/orders/add-payment-voucher`, values);

export const getPaymentMethods = () => api.get(`${API}/auth/payment-methods`);
export const getPaymentCompanyMethods = (companyId) => api.get(`${API}/auth/payment-company-methods${companyId ? '/'+companyId : ''}`);
export const selectPaymentMethod = (values) => api.patch(`${API}/auth/payment-company-methods/${values.id}`);
