import axios from "axios";
import { API } from "../config/api.config";
import { notification } from "../uteis/notifications";

const api = axios.create({
  baseURL: API,
});

api.interceptors.request.use(config => {
  const ACCESS_TOKEN = localStorage.getItem('access_token')
  const token = `Bearer ${ACCESS_TOKEN}`
  config.headers.authorization = token;

  return config;
})

api.interceptors.response.use(config => {
  return config;
}, error => {
  let message = null
  if (error?.response?.status === 404) {
    const conteudo = error?.response?.data?.resposta?.conteudo
    message = 'error' in conteudo ? conteudo?.error : conteudo?.message
    window.history.back();
  }
  if (error?.response?.status === 401) {
    message = error?.response?.data?.resposta?.conteudo?.message || 'Sem permissão'
    if (message) {
      notification(
        "Algo deu errado",
        message,
        "danger"
      );
    }

    localStorage.removeItem("access_token")
    localStorage.removeItem("expires_at")
    localStorage.removeItem("userInfo")
    localStorage.removeItem("carrinho")

    document.location.href = '/login'

    return
  }
  
  if (error?.response?.status === 422) {
    const messages = error?.response?.data?.resposta?.conteudo?.messages;
    const uniqueMessage = error?.response?.data?.resposta?.conteudo?.message;

    if (uniqueMessage) {
      notification(
        "Inválido",
        uniqueMessage,
        "danger"
      );
      return;
    }

    if (Object.values(messages)?.length) {
      Object.values(messages).forEach(msg => {
        notification(
          "Inválido",
          msg.join('\n'),
          "danger"
        );
      });
      return;
    }

    message = 'Campo(s) inválidos'
  }
  
  if (message) {
    notification(
      "Algo deu errado",
      message,
      "danger"
    );
  }
})

export default api;
