import React, { useState, useEffect, useContext } from "react";
import SweetAlert from 'react-bootstrap-sweetalert'
import ItemProcurar from "../../components/carteiras/solicitaoes"
import { PageSettings } from "../../config/page-settings"
import Loader from "../../components/loader";
import { aceitePortfolio, recusaDoCliente } from "../../services/portfolios";
import { notification } from "../../uteis/notifications";

const MinhaCarteiraSolicitacoes = ({
  data,
  loading,
  setLoading,
  setHasChange,
}) => {
  const contextType = useContext(PageSettings);
  const [isRecusar, setIsRecusar] = useState(null);

  const aceitar = async (id_pending_requests, type = "fornecedor") => {
    setLoading(true)
    await aceitePortfolio(id_pending_requests)
      .then(response => {
        notification("Sucesso!", response.data.resposta.conteudo.message, "success")
        setHasChange(true);
      })
      .catch(error => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              notification("Ops!!", message, "danger")
            })
          })
          return
        }

        notification("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoading(false)
      })
  }
  const recusar = async (id_pending_requests, type = "fornecedor") => {
    setLoading(true)
    await recusaDoCliente(id_pending_requests)
      .then(response => {
        notification("Sucesso!", response.data.resposta.conteudo.message, "success")
        setHasChange(true);
      })
      .catch(error => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              notification("Ops!!", message, "danger")
            })
          })
          return
        }

        notification("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const confirmar = async () => {
    recusar(isRecusar.idRequest, isRecusar.type)
    setIsRecusar(null)
  }
  const cancelar = () => {
    setIsRecusar(null)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(isRecusar &&
        <SweetAlert danger showCancel
          confirmBtnText="Sim"
          confirmBtnBsStyle="outline-success"
          cancelBtnBsStyle="danger"
          cancelBtnText="Cancelar"
          title="Deseja Recusar o pedido dessa empresa?"
          onConfirm={confirmar}
          onCancel={cancelar}
        >
        </SweetAlert>
      )}
      <Loader loading={loading} />
      <div className="row">
        <div className="col-md-12">
          <div className="result-container">
            <ul className="result-list">
              {
                data?.length > 0 ? data.map(solicitacao => (
                  <ItemProcurar
                    key={solicitacao.id}
                    type={solicitacao.type}
                    item={{
                      ...solicitacao.company,
                      ...solicitacao,
                    }}
                    aceitar={aceitar}
                    setIsRecusar={setIsRecusar}
                  />
                )) : (
                  <h4 className="mt-3">Nenhuma solicitação pendente</h4>
                )
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinhaCarteiraSolicitacoes;
