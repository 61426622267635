
import * as type from "./types"
const initialState = {
    loading: false,
    err: null,
    auth: null,
    user: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : null,
    current_companie: localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).companies[0] : null
}

export default function (state = initialState, action){
    switch (action.type) {
        case type.LOG_IN_REQUESTED:
            return {...state, loading: true}
        case type.LOG_IN_SUCCESSED:
            return {...state, loading: false, auth: action.payload, user: action.payload.user}
        case type.LOG_IN_FAILED:
            return {...state, loading: false, err: action.payload}
        case type.LOG_OUT_SUCCESSED:
            return {...state, err: null, auth: null}
        case type.CURRENT_COMPANIE:
            return {...state, loading: false, current_companie: action.payload}
        default:
            return state
    }
}
