import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SweetAlert from "react-bootstrap-sweetalert";
import Dropzone from "react-dropzone";
import { store } from 'react-notifications-component';
import { useLocation } from "react-router";
import { Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../config/api.config";
import { deleteProductImage } from "../../services/product";
import { deleteServiceImage } from "../../services/service";

const permitedTypesImges = [
  "image/png",
  "image/jpg",
  "image/jpeg",
]

const reorder = (list, startIndex, endIndex) => {
  let result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'transparent',

  // width: '125px',
  margin: '.5rem',
  padding: '.5rem',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '.5rem',
  border: '1px solid grey',
  position: 'relative',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});

const DropFiles = (props) => {
  const [fotos, setFotos] = useState([])
  const [itemToDelete, setItemToDelete] = useState(null)
  const location = useLocation()

  const avisar = (title, msg, type) => {
    store.addNotification({
      title: title || "Usuário ou senha incorretos",
      message: msg || "Um código de verificação foi enviado para o seu email!",
      type: type || "danger",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
  }

  const handleFotos = (files) => {
    if (files.length + fotos.length > 5)
      return avisar("Erro", "limite de 5 fotos por produto", "danger")
    
    if (!permitedTypesImges.includes(files[0].type)) {
      return avisar("Atenção", "Tipo do arquivo inválido", "warning")
    }

    const filesToSave = [...files, ...props.files]
    props.setFiles(filesToSave)

    files.forEach(async (file) => {
      props.setLoading(true)

      var reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = function () {
        file.preview = reader.result
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };

      reader.onloadend = function () {
        props.setLoading(false)
      }
    })

    const newFotos = [...files, ...fotos]
    setFotos(newFotos)
  }

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const itemsOrder = reorder(
      fotos,
      result.source.index,
      result.destination.index
    );

    setFotos(itemsOrder.map((item, idx) => ({ ...item, order: idx })))
    props.setFiles(itemsOrder)
  }

  const loadingDelete = (classItemToDelete, indexItemToDelete, visibleSpinner) => {
    const thisButton = document.querySelectorAll(classItemToDelete)[indexItemToDelete]
    thisButton.style.display = visibleSpinner ? 'flex' : 'none'
  }

  const deletarItem = async (index) => {
    props.setLoading(true)
    const objectToDelete = fotos.find((element, i) => i === index)

    if (objectToDelete?.id && (location.pathname.includes('editar/') || props.valores.edit)) {
      const deleteImg = (id) => props.type === 'servico' ? deleteServiceImage(id) : deleteProductImage(id)
      await deleteImg(objectToDelete.id)
        .then(response => {
          avisar('Sucesso!', response.data.resposta.conteudo.message, 'success')

          // const thisButton = document.querySelectorAll('.spinner-when-delete-image')[index]
          // thisButton.style.display = 'none'
        })
        .catch(error => {
          // const thisButton = document.querySelectorAll('.spinner-when-delete-image')[index]
          // thisButton.style.display = 'none'

          if (error && error.response?.data?.resposta) {
            const messages = error.response.data?.resposta?.conteudo?.messages

            if (error.response.data.resposta?.conteudo?.error && !messages) {
              avisar("Ops!!", error.response.data.resposta?.conteudo?.error, "danger")
            }

            if (messages) {
              Object.keys(messages).forEach(key => {
                messages[key].forEach(message => {
                  avisar("Ops!!", message, "danger")
                })
              })
            }
            return
          }

          avisar("Ops!!", "Erro interno no servidor!", "danger")
          return
        })
    }

    const newItems = [...fotos]
    newItems.splice(index, 1)

    setFotos(newItems)
    props.setFiles(newItems)

    // loadingDelete('.spinner-when-delete-image', index, false)
    setItemToDelete(null)
    props.setLoading(false)
  }

  useEffect(() => {
    if (props.files.length > 0) {
      const files = props.files
        .map(file => {
          if (!file.preview) {
            file.preview = `${URL_TO_IMAGES}${file.path.replace('public', '')}`
          }

          return file
        })

      setFotos(files)
    }
  }, [props.files])

  if (itemToDelete !== null) {
    return <SweetAlert danger showCancel
      confirmBtnText="Sim, deletar!"
      confirmBtnBsStyle="danger"
      cancelBtnBsStyle="default"
      title="Você tem certeza?"
      onConfirm={() => deletarItem(itemToDelete)}
      onCancel={() => {
        loadingDelete('.spinner-when-delete-image', itemToDelete, false)
        setItemToDelete(null)
      }}
    >
      Após essa ação Você não conseguira recuperar os dados.
    </SweetAlert>
  }

  return (
    <Dropzone onDrop={handleFotos} >
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            <input {...getInputProps()} accept="image/png, image/jpg, image/jpeg" />
            <div
              className="dropZoneAcheiProtudo"
              style={{
                margin: "auto",
                filter: "none",
                position: "relative"
              }}
            >
              <div className="text-center">
                <p>
                  <i className="pb-3 fas f fa-download fa-3x"></i><br />
                  <i> Clique ou arraste as fotos</i><br />

                </p>
                <span className="badge badge-danger quantidadeFotos">{fotos.length} de 5</span>
              </div>

            </div>
          </div>
          <div className="fotosPreview">
            {
              fotos.length > 0 && (
                // <DraggableCustom items={fotos} setItems={setFotos} />
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable" direction="horizontal">
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                        {...provided.droppableProps}
                      >
                        {fotos.map((item, index) => (
                          <Draggable key={index} draggableId={String(index)} index={index}>
                            {(provided, snapshot) => (
                              <div
                                // style={{
                                // }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                              >
                                <Button className="btn btn-danger btn-sm btn-circle btn-lg m-3 btnDeletarFoto"
                                  onClick={() => {
                                    loadingDelete('.spinner-when-delete-image', index, true)
                                    setItemToDelete(index)
                                  }}
                                >
                                  <i className="fa fa-times"></i>
                                </Button>

                                <div className="spinner-when-delete-image"
                                  style={{
                                    position: 'absolute',
                                    background: 'rgb(255 255 255 / 50%)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    color: '#6d6d6d',
                                    fontSize: '50px',
                                    display: 'none'
                                  }}
                                >
                                  <i className="fas fa-circle-notch fa-spin"></i>
                                </div>

                                <img
                                  alt=""
                                  src={item.preview}
                                  style={{
                                    width: '125px'
                                  }}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
            }
          </div>
          {
            props.func_acao ? (
              <button type="button" className="mt-3 btn btn-secondary" onClick={props.func_acao}>Proximo</button>
            ) : null
          }
        </section>

      )}
    </Dropzone>
  )
}

export default DropFiles
