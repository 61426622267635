import React from "react";
import styled from "styled-components";
import { moneyMask } from "./mask";

const BoxMinMaxPrices = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    @media (max-width: 575px) {
      flex-direction: row;
      gap: 10px;
    }
`;

export const formatedVariationsPrice = (price) => {
  if (price.min === price.max) {
    return `${moneyMask(price.min, true)}`
  }
  return <BoxMinMaxPrices>
    <div>
      {`${moneyMask(price.min, true)} `}
    </div>
    <div>até</div>    
    <div>
      {` ${moneyMask(price.max, true)}`}
    </div>
  </BoxMinMaxPrices>
}
