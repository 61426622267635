import React from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import back1 from "../../../assets/img/backgrounds/3back.jpg"
import { BackButton } from "../../../components/buttons/BackButton"
import { URL_TO_IMAGES } from '../../../config/api.config'
import logo from "./logo.jpg"

export default (props) => {
    const { type, produto, empresa } = props
    const { current_companie } = useSelector(state => state.auth)

    return (
        <div className="profile-header bg-white">
            <div className="profile-header-cover" style={{ backgroundImage: `url(${type === 'servico' && localStorage.getItem('background-servico') || localStorage.getItem('background-produto') || back1})` }}></div>
            <div className="profile-header-content">                
                <BackButton />
                <div className="companyName d-flex justify-content-center align-items-center flex-wrap">
                    <div className="profile-header-img">
                        <img src={URL_TO_IMAGES+'/'+empresa?.company_logo?.replace('public', '') || logo} alt="" />
                    </div>
                    <div className="ml-3">
                        <h4 className="m-t-10 m-b-2">{produto.company_id === empresa.id ? empresa.company_name : 'Empresa'} </h4>
                        <p className="m-b-5"></p>
                        <Link to={`/empresa${produto.company_id === current_companie.id ? '': '/'+produto.company_id}`} className="btn btn-xs btn-yellow">Ver Fornecedor</Link>
                    </div>
                </div>

                <div className="profile-review">
                    {
                        empresa.avg && (
                            <p> <i className="far fa-star"></i> {empresa.avg}</p>
                        )
                    }
                </div>
            </div>

        </div>
    )
}
