import classnames from "classnames";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ListaComentarios from "../../components/comentarios/lista";
import Loader from "../../components/loader";
import { PageSettings } from "../../config/page-settings";
import { notification } from "../../uteis/notifications";
import { useGetData } from "./hooks/useGetData";
import ModalCotacao from "./modal/cotacao";
import Header from "./parts/header";
import Produtos from "./parts/products";
import Servicos from "./parts/services";

const Empresa = () => {
  const contextType = useContext(PageSettings);
  const refFormEvaluation = useRef(null)
  const [activeInitialTab, setActiveInitialTab] = useState("1");
  const [modalProduto, toggleModal] = useState(false)

  const [title, setTitle] = useState("")
  const [comment, setComment] = useState("")
  const [stars, setStars] = useState(0)
  const [edit, setEdit] = useState(false)

  const {
    listaProdutos,
    listaServicos,
    empresa,
    statusPedido,
    carregandoStatus,
    loading,
    setLoading,
    runServices,
    getProdutos,
    getServicos,
    createComent,
  } = useGetData()

  let { id } = useParams();

  const toggle = useCallback((tab) => {
    if (contextType.profileCompanyTab !== tab) {
      setActiveInitialTab(null)
      contextType.handleSetProfileCompanyTab(tab);
    };
  }, [contextType]);

  const activeTabClass = (item) => contextType.profileCompanyTab ? contextType.profileCompanyTab === item : activeInitialTab === item

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.handleSetPageContentFullHeight(true);
    contextType.handleSetPageContentFullWidth(true);
    contextType.closeMobileSiderBar()

    if (contextType?.profileCompanyTab) {
      setActiveInitialTab(contextType.profileCompanyTab)
    }

    return () => {
      contextType.handleSetPageContentFullHeight(false);
      contextType.handleSetPageContentFullWidth(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (listaProdutos?.data?.length > 0) {
      setActiveInitialTab("1")
    }
    if (listaProdutos?.data?.length === 0 && listaServicos?.data?.length > 0) {
      setActiveInitialTab("2")
    }
    if (listaProdutos?.data?.length === 0 && listaServicos?.data?.length === 0) {
      setActiveInitialTab("3")
    }
  }, [listaProdutos, listaServicos]);

  useEffect(() => {
    if (edit) {
      window.scrollTo({ behavior: 'smooth', top: refFormEvaluation.current.offsetTop })
      setEdit(false)
    }
  }, [edit])

  const toggleModalProduto = () => {
    toggleModal(!modalProduto)
  }

  return (
    <>
      <Loader loading={loading} />
      <ModalCotacao modalProduto={modalProduto} toggleModal={toggleModalProduto} />

      <Header
        id={id}
        item={empresa}
        runServices={runServices}
        carregandoStatus={carregandoStatus}
        status={statusPedido}
        setLoading={setLoading}
      />
      {
        empresa.profile === "1" && (
          <>
            <div className="bg-white" style={{ marginTop: '-15px' }}>
              <div className="col-12 pt-3 pb-3">
                <Nav tabs className="nav-pills">
                  {
                    listaProdutos.data.length > 0 && (
                      <NavItem className="m-3">
                        <NavLink
                          className={classnames({
                            active: activeTabClass("1")
                          })}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          Produtos
                        </NavLink>
                      </NavItem>
                    )
                  }
                  {
                    listaServicos.data.length > 0 && (
                      <NavItem className="m-3">
                        <NavLink
                          className={classnames({
                            active: activeTabClass("2")
                          })}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          Serviços
                        </NavLink>
                      </NavItem>
                    )
                  }
                  <NavItem className="m-3">
                    <NavLink
                      className={classnames({
                        active: activeTabClass("3")
                      })}
                      onClick={() => {
                        toggle("3");
                      }}
                    >
                      Avaliações
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
            </div>

            <TabContent activeTab={contextType?.profileCompanyTab || activeInitialTab} style={{ paddingBottom: 30 }}>
              <TabPane tabId="1">
                <Produtos loading={loading} listaProdutos={listaProdutos} getItems={getProdutos} company={empresa} />
              </TabPane>
              <TabPane tabId="2">
                <Servicos loading={loading} listaServicos={listaServicos} getItems={getServicos} company={empresa} />
              </TabPane>
              <TabPane tabId="3">
                <div ref={refFormEvaluation} className="col-md-12 mt-4" style={{ position: 'relative' }}>
                  {
                    loading && (
                      <div
                        style={{
                          zIndex: '1',
                          background: '#eff4f9',
                          position: 'absolute',
                          width: '100%',
                          height: '100%',
                          display: loading ? 'none' : 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          opacity: '.8',
                          fontSize: '1rem',
                          fontWeight: '600',
                        }}
                      >
                        Carregando...
                      </div>
                    )
                  }
                  <form className="comentar">
                    <h4 className="media-heading">Seu comentário.</h4>
                    <div className="rating mt-2 mb-1">
                      <p className="mr-2">sua nota: {stars}</p>
                      {
                        [1, 2, 3, 4, 5].map(item => (
                          <span
                            key={item}
                            className={`m-1 p-0 btn star ${item <= stars && 'active'}`}
                            onClick={() => setStars(item)}
                          ></span>
                        ))
                      }

                    </div>
                    <div className="input-group mb-2">
                      <input
                        type="text"
                        className="form-control bg-light"
                        placeholder="Título do coméntaio"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                      />
                    </div>
                    <div className="input-group mb-2">
                      <textarea
                        type="text"
                        className="form-control bg-light"
                        placeholder="Escreva seu coméntaio"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                      />
                    </div>
                    <button
                      className="btn btn-green"
                      type="button"
                      onClick={() => createComent({ title, comment, stars }, () => { setComment(""); setTitle("") })}                                    >
                      <i className="fa fa-paper-plane"></i> Enviar
                    </button>
                  </form>
                  <hr />
                  {
                    empresa?.evaluates && (
                      <ListaComentarios
                        lista={empresa.evaluates}
                        getItems={runServices}
                        avisar={notification}
                        type="company"
                        setLoading={setLoading}
                        loading={loading}
                      />
                    )
                  }
                  {
                    loading && (
                      <p className="text-center mb-5"><i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando... </p>
                    )
                  }
                </div>
              </TabPane>
            </TabContent>
          </>
        )
      }
    </>
  );
};

export default Empresa;
