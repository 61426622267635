import { useCallback, useEffect, useState } from "react";
import {
  getCustomers,
  getPendingRequests,
  getSuppliers,
} from "../../../services/portfolios";
import { notification } from "../../../uteis/notifications";

function useWallet({ isSupplier }) {
  const [loading, setLoading] = useState(true);
  const [hasChange, setHasChange] = useState(false);
  const [clients, setClients] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);

  const getClientes = async () => {
    setLoading(true);
    await getCustomers()
      .then((response) => {
        if (response.data.resposta.conteudo.customers) {
          setClients(response.data.resposta.conteudo.customers);
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages;
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              notification("Ops!!", message, "danger");
            });
          });
          return;
        }
        notification("Ops!!", "Erro interno no servidor!", "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getFornecedores = async () => {
    setLoading(true);
    await getSuppliers()
      .then((response) => {
        if (response.data.resposta.conteudo.suppliers) {
          setSuppliers(response.data.resposta.conteudo.suppliers);
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages;
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              notification("Ops!!", message, "danger");
            });
          });
          return;
        }

        notification("Ops!!", "Erro interno no servidor!", "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getSolicitacoes = async () => {
    await getPendingRequests()
      .then((response) => {
        setPendingRequests(response.data.resposta.conteudo.pendingRequests);
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages;
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              notification("Ops!!", message, "danger");
            });
          });
          return;
        }
        notification("Ops!!", "Erro interno no servidor!", "danger");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const runAllInitData = useCallback(() => {
    const calleds = [getFornecedores(), getSolicitacoes()];

    if (isSupplier) {
      Promise.all([...calleds, getClientes()]).finally(
        () => {
          setHasChange(false);
        }
      );
      return 
    }
    Promise.all(calleds).finally(
      () => {
        setHasChange(false);
      }
    );
  }, [isSupplier]);

  useEffect(() => {
    runAllInitData();
  }, [runAllInitData]);

  useEffect(() => {
    if (hasChange) {
      runAllInitData();
    }
  }, [hasChange, runAllInitData]);

  return {
    wallet: {
      clients,
      suppliers,
      pendingRequests,
    },
    loading,
    setLoading,
    setHasChange,
    getClientes,
    getFornecedores,
    getSolicitacoes,
  };
}

export { useWallet };
