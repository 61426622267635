import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Col, FormGroup, Input, Row } from "reactstrap"

import { URL_TO_IMAGES } from '../../config/api.config'
import { clearMask, moneyMask } from "../../uteis/mask"
import { formatesVariations } from "../pedidos"
import product from './product.svg'

const Produto = ({ produto, disabled, isRespondida, respostaProduto, hideField, toggleModal, produtos, setProdutos, setProdutoToModal }) => {
  const [image, setImage] = useState(product)
  const [observacao, setObservacao] = useState("")
  const [disponivel, setDisponivel] = useState("Disponível")
  const [valor, setValor] = useState(0)

  useEffect(() => {
    if (produtos) {
      const newProduto = {
        product_id: produto.product_id,
        observations: observacao,
        status: disponivel,
        amount: produto.quantity,
        unitary_value: clearMask(valor)
      }
      const oldProdutos = produtos.filter(prod => prod.product_id !== produto.product_id)
      setProdutos([...oldProdutos, newProduto])
    }
    if (produto.images && produto.images.length > 0) {
      const images = produto.images
      if (images.length === 0) {
        return
      }
      if (images.some(i => i.featured_image === 1)) {
        setImage(URL_TO_IMAGES + '/' + images.find(i => i.featured_image === 1).path.replace('public', ''))
        return
      }
      setImage(URL_TO_IMAGES + '/' + images[0].path.replace('public', ''))
    }
  }, [observacao, disponivel, valor])

  return (
    <div className="media media-sm border-bottom pb-4">
      <img src={image} alt="" className="media-object rounded mr-3" />

      <div className="media-body">

        <h5 className="media-heading">
          <Link to={`/produto/${produto.product_id}`}>{produto.name}</Link>
          <button
            className="btn btn-info ml-2 btn-icon btn-circle btn-sm"
            onClick={() => {
              setProdutoToModal(produto)
              return toggleModal ? toggleModal({ ...produto, image }) : null
            }
            }
          >
            <i className="fas fa-info "></i>
          </button>
        </h5>

        {produto?.variations ? (
          <div style={{
            display: "flex",
            flexWrap: "wrap",
            gap: ".5rem",
          }}>
            {formatesVariations(produto?.variations)}
          </div>
        ) : null}

        <small><strong>Observação</strong>: {produto.observations}</small><br />
        <div className="mb-2">
          <small><strong>Unid. de medida</strong>: {produto.unit_measurements_name}</small><br />
          <small><strong>Quant.</strong>: {produto.quantity}</small>
        </div>
        {!hideField || isRespondida ?
          <Row>
            {disabled ?
              <Col>
                <hr />
                <p className="mb-1"><strong>Observação</strong>: {respostaProduto.observations}</p>
                {
                  respostaProduto.status === "Indisponível" ? (
                    <span className="badge-soft-warning badge badge-warning">Indisponível</span>
                  ) : (
                    <span className="badge-soft-success badge badge-secondary">Disponivel</span>
                  )
                }
                <p className="mt-2"> <strong>Valor Unit.:</strong>: {moneyMask(respostaProduto.unitary_value.toString().includes('.') ? respostaProduto.unitary_value.toFixed(2) : respostaProduto.unitary_value.toFixed(2))}</p>
                <p className="mt-2"> <strong>Valor TOTAL:</strong>: {moneyMask((respostaProduto.unitary_value * respostaProduto.amount).toFixed(2))}</p>
              </Col>
              : <>
                <Col sm="12" >
                  <FormGroup className="mb-2">
                    <Input required disabled={disabled} value={observacao} onChange={(e) => setObservacao(e.target.value)} type="textarea" name="observacao" placeholder="Observação" />
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <FormGroup>
                    <Input required disabled={disabled} value={disponivel} onChange={(e) => setDisponivel(e.target.value)} type="select" name="disponivel">
                      <option value={'Disponível'}>Disponível</option>
                      <option value={'Indisponível'}>Indisponível</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm="6">
                  <FormGroup>
                    <Input
                      required
                      disabled={disabled}
                      value={moneyMask(valor) || ''}
                      onChange={(e) => {
                        setValor(e.target.value)
                      }}
                      type="text"
                      placeholder="Valor"
                    />
                  </FormGroup>
                </Col>
                <Col sm="3">
                  <div className="d-flex flex-column">
                    {/* TOTAL desse produto <span style={{fontSize: '1.1rem', fontWeight: 'bold'}}>R$ {parseFloat(valor.toString().replace('R$ ', '').replace('.', '').replace(',', '.')) * parseFloat(produto.quantity)}</span> */}
                    TOTAL desse produto <span style={{ fontSize: '1.1rem', fontWeight: 'bold' }}>{moneyMask(clearMask(valor) * produto.quantity)}</span>
                  </div>
                </Col>
              </>
            }
          </Row> : null
        }
      </div>
    </div>
  )
}

export default Produto
