import React from "react";
import { Item } from "../v2/produto/item";
import { Empresa } from "./components";

const escolherTipo = (tipo, item, setarPosition) => {
  switch (tipo) {
    case "produto":
    case "servico":
      return (
        <li>
          <Item item={item} setarPosition={setarPosition} />
        </li>
      );
    case "empresa":
      return (
        <li>
          <Empresa empresa={item} setarPosition={setarPosition} />
        </li>
      );
    default:
      return null;
  }
};
const ItemProcurar = ({ item, isFornecedor, setarPosition }) => {
  if (!item) return null;
  return escolherTipo(item.tipo, item, setarPosition);
};

export default ItemProcurar;
