import React from 'react';
import styled from "styled-components";
import VariationListItemProduct from "../../variacaoProduto/components/VariationListItem";

const SelectVariation = styled.div`
  cursor: pointer;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  background-color: ${(props) =>
    props.active ? props.theme.colors.gray3 : "transparent"};
  &:hover {
    background-color: ${(props) => !props.active && props.theme.colors.gray1};
  }
`;

export function VariationSelect({prodVariation, activeVariation, setActiveVariation, typeCompany = "produto"}) {
  const images = typeCompany === "produto" ?
    prodVariation?.products_images.filter(img => img.variations_product.variation_product_id === prodVariation.id) || []
  : prodVariation?.services_images.filter(img => img.variations_service.variation_service_id === prodVariation.id) || []

  return (
    <SelectVariation
      key={prodVariation.id}
      active={prodVariation.id === activeVariation.id}
      onClick={() => setActiveVariation({
        ...prodVariation,
        images
      })}
    >
      <VariationListItemProduct
        prodVariation={{
          products_variations:
            prodVariation?.products_variations ||
            prodVariation?.services_variations,
          ...prodVariation,
        }}
        isShow
      />
    </SelectVariation>
  );
}
