import * as type from "./types";

export const getDataRequested = (action) => {
  return {
    type: type.SET_SEARCH_DATA_REQUESTED,
    payload: action,
  };
};

export const getDataSuccess = (result) => {
  return {
    type: type.SET_SEARCH_DATA_SUCCESSED,
    payload: result,
  };
};

export const setTypeRequested = (typ) => {
  return {
    type: type.SET_SEARCH_TYPE_PARAMS,
    payload: typ,
  };
};

export const setTypeSuccess = (result) => {
  return {
    type: type.SET_SEARCH_TYPE_PARAMS_SUCCESS,
    payload: result,
  };
};

export const setPageRequested = (typ) => {
  return {
    type: type.SET_SEARCH_PAGE_PARAMS,
    payload: typ,
  };
};

export const setPageSuccess = (result) => {
  return {
    type: type.SET_SEARCH_PAGE_PARAMS_SUCCESS,
    payload: result,
  };
};

export const setLoading = (result) => {
  return {
    type: type.SET_SEARCH_LOADING,
    payload: result,
  };
};
