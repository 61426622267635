import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import { mountFullAddress } from "../../../uteis/functions";

export function Empresa({ empresa, setarPosition }) {
  return (
    <>
      <Link
        onClick={() => setarPosition()}
        to={`/empresa/${empresa.id}`}
        className="result-image result-image-empresa"
      >
        <img
          className="rounded-corner"
          src={empresa.imagem || "/assets/img/gallery/gallery-52.jpg"}
          alt="Imagem do Logotipo"
        />
      </Link>
      <div className="result-info">
        <h4 className="title">
          <Link
            onClick={() => setarPosition()}
            to={`/empresa/${empresa.id}`}
          >
            {empresa.nome || empresa.fantasy_name}
          </Link>
        </h4>
        <p className="location">
          <Badge>
            {parseInt(empresa.profile) === 1 ? "fornecedor" : "cliente"}
          </Badge>
        </p>

        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: empresa.descricao }}
        ></div>
        {parseInt(empresa.profile) === 1 && (
          <p className="m-r-15 text-inverse-lighter">
            <i className="fa fa-boxes fa-fw fa-lg m-r-3"></i>Produtos:{" "}
            {empresa.products_count || 0} |{" "}
            <i className="fa fa-people-carry fa-fw fa-lg m-r-3"></i>
            Serviços: {empresa.services_count || 0}
          </p>
        )}
        <div className="btn-row">
          {parseInt(empresa.profile) === 1 && empresa.estrelas && (
            <Link
              onClick={() => setarPosition()}
              to={`/empresa/${empresa.id}`}
              data-toggle="tooltip"
              data-container="body"
              data-title="Analytics"
            >
              <i className="far fa-star"></i> {empresa.estrelas}
            </Link>
          )}
          <Link
            onClick={() => setarPosition()}
            to={`/empresa/${empresa.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            <i className="far fa-eye"></i> {empresa.hit || 0}
          </Link>
        </div>
        <p className="text-muted mt-3">
          {mountFullAddress({
            address: empresa.address,
            neighborhood: empresa.neighborhood,
            complement: empresa.complement,
            city: empresa.city,
            uf: empresa.uf,
            postal_code: empresa.postal_code,
          })}
        </p>
      </div>
      <div className="result-price">
        <Link
          onClick={() => setarPosition()}
          to={`/empresa/${empresa.id}`}
          className="btn btn-yellow btn-block"
        >
          Ver perfil
        </Link>
      </div>
    </>
  );
}
