import React from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../config/api.config";
import { formatedVariationsPrice } from "../../uteis/formatedVariationsPrice";
import { moneyMask } from "../../uteis/mask";
const btns = item => {
  return (
    <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.updateActive(item.servico);
        }}
        className="btn btn-warning btn-icon btn-circle btn-lg m-3"
        title={item.servico.active === 1 ? "Desativar" : "Ativar"}

      >
        {
          item.servico.active === 1 ?
            <i className="fa fa-ban"></i> :
            <i className="fas fa-play"></i>
        }
      </Button>
      <Link
        to={`/editar/servico/${item.servico?.id}?name=${item.servico?.name}&desc=${item.servico?.description}`}
        className="btn btn-success btn-icon btn-circle btn-lg m-3"
        title="Editar"
      >
        <i className="fa fa-edit"></i>
      </Link>
      <Link
        to={`/cadastrar/servico/${item.servico?.id}?name=${item.servico?.name}&desc=${item.servico?.description}`}
        className="btn btn-primary btn-icon btn-circle btn-lg m-3"
        title="Copiar"
      >
        <i className="fa fa-clone"></i>
      </Link>
      <Link
        to={`/servico/${item.servico?.id}/variacao`}
        className="btn btn-info btn-icon btn-circle btn-lg m-3"
        title="Variações"
      >
        <i className="fas fa-boxes"></i>
      </Link>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setDeletar(item.servico)
        }}
        className="btn btn-danger btn-icon btn-circle btn-lg m-3"
        title="Excluir"
      >
        <i className="fa fa-times"></i>
      </Button>
    </div>
  )
}

const Servico = (props) => {
  const { servico } = props;
  const image = servico.images[0]?.path ? `${URL_TO_IMAGES}/${servico.images.find(img => img.featured_image === 1 || servico.images.length === 1)?.path.replace('public', '')}` : '/assets/img/default-product.svg'

  return (
    <li style={{
      border: servico.active === 1 ? 'none' :'2px solid #ff5b57'
    }}>
      <div
        to={`/servico/${servico.id}`}
        className="result-image"
        style={{
          backgroundImage: `url( ${image || "/assets/img/default-product.svg"} )`,
        }}
      >
      </div>
      <div className="result-info">
        <h4 className="title">
          <Link to={`/servico/${servico.id}`}>
            {servico.name}
          </Link>
        </h4>
        <span className="badge badge-secondary">{servico.category.name}</span>
        <div className="desc" dangerouslySetInnerHTML={{ __html: servico.description }}>
        </div>
        <div className="btn-row">
          {
            servico.avg && (
              <Link
                to={`/servico/${servico.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Analytics"
              >
                <>
                  <i className="far fa-star"></i> {servico.avg}
                </>
              </Link>
            )
          }
          <Link
            to={`/servico/${servico.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            {/* <i className="far fa-eye"></i> {loadingVisits ? <i className="fas fa-spinner fa-spin"></i>: visits || 0}  */}
            <i className="far fa-eye"></i> {servico?.hit || 0}
          </Link>

        </div>
      </div>
      <div className="result-price" style={{ zIndex: 4, paddingTop: 20  }}>
        {servico.active !== 1 && (
          <span
            className="badge badge-danger"
            style={{
              position: "absolute",
              top: 0,
              right: "-10px",
              paddingRight: "20px",
              fontSize: "13px",
              borderRadius: 0
            }}
          >
            DESATIVADO
          </span>
        )}

        <div className="d-sm-block align-self-end align-self-sm-end">
          {servico.variations_price ?
            formatedVariationsPrice(servico.variations_price) :
            moneyMask(servico.default_price, true)
          }
        </div>

        <div className="d-sm-block align-self-end align-self-sm-end">
          {props.isUser ? btns(props) : null}
        </div>

        <Link to={`/servico/${servico.id}`} className="btn btn-yellow btn-block">
          Ver Serviço
        </Link>
      </div>
    </li>
  );
};

export default Servico;
