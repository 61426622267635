import { API } from '../../config/api.config';
import api from "../api";


export const getAddress = (addressId) => api.get(`${API}/auth/adresses/${addressId}`);
export const getAdresses = () => api.get(`${API}/auth/adresses`);

export const createAddress = (values) => api.post(`${API}/auth/adresses`, values);
export const updateAddress = (values) => api.put(`${API}/auth/adresses/${values.id}`, values);
export const deleteAddress = (addressId) => api.delete(`${API}/auth/adresses/${addressId}`);
