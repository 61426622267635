import React, { useContext, useEffect, useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  Button,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from "reactstrap";
import Breadcrumb from "../../components/breakcumbs";
import ItemProduto from "../../components/itemProcurar";
import Loader from "../../components/loader";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import ModalFiltro from "./parts/modalFiltro";
import Pagination from "./parts/pagination";

// cadastrar categoria
const listaProdutos = [
  {
    tipo: "produto",
    nome: "Produto 1",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "312213",
  },
  {
    tipo: "servico",
    nome: "Produto 3",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "242345",
  },
  {
    tipo: "empresa",
    nome: "Produto 3",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "12312",
  },
  {
    tipo: "servico",
    nome: "Produto 4",
    estrelas: "3/4",
    visualizacoes: 234,
    categoria: "Categoria",
    descricao: "Descritivo do produto: bla, bla, bla",
    id: "423423",
  },
]

const Home = ( ) => {
  const [loading, setLoading] = useState(true);
  const [modalFiltro, setModalFiltro] = useState(false)
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const contextType = useContext(PageSettings);
  const [deletar, setDeletar] = useState(false)
  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };
  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const confirmar = ()=>{
    console.log("confirmar")
    setDeletar(false)
  }
  const deletarItem = ()=>{
    console.log("confirmar")
    setDeletar(false)
  }

  const toggleModalFiltro = () => {
    setModalFiltro(!modalFiltro)
  }
  return (
    <>
    {(deletar &&
      <SweetAlert danger showCancel
        confirmBtnText="Sim, deletar!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Você tem certeza?"
        onConfirm={confirmar}
        onCancel={deletarItem}
      >
        Após essa ação Você não conseguira recuperar os dados.
      </SweetAlert>
    )}
      <Loader loading={loading} />

      
      <Breadcrumb list={[{name: "Home", path: "/"} ,{name: "Procurar cliente", path: "/cadastroEmpresa"}]} />
      <TitlePage title="Procurar cliente" desc="Pesquise por nome, setor de atividade" icon="fab fa-simplybuilt" />
      <div className="row">
      <ModalFiltro modal={modalFiltro} toggle={toggleModalFiltro} />
        <div className="col-md-12">
          <div className="result-container">
            <InputGroup size="lg" className="m-b-20">
              <Input
                placeholder="Pesquise o seu cliente..."
                type="text"
                className="input-white"
              />
              <InputGroupButtonDropdown
                addonType="append"
                isOpen={splitButtonOpen}
                toggle={toggleSplit}
              >
                <Button color="success" >
                <i className="fa fa-search fa-fw"></i>
                </Button>
              </InputGroupButtonDropdown>
            </InputGroup>
            <button className="btn btn-yellow mb-3" onClick={toggleModalFiltro}>Pesquisa Avançada</button>
            <ul className="result-list">
              {
                listaProdutos.map((produto =>{
                  return <ItemProduto  cliente={true} type="" key={Math.random()} isUser={true} produto={produto} setDeletar={setDeletar}/>
                }))
              }
            </ul>
            <div className="clearfix m-t-20">
              <Pagination />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
