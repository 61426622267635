import React from "react";
import { ThemeProvider } from "styled-components";

const theme = {
  colors: {
    white: "#ffffff",
    gray1: "#eff4f9",
    gray3: "#909ba6",
    blue3: "#18568f",
    blue4: "#0e406f",
    blue5: "#0a243c",
    success: "#18ce0f",
    primary: "#348fe2",
    primary2: "#18568f",
    warning: "#FFB236",
    danger: "#ff5b57",
    danger2: "#ff2924",
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
