import { useCallback, useEffect, useState } from "react";
import { createImages, getProduct } from "../../../services/product";
import {
  createVariationsProducts as createVariationsProductsAPI,
  deleteVariationsProducts as deleteVariationsProductsAPI,
  getVariationsProducts,
  updateVariationsProducts as updateVariationsProductsAPI,
} from "../../../services/variationsProduct";
import { orderBy } from "../../../uteis";
import { notification } from "../../../uteis/notifications";

export function useVariacaoProduto({ id }) {
  const [loading, setLoading] = useState(false);
  const [produto, setProduto] = useState();
  const [produtoVariacoes, setProdutoVariacoes] = useState([]);

  const getProduto = useCallback(async () => {
    if (id) {
      await getProduct(id)
        .then((response) => {
          const resProd = response.data.resposta.conteudo.product;
          setProduto(resProd);
        })
        .catch((error) => {
          console.log("Erro ao buscar produto", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const getProdutoVariacoes = useCallback(async () => {
    if (id) {
      await getVariationsProducts(id)
        .then((response) => {
          const resProd = response.data.resposta.conteudo.productVatiations;

          const newData = resProd.data.map(prodVar => {
            const idProdVar = prodVar.id;
            const images = prodVar?.products_images.filter(img => img?.variations_product?.variation_product_id === idProdVar);

            return {
              ...prodVar,
              products_images: images
            }
          })
          setProdutoVariacoes({...resProd, data: newData});
        })
        .catch((error) => {
          console.log("Erro ao buscar produto", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const createVariationsProducts = async (formdatData, callback) => {
    await createVariationsProductsAPI(formdatData)
      .then(async (response) => {
        if (response) {
          notification(
            "Variação do produto salva com sucesso!",
            "Sua variação do produto foi salva e já esta disponivel na plataforma",
            "success"
          );
        }

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages;

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            notification(
              "Ops!!",
              error.response.data.resposta?.conteudo?.error,
              "danger"
            );
          }

          if (messages) {
            Object.keys(messages).forEach((key) => {
              messages[key].forEach((message) => {
                notification("Ops!!", message, "danger");
              });
            });
          }
          return;
        }

        notification("Ops!!", "Erro interno no servidor!", "danger");
      });
  };

  const updateVariationsProducts = async (id, formdatData, callback) => {
    formdatData.append("id", id);
    formdatData.append("_method", "PUT");
    await updateVariationsProductsAPI(id, formdatData)
      .then(async (response) => {
        notification(
          "Variação do produto salva com sucesso!",
          "Sua variação do produto foi salva e já esta disponivel na plataforma",
          "success"
        );
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages;

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            notification(
              "Ops!!",
              error.response.data.resposta?.conteudo?.error,
              "danger"
            );
          }

          if (messages) {
            Object.keys(messages).forEach((key) => {
              messages[key].forEach((message) => {
                notification("Ops!!", message, "danger");
              });
            });
          }
          return;
        }

        notification("Ops!!", "Erro interno no servidor!", "danger");
      });
  };

  
  const deleteVariationsProducts = async (id, callback) => {
    await deleteVariationsProductsAPI(id)
      .then(async (response) => {
        notification(
          "Variação do produto salva com sucesso!",
          "Sua variação do produto foi salva e já esta disponivel na plataforma",
          "success"
        );
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages;

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            notification(
              "Ops!!",
              error.response.data.resposta?.conteudo?.error,
              "danger"
            );
          }

          if (messages) {
            Object.keys(messages).forEach((key) => {
              messages[key].forEach((message) => {
                notification("Ops!!", message, "danger");
              });
            });
          }
          return;
        }

        notification("Ops!!", "Erro interno no servidor!", "danger");
      });
  };

  const savePhotos = async (valores, isEdit) => {
    const filesOrder = orderBy(valores.photos, "order");

    const currentImages = {
      current_images: [],
    };
    filesOrder.forEach(async (file, index) => {
      if (file?.id) {
        currentImages.current_images.push(`id: ${file.id}, order: ${index}`);
        return;
      }
      file.order = index;
      if (produto.id) {
        const newImages = new FormData();
        newImages.append("product_id", produto.id);
        newImages.append("images[]", file);
        await createImages(newImages).then((response) => {
          currentImages.current_images.push(
            `id: ${response.data.resposta.conteudo.images[0].id}, order: ${file.order}`
          );
        });
      }
    });

    return currentImages;
  };

  useEffect(() => {
    getProduto();
    getProdutoVariacoes();
  }, [getProduto, getProdutoVariacoes]);

  return {
    produto,
    produtoVariacoes,
    getProdutoVariacoes,
    createVariationsProducts,
    updateVariationsProducts,
    deleteVariationsProducts
  };
}
