import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, withRouter, Switch } from "react-router-dom";
import { routes, routesAuth } from "./../../config/page-route.jsx";
import { PageSettings } from "./../../config/page-settings.js";
import AuthMiddleWare from "../../components/auth";
import { setTypeRequested } from "../../store/pesquisa/action";

const pathsToAdvancedSearch = ['procurar', 'produto', 'servico', 'fornecedor']

function setTitle(path, routeArray) {
  var pageTitle;
  for (var i = 0; i < routeArray.length; i++) {
    if (routeArray[i].path === path) {
      pageTitle = "Achei Forcenedor | " + routeArray[i].title;
    }
  }
  document.title = pageTitle ? pageTitle : "Achei Fornecedor";
}

function ContentF(props) {
	const dispatch = useDispatch();
  useEffect(() => {
	const pathname = props.history.location.pathname;
	setTitle(props.history.location.pathname, routes);

	const pathArray = pathname.split('/');
	const hashPathSearchh = pathArray.find(path => pathsToAdvancedSearch.includes(path));
	if (!hashPathSearchh) {
		dispatch(setTypeRequested({ pesquisar: "produtos" }));
	}
  }, [dispatch, props.history.location.pathname]);
  return (
    <PageSettings.Consumer>
      {({ pageContentFullWidth, pageContentClass, pageContentInverseMode }) => (
        <div
          className={
            "content " +
            (pageContentFullWidth ? "content-full-width " : "") +
            (pageContentInverseMode ? "content-inverse-mode " : "") +
            pageContentClass
          }
        >
          <Switch>
            {routes.map((route, index) => (
              <AuthMiddleWare
                key={index}
                route={route}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
            {routesAuth.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ))}
          </Switch>
        </div>
      )}
    </PageSettings.Consumer>
  );
}

export default withRouter(ContentF);
