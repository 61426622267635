import api from "../api";
import { API, BASE_URL_SERVICES } from '../../config/api.config'


export const getService = (serviceId) => api.get(`${BASE_URL_SERVICES}/${serviceId}`)
export const getServices = (params) => api.get(`${BASE_URL_SERVICES}`, {
    params
})

export const getOtherServices = (params) => api.get(`${API}/auth/services-company`, {params})

export const deleteService = (serviceId) => api.delete(`${BASE_URL_SERVICES}/${serviceId}`)
export const createService = (service, serviceId = null) => {
    return api.post(`${BASE_URL_SERVICES}${serviceId ? '/'+serviceId : ''}`, service)
}

export const createImages = (values) => api.post(`auth/services-images`, values)
export const updateImages = (values, serviceId) => api.post(`auth/services-images/${serviceId}`, values)

export const deleteServiceImage = (imageId) => api.delete(`auth/service-image/${imageId}`)


export const getServiceVisits = (id) => {
    return new Promise( (resolve, reject) =>{
        api.get(`${API}/auth/dashboard/number-visits-service/${id}`)
                .then(result => resolve(result.data.resposta.conteudo.numberVisitsService))
                .catch( err => reject(err) )
    })
}
