import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
// verificar se o usuario esta logado no amplify
const Authmiddleware = ({ component: Component, path }) => {
	return (
		<Route
			path={path}
			render={props => {
				let logoutBy = null;
				const accessToken = localStorage.getItem("access_token")
				const expiresAt = localStorage.getItem("expires_at") || 0
				const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")) : ""
				if (!accessToken || new Date() > new Date(expiresAt)) {
					logoutBy = 'expires_token';
          return <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				}

				if (!userInfo?.email_verified_at && !!userInfo.email_verified_at !== true) {
					logoutBy = 'verify_email'
				}

				switch (logoutBy) {
					case 'verify_email':
						return <Redirect to={{ pathname: "/login/nao-valido", state: { from: props.location } }} />
					default:
						return <Component  {...props} />
				}
			}}
		/>
	)
};

export default withRouter(Authmiddleware);
