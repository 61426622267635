import api from "../api";


export const createQuotation = (data) => api.post(`auth/product-quotations`, data)

export const responseQuotation = (data) => api.post(`auth/product-quotations/response`, data)
export const getResponseQuotation = (idCotacao) => api.get(`auth/product-quotations/response/${idCotacao}`)

export const getMyQuotations = (params) => api.get(`auth/my-quotations`, {params})
export const getRequestQuotations = (params) => api.get(`auth/customer-quotation`, {params})

export const getMyQuotation = (idQuotation) => api.get(`auth/product-quotations/${idQuotation}`)
