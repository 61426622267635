import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useRouteMatch } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { BackButton } from "../../components/buttons/BackButton";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import { createImages, updateImages } from "../../services/product";
import { clearMask } from "../../uteis/mask";
import { notificationToast } from "../../uteis/notifications";
import { VariationName } from "../variacao/components";
import { useVariacao } from "../variacao/hooks/useVariacao";
import VariationListItem from "./components/VariationListItem";
import ModalProdutoVariacaoForm from "./form";
import { useVariacaoProduto } from "./hooks/useVariacaoProduto";

export default function VariacaoProduto() {
  const [deletar, setDeletar] = useState(null);
  const [valores, setValores] = useState({
    variations: [{}],
    price: "",
    photos: [],
  });
  const [modalProdutoVariacao, setModalProdutoVariacao] = useState(false);

  const {
    params: { id },
  } = useRouteMatch();

  const { variacoes } = useVariacao();
  const {
    produto,
    produtoVariacoes,
    getProdutoVariacoes,
    createVariationsProducts,
    updateVariationsProducts,
    deleteVariationsProducts
  } = useVariacaoProduto({ id });

  const contextType = useContext(PageSettings);

  const confirmar = async () => {
    setDeletar(null);

    await handleSubmit(null, true);
  };

  const resetForm = () => {
    setValores({
      variations: [{}],
      price: "",
      photos: [],
    });
  };
  const toggleModalProdutoVariacao = () => {
    setModalProdutoVariacao(!modalProdutoVariacao);
  };

  const handleSubmit = async (event, isDelete) => {
    if (event) {
      event.preventDefault();
    }

    if (isDelete) {
      await deleteVariationsProducts(valores.id, async () => {
        await getProdutoVariacoes();
      })

      return
    }

    let price = valores.price;
    if (!isDelete && produto.price_type !== "cotation" && !(!!clearMask(price))) {
      notificationToast("Campo 'Preço padrão' é obrigátorio", "error")
      return
    }

    if (typeof price === "string") {
      const newPrice = valores.price.split(",");
      newPrice[0] = newPrice[0].replace("R$ ", "").replace(".", "");
      price = Number(newPrice.join("."));
    }

    const filesOrder = valores.photos;

    const formdatData = new FormData();
    formdatData.append("price", price);
    formdatData.append("product_id", produto.id);

    const currentImages = {
      _method: valores.edit ? "put" : "post",
      is_variation: 1,
      current_images: [],
      current_images_2: [],
    };

    filesOrder.forEach(async (file, index) => {
      if (file?.id) {
        currentImages.current_images.push(`id: ${file.id}, order: ${index}`);
        return;
      }
      file.order = index;
      if (produto.id) {
        const newImages = new FormData();
        newImages.append("product_id", produto.id);
        newImages.append("images[]", file);
        newImages.append("is_variation", 1);
        newImages.append("variation_id", valores.id);
        if (valores.id) {
          await createImages(newImages).then((response) => {
            currentImages.current_images.push(
              `id: ${response.data.resposta.conteudo.images[0].id}, order: ${file.order}`
            );
          });
        }
      }
      formdatData.append("images[]", file);
    });

    valores.variations.forEach(async (vari, index) => {
      if ("variation_id" in vari) {
        formdatData.append("variations[]", vari.variation_id);
        return;
      }
      formdatData.append("variations[]", vari.id);
    });

    for (let pair of formdatData.entries()) {
      if (pair[1] === "null" || pair[1] === null) {
        formdatData.delete(pair[0]);
      }
    }

    formdatData.append("active", 1);

    if (isDelete) {
      formdatData.delete("active");
      formdatData.append("active", 0);
      updateVariationsProducts(valores.id, formdatData, async () => {
        await getProdutoVariacoes();
      });
      return;
    }

    if (valores.edit) {
      await updateImages(currentImages, produto.id).then((response) => {
        updateVariationsProducts(valores.id, formdatData, async () => {
          await getProdutoVariacoes();
          toggleModalProdutoVariacao();
        });
      });
      return;
    }

    createVariationsProducts(formdatData, async () => {
      await getProdutoVariacoes();
      toggleModalProdutoVariacao();
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ position: 'relative' }}>
      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={() => setDeletar(null)}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}

      <TitlePage
        title={`Variações do Produto: ${produto?.name}`}
        desc="Aqui você adiciona variações ao Produto."
        icon="fas fa-box"
      />

      <BackButton />

      <div className="row">
        <div className="col-md-12">
          <Row className="justify-content-end mt-3">
            {modalProdutoVariacao && (
              <ModalProdutoVariacaoForm
                open={modalProdutoVariacao}
                toggle={toggleModalProdutoVariacao}
                handleSubmit={handleSubmit}
                variacoes={variacoes}
                valores={valores}
                setValores={setValores}
                isCotation={produto.price_type === "cotation"}
              />
            )}
            <Col md="4" lg="4" xl="2">
              <button
                className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
                onClick={() => {
                  resetForm();
                  toggleModalProdutoVariacao();
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>
        </div>
        <div className="col-md-12">
          {produtoVariacoes?.data && produtoVariacoes?.data.length > 0 ? (
            produtoVariacoes.data.map((prodVariation) => (
              <VariationListItem
                key={prodVariation.id}
                prodVariation={prodVariation}
                callbackEdit={() => {
                  setValores({
                    ...prodVariation,
                    edit: true,
                    variations: prodVariation?.products_variations,
                    photos: prodVariation?.products_images || [],
                  });
                  toggleModalProdutoVariacao();
                }}
                callbackDelete={() => {
                  setValores({
                    ...prodVariation,
                    edit: true,
                    variations: prodVariation?.products_variations,
                    photos: [],
                  });
                  setDeletar({ ...prodVariation, active: 0 });
                }}
              />
            ))
          ) : (
            <VariationName>Nenhuma variação criada!</VariationName>
          )}
        </div>
      </div>
    </div>
  );
}
