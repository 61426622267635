import { API } from "../../config/api.config";
import api from "../api";


const baseUrlCompanie = `${API}/auth/companies`

export const createCompanie = (values) => api.post(`${API}/auth/register`, values)

export const getCompanies = () => api.get(`${baseUrlCompanie}`)
export const getCompanie = (companieId) => api.get(`${baseUrlCompanie}/${companieId}`)
export const updateCompanie = (companieId, values) => api.put(`${API}/auth/companies/${companieId}`, values)

export const uploadLogo = (data) => api.post(`${API}/auth/companies/upload-logo`, data)

export const saveBusinessHour = (data) => api.post(`${API}/auth/business-hour`, data)
export const updateBusinessHour = (hourId, data) => api.put(`${API}/auth/business-hour/${hourId}`, data)

export const getEconomicActivity = () => api.get(`${API}/auth/economicActivity`)
export const getCompanyType = () => api.get(`${API}/auth/companyType`)
