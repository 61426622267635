import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link, useHistory } from "react-router-dom";
import validator from "validator";
// reactstrap components
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner
} from "reactstrap";
import Loader from '../../components/loader';
import { createCompanie } from "../../services/companies/index.js";
import { validaCpfCnpj } from "../../uteis/index";
import { cnpjMask } from "../../uteis/mask";
import { PageSettings } from './../../config/page-settings.js';

import "react-toastify/dist/ReactToastify.min.css";
import { reactivateAccount, sendTokenToEmail } from "../../services/auth/reactivate";

// core components
function Cadastro() {
  const contextType = useContext(PageSettings);
  const history = useHistory()

  const [tipoCadastro, setartipoCadastro] = useState(null);
  // setar os valores de cada campo
  const [cnpj, setCnpj] = useState("")
  const [companyName, setComanyName] = useState("")
  const [email, setEmail] = useState("")
  const [c_email, setC_email] = useState("")
  const [password, setPassword] = useState("")
  const [c_password, setC_password] = useState("")
  const [btnCarregando, setBtnCarregando] = useState(false)
  const [termodeUso, setTermoDeUso] = useState(false);
  const [termodePrivacidade, setTermoDePrivacidade] = useState(false);

  const [showModalHasAccount, setShowModalHasAccount] = useState(null)
  const [tokenSend, setTokenSend] = useState(false)
  const [token, setToken] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    contextType.handleSetPageSidebar(false);
    contextType.handleSetPageHeader(false);
    contextType.handleSetPageContentFullWidth(true);
    return () => {
      contextType.handleSetPageSidebar(true);
      contextType.handleSetPageHeader(true);
      contextType.handleSetPageContentFullWidth(false);
    }
  }, [])
  const clearFields = () => {
    setCnpj("")
    setComanyName("")
    setEmail("")
    setC_email("")
    setPassword("")
    setC_password("")
  }
  const msg = (msg) => {
    toast.error(
      msg,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 15000,
      }
    );
  }

  const changePrivacidade = (e) => {
    setTermoDePrivacidade(!termodePrivacidade)
  }

  const changeTermos = (e) => {
    setTermoDeUso(!termodeUso)
  }

  const thereErros = () => {
    if (!cnpj || !validaCpfCnpj(cnpj)) {
      msg("Campo CNPJ é obrigatório ou nāo é válido")
      return true
    }

    if (cnpj.length < 18) {
      msg("O Campo CNPJ não ė valido")
      return true
    }

    if (!companyName) {
      msg("Preencha o campo Razão social")
      return true
    }

    if (!email || (!validator.isEmail(email))) {
      msg("Campo E-MAIl é obrigatório ou a formatação do mesmo não esta adequada")
      return true
    }
    if (email !== c_email) {
      msg("Campos E-MAIl e sua confirmação não coincidem")
      return true
    }

    if (password !== c_password || password.length < 5) {
      msg("Campos senha e sua confirmação não coincidem e devem conter mais de cinco caracteres")
      return true
    }

    if (!termodeUso) {
      msg("Aceite nosso termo de uso")
      return true
    }

    if (!termodePrivacidade) {
      msg("Aceite nosso termo de privacidade")
      return true
    }

    return false
  }
  const cadastrar = async (e) => {

    const fieldsErro = thereErros()
    if (fieldsErro)
      return

    setBtnCarregando(true)
    const dataToSave = {
      cnpj,
      company_name: companyName,
      email,
      profile: tipoCadastro === "Fornecedor" ? 1 : 2,
      c_password: c_password,
      password: password,
      terms_of_use: termodeUso ? 1 : 0,
      privacy_policy: termodePrivacidade ? 1 : 0
    }

    await createCompanie(dataToSave)
      .then((data) => {
        if (data) {
          toast.success(
            "Cadastro feito com sucesso para finalizar apenas confirme seu e-mail, clicando no link que enviamos para ele",
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false
            }
          );
          clearFields()
        }
      })
      .catch(err => {
        if (err && err.response) {
          if (err.response.data.resposta.status === 400) {
            setShowModalHasAccount(err.response.data.resposta.conteudo.messages)
            return
          }
          if (err.response.result.resposta.status === 500) {
            msg(err.response.result.resposta.conteudo.message)
            return
          }
          if (err.response.result.resposta.conteudo.error) {
            for (let item in err.response.result.resposta.conteudo.messages) {
              if (Array.isArray(err.response.result.resposta.conteudo.messages[item]))
                msg(err.response.result.resposta.conteudo.messages[item][0])
              else
                msg(err.response.result.resposta.conteudo.messages)
            }
            return
          }
          msg("Ops! Estamos com problema momentâneo tente novamente mais tarde!")
        }
      })
      .finally(() => {
        setBtnCarregando(false)
      })
  };

  const handleSendToken = async () => {
    setLoading(true)
    await sendTokenToEmail(email)
      .then(response => {
        setTokenSend(true)
        setShowModalHasAccount(response.data.resposta.conteudo.message.message)
      })
      .catch(err => {
        setTokenSend(false)
        console.log('ERROR send token', err, err.response);
      })
      .finally(() => setLoading(false))
  }

  const handleActiveAccount = async () => {
    setLoading(true)
    await reactivateAccount({ email, token })
      .then(response => {
        toast.success(
          response.data.resposta.conteudo.message,
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          }
        );
        setTokenSend(false)
        setShowModalHasAccount(null)
        history.push(`/login`)
      })
      .catch(err => {
        setTokenSend(false)
        console.log('ERROR reactivate', err, err.response);
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Loader loading={loading} />

      {(showModalHasAccount &&
        <SweetAlert
          warning
          title={showModalHasAccount}
          onConfirm={tokenSend ? handleActiveAccount : handleSendToken}
          customButtons={
            tokenSend ? (
              <>
                <Input
                  maxLength={18}
                  value={token}
                  placeholder={`Token enviado para o e-mail ${email}`}
                  type="text"
                  onChange={(e) => setToken(e.target.value)}
                >
                </Input>
                <button
                  className="btn btn-primary m-2"
                  onClick={handleActiveAccount}
                >
                  Reativar minha conta
                </button>
              </>
            ) : (
              <>
                <button
                  className="btn btn-outline-danger m-2 mr-5"
                  onClick={() => setShowModalHasAccount(null)}
                >
                  Não
                </button>
                <button
                  className="btn btn-primary m-2"
                  onClick={handleSendToken}
                >
                  Sim
                </button>
              </>
            )
          }
        >
        </SweetAlert>
      )}


      <div
        className="section section-cadastrar"
        style={{
          backgroundImage:
            "url(" +
            require("../../assets/img/cadastro.svg") +
            ")," +
            "url(" +
            require("../../assets/img/logar.svg") +
            ")",
        }}
      >
        <Container fluid>
          <Row>
            <Col
              md={11}
              className="d-flex justify-content-center justify-content-md-end"
            >
              <Card className="card-signup shadow-none">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <Link to="/">
                      <img
                        src={require("../../assets/img/logo@2x.png")}
                        style={{ maxWidth: 100 }}
                        alt="logo Achei Fornecedor"
                      />
                    </Link>
                    <CardTitle className="" tag="p">
                      {tipoCadastro ? (
                        <>
                          <i
                            className="fas fa-arrow-left mr-1"
                            onClick={() => setartipoCadastro(null)}
                            style={{ opacity: 0.5 }}
                          ></i>{" "}
                          Cadastro
                        </>
                      ) : (
                        "Se cadastrar como?"
                      )}
                    </CardTitle>
                  </CardHeader>
                  {!tipoCadastro ? (
                    <div className="d-flex justify-content-center op-cadastro">
                      <div
                        className="flex-grow-1 p-1"
                        onClick={() => setartipoCadastro("Comprador")}
                      >
                        <img
                          className="img-fluid"
                          src={require("../../assets/img/cliente.svg")}
                          alt="Selecionar Comprador"
                        />
                        <p className="text-center bold">Comprador</p>
                      </div>
                      <div
                        className="flex-grow-1 p-1"
                        onClick={() => setartipoCadastro("Fornecedor")}
                      >
                        <img
                          className="img-fluid"
                          src={require("../../assets/img/fornecedor.svg")}
                          alt="Selecionar Fornecedor"
                        />
                        <p className="text-center bold">Fornecedor</p>
                      </div>
                    </div>
                  ) : (
                    <CardBody>
                      <FormGroup>
                        <Input maxLength={18} value={cnpj} placeholder="CNPJ" type="text" onChange={(e) => setCnpj(cnpjMask(e.target.value))}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder="Razão social" type="text" value={companyName} onChange={(e) => setComanyName(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder="e-mail" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={c_email}
                          placeholder="Confirmação de email" type="email" onChange={(e) => setC_email(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input placeholder="Senha" value={password} type="password" onChange={(e) => setPassword(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          value={c_password}
                          placeholder="Confirmação de senha" type="password" onChange={(e) => setC_password(e.target.value)}></Input>
                      </FormGroup>
                      <FormGroup check>
                        <Input type="checkbox" name="check1" id="exampleCheck" onChange={changeTermos} />
                        <Label className="ml-0 pl-0" htmlFor="exampleCheck" check><a target="_blank" rel="noopener noreferrer" href="https://assests-achei-fornecedor.s3-us-west-2.amazonaws.com/Achei+Fornecedor-TermosdeUso.pdf">Termo de uso</a></Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input type="checkbox" name="check2" id="exampleCheck2" value={termodePrivacidade} onChange={changePrivacidade} />
                        <Label className="ml-0 pl-0" htmlFor="exampleCheck" check><a target="_blank" rel="noopener noreferrer" href="https://assests-achei-fornecedor.s3-us-west-2.amazonaws.com/Achei+Fornecedor-Politica+de+Privacidade.pdf">Termo de privacidade</a></Label>
                      </FormGroup>
                      <Button
                        className="btn btn-lg btn-lg bg-primary text-white btn-block mt-5"
                        color="primary"
                        onClick={cadastrar}
                        size="lg"
                        disabled={btnCarregando}
                      >
                        {btnCarregando ? <Spinner /> : null}
                        Cadastrar
                      </Button>
                    </CardBody>
                  )}
                </Form>
                <p className="text-center mt-3">
                  Já tem uma conta?{" "}
                  <Link to="/login">
                    <strong>logar</strong>
                  </Link>
                </p>
              </Card>
            </Col>
          </Row>
          <div className="col text-center"></div>
        </Container>
      </div>
    </>
  );
}

export default Cadastro;
