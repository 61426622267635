export const SET_SEARCH_DATA_REQUESTED = "serach@SET_SEARCH_DATA_REQUESTED"
export const SET_SEARCH_DATA_SUCCESSED = "serach@SET_SEARCH_DATA_SUCCESSED"
export const SET_SEARCH_DATA_FAILED = "serach@SET_SEARCH_DATA_FAILED" 


export const SET_SEARCH_TYPE_PARAMS = "serach@SET_SEARCH_TYPE_PARAMS" 
export const SET_SEARCH_TYPE_PARAMS_SUCCESS = "serach@SET_SEARCH_TYPE_PARAMS_SUCCESS" 


export const SET_SEARCH_PAGE_PARAMS = "serach@SET_SEARCH_PAGE_PARAMS" 
export const SET_SEARCH_PAGE_PARAMS_SUCCESS = "serach@SET_SEARCH_PAGE_PARAMS_SUCCESS" 

export const SET_SEARCH_LOADING = "serach@SET_SEARCH_LOADING" 