import jsPDF from "jspdf";
import "jspdf-autotable";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Loader from "../../components/loader";
import { usePedido } from "../../hooks/usePedido";
import { mountFullAddress } from "../../uteis/functions";
import { cnpjMask, moneyMask } from "../../uteis/mask";
import { statusEnumToText } from "../../uteis/orders";

const Content = styled.div`
  width: 1080px;
  background: white;
  padding: 20px;
  font-size: 15px;
`;
const Companies = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;

  div:last-child div {
    justify-content: flex-end;
  }
`;
const Company = styled.div`
  width: 500px;
  border: 1px solid;
  border-radius: 5px;
  padding: 15px;

  :last-child {
    text-align: end;
  }
`;
const Addresses = styled.div`
  display: flex;
  gap: 10px;
`
const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;
`;
const TextDescribe = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  div:nth-child(2) {
    font-weight: bold;
    font-size: 15px;
  }
`;
const ListProducts = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
`;
const Product = styled.div`
  width: 336px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border: 1px solid #c5cdd3;
  border-radius: 5px;
  padding: 10px;
`;
const ProductDetails = styled.div`
  display: flex;
  gap: 15px;
`;
const ProductVariations = styled.div`
  font-weight: 600;
  div:nth-child(1) {
    font-weight: 500;
    display: flex;
    gap: 5px 10px;
    flex-wrap: wrap;
    font-size: 12px;
  }
`;
const ProductValue = styled.div`
  border-top: 1px solid;
  margin-top: 10px;

  > div:first-child {
    display: flex;
    justify-content: flex-end;
  }
  > div:last-child {
    display: flex;
    justify-content: space-between;
    > div:last-child {
      font-weight: 600;
    }
  }
`
const CompanyInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 0 5px;
  span {
    font-weight: 700;
    margin-right: 5px;
  }
`

const Line = styled.div`
  width: 1080px;
  height: 1px;
  background-color: black;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: -20px;
`

const TotalValue = styled.div`
  display: flex;
  justify-content: space-between;
  > div {
    :last-child {
      text-align: end;
    }
  }
`

const formatedVariations = (variationSelected) => {
  if (!variationSelected) {
    return
  }
  const { products_variations } = variationSelected;
  const variations = products_variations.reduce((accumulator, currentValue, idx) => {
    const desc = currentValue.variation.parent.name;
    const valor = currentValue.variation.name;

    return `${accumulator} | ${desc} : ${valor}`
  }, '')

  return variations
};

export function PrintOrder() {
  const { pedido, setPedido } = usePedido();
	const { current_companie } = useSelector(state => state.auth)
  
  const history = useHistory();

  let cliente = pedido?.customer || undefined;

  if (pedido?.customer_id === current_companie?.id) {
    cliente = current_companie;
  }

  const pedidoFormated = {
    numero: pedido?.id || '',
    criadoEm: pedido?.created_at ? new Date(pedido.created_at).toLocaleDateString() : '',
    formaPagamento: pedido?.payment.name || '',
    status: pedido?.current_status.name ? statusEnumToText[pedido?.current_status.name].toUpperCase() : '',
    enderecoEntrega: pedido?.delivery_address?.address ? mountFullAddress(
      {
        address: pedido?.delivery_address?.address?.address,
        neighborhood: pedido?.delivery_address?.address?.neighborhood,
        complement: pedido?.delivery_address?.address?.complement,
        city: pedido?.delivery_address?.address?.city?.name,
        uf: pedido?.delivery_address?.address?.city?.uf_with_country?.name,
        postal_code: pedido?.delivery_address?.address?.postal_code,
      },
      true
    ) : '',
    enderecoFaturamento: pedido?.billing_address?.address ? mountFullAddress(
      {
        address: pedido?.billing_address?.address?.address,
        neighborhood: pedido?.billing_address?.address?.neighborhood,
        complement: pedido?.billing_address?.address?.complement,
        city: pedido?.billing_address?.address?.city?.name,
        uf: pedido?.billing_address?.address?.city?.uf_with_country?.name,
        postal_code: pedido?.billing_address?.address?.postal_code,
      },
      true
    ) : '',
    fornecedor: {
      nome: pedido?.supplier?.company_name || '',
      cnpj: pedido?.supplier?.cnpj ? cnpjMask(pedido?.supplier?.cnpj) : '',
      telefone: pedido?.supplier?.phone || '',
      whatsapp: pedido?.supplier?.whatsapp || '',
      email: pedido?.supplier?.email || '',
    },
    cliente: {
      nome: cliente?.company_name || '',
      cnpj: cliente?.cnpj ? cnpjMask(cliente?.cnpj) : '',
      telefone: cliente?.phone || '',
      whatsapp: cliente?.whatsapp || '',
      email: cliente?.email || '',
    },
    items: pedido?.items.map(item => ({
      nome: item?.name || '',
      codigo: item?.code || '',
      categoria: item?.category?.name || '',
      marca: item?.brand?.name || '',
      variacoes: item?.variationSelected ? formatedVariations(item?.variationSelected) : '',
      quatidade: item?.quantidade || '',
      preco: item?.selectedPrice || '',
    })),
    valorEntrega: pedido?.delivery_address?.value || '',
    valotTotalPedido: pedido?.total_value || '',
  };

  const handleGeneratePDF = useCallback(() => {
    const doc = new jsPDF();

    doc.html(document.getElementById("content"), {
      callback: async function (doc) {
        await doc.save(`${"PEDIDO-20240304-19"}.pdf`, { returnPromise: true });
        setPedido(null);

        history.goBack(); 
      },
      x: 10,
      y: 10,
      width: 190,
      windowWidth: 1080,
    });
  }, [history, setPedido]);

  useEffect(() => {
    if (pedido) {
      handleGeneratePDF()
    }
  }, [handleGeneratePDF, pedido])

  return (
    <>
      <Loader loading />
      <Content id="content">
        <h4
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>Pedido</div> { pedidoFormated.numero }
        </h4>

        <Details>
          <div
            style={{
              display: "flex",
              gap: "1rem",
            }}
          >
            <TextDescribe>
              <div>Criado em</div>
              <div>
                {pedidoFormated.criadoEm}
              </div>
            </TextDescribe>
            <TextDescribe>
              <div>Forma de pagamento</div>
              <div>{pedidoFormated.formaPagamento}</div>
            </TextDescribe>
            <TextDescribe>
              <div>Status</div>
              <div>{pedidoFormated.status}</div>
            </TextDescribe>
          </div>
          <Addresses>
            <TextDescribe>
              <div>Entrega</div>
              <div>{pedidoFormated.enderecoEntrega}</div>
            </TextDescribe>
            <TextDescribe>
              <div>Faturamento</div>
              <div>{pedidoFormated.enderecoFaturamento}</div>
            </TextDescribe>
          </Addresses>
        </Details>

        <Line />

        <Companies>
          {[pedidoFormated.fornecedor, pedidoFormated.cliente].map((empresa, idx) => (
            <Company>
              <small>{idx === 0 ? 'FORNECEDOR' : 'CLIENTE'}</small>
              <h2
                style={{
                  borderBottom: "1px solid",
                }}
              >
               { empresa.nome }
              </h2>

              <CompanyInfo>
                {empresa?.cnpj ? (
                  <div>
                    <span>CNPJ:</span>
                    {empresa.cnpj}
                  </div>
                ) : null}

                {empresa?.telefone ? (
                  <div>
                    <span>Telefone:</span>
                    {empresa.telefone}
                  </div>
                ) : null}

                {empresa?.whatsapp ? (
                  <div>
                    <span>Whatsapp:</span>
                    {empresa.whatsapp}
                  </div>
                ) : null}

                {empresa?.email ? (
                  <div>
                    <span>E-mail:</span>
                    {empresa.email}
                  </div>
                ) : null}
              </CompanyInfo>
            </Company>
          ))}
        </Companies>

        <h4>Itens do Pedido (Total: {pedidoFormated?.items?.length})</h4>
        <ListProducts>
          {pedidoFormated?.items ? pedidoFormated.items.map((item) => (
            <Product key={item}>
              <h3>{item.nome}</h3>
              <ProductDetails>
                <TextDescribe>
                  <div>Código</div>
                  <div>{item.nome}</div>
                </TextDescribe>
                <TextDescribe>
                  <div>Categoria</div>
                  <div>{item.categoria}</div>
                </TextDescribe>
                <TextDescribe>
                  <div>Marca</div>
                  <div>{item.marca}</div>
                </TextDescribe>
              </ProductDetails>
              {
                item?.variacoes ? (
                  <ProductVariations>
                    VARIACOES
                    <div>
                      {item.variacoes}
                    </div>
                  </ProductVariations>
                ) : null
              }

              <ProductValue>
                <div>
                  Quantidade: {item.quatidade}
                </div>
                <div>
                  <div>Preco: {moneyMask(item.preco, true)}</div>
                  <div>Total: {moneyMask(item.quatidade * item.preco, true)}</div>
                </div>
              </ProductValue>
            </Product>
          )) : null}

        </ListProducts>

        <Line />

        <TotalValue>
          <div>
            <div>
              VALOR DA ENTREGA: <strong>{moneyMask(pedidoFormated.valorEntrega, true)}</strong>
            </div>
            <div>
              VALOR TOTAL DO PEDIDO: <strong>{moneyMask(pedidoFormated.valotTotalPedido, true)}</strong>
            </div>
          </div>
          <div>
            VALOR TOTAL DO PEDIDO + ENTREGA: <br /> <strong>{moneyMask(pedidoFormated.valorEntrega + pedidoFormated.valotTotalPedido, true)}</strong>
          </div>
        </TotalValue>
      </Content>
    </>
  );
}
