import React, { useEffect, useState } from 'react'

import productImageDefault from '../../../components/cotacao/product.svg'
import { URL_TO_IMAGES } from '../../../config/api.config'

export default function ProdutoCotacao({ produto, activeVariation, observacao, type, setProdutoToModal, toggleModal }) {
  const [imageURL, setImageURL] = useState(productImageDefault)

  useEffect(() => {
    const variationProduct = activeVariation && activeVariation?.products_variations.find(prod => prod?.variation_product_id === activeVariation.id)
    const variationImages = activeVariation && activeVariation?.products_images.filter(img => img?.variations_product?.variation_product_id === variationProduct?.variation_product_id)
  
    if (variationImages) {
      if (variationImages.some(i => i.featured_image === 1)) {
        setImageURL(URL_TO_IMAGES + '/' + variationImages.find(i => i.featured_image === 1).path.replace('public', ''))
      } else {
        setImageURL(URL_TO_IMAGES + '/' + variationImages[0].path.replace('public', ''))
      }
      return;
    }

    if (produto.images?.length > 0) {
      const images = produto.images

      if (images.some(i => i.featured_image === 1)) {
        setImageURL(URL_TO_IMAGES + '/' + images.find(i => i.featured_image === 1).path.replace('public', ''))
      } else {
        setImageURL(URL_TO_IMAGES + '/' + images[0].path.replace('public', ''))
      }
    } else {
      setImageURL(productImageDefault)
    }
  }, [produto.images, activeVariation])

  return (
    <div key={produto.id} style={{
      padding: '1rem',
      display: 'flex'
    }}>
      <div style={{
        width: '150px',
        height: '100px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
        <img className="img-fluid" src={imageURL} alt="" />
      </div>
      <div style={{
        padding: '0 15px',
        width: 'fit-content',
        height: '100px',
        overflow: 'hidden'
      }}>
        <h5 className="media-heading">
          {produto.name}
          {
            type === 'list' && (
              <button
                className="btn btn-info ml-2 btn-icon btn-circle btn-sm"
                onClick={() => {
                  setProdutoToModal(produto)
                  return toggleModal ? toggleModal({ ...produto, imageURL }) : null
                }
                }
              >
                <i className="fas fa-info "></i>
              </button>
            )
          }
        </h5>
        <div className="desc" dangerouslySetInnerHTML={{ __html: type === 'list' ? observacao : produto.description }}></div>
      </div>
    </div>
  )
}
