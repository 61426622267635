import api from "../api";
import { API } from '../../config/api.config'

const urlCustomer = `${API}/auth/customer-portifolio`
const urlSupplier = `${API}/auth/portfolio`


export const getPendingRequests = () => api.get(`${API}/auth/pending-requests`)

// {
//     "company_id": 7,
//     "client_id": 9,
//     "where_to_add": "fornecedores"
// }
export const aceiteDoFornecedor = (idRequest) => api.put(`${urlSupplier}/${idRequest}`)
export const recusaDoFornecedor = (idRequest) => api.delete(`${urlCustomer}/${idRequest}`)
export const createClient = (client) => api.post(urlCustomer, client) // fornecedor pedindo para seguir cliente ou fornecedor
export const addTagClient = (values = {}) => api.post(`${urlSupplier}/add-tag`, values)
export const removeTagClient = (idRequest) => api.delete(`${urlSupplier}/remove-tag/${idRequest}`)

export const getCustomers = () => api.get(`${API}/auth/portfolio-customers`) // (fornecedor) retorna todos os Clientes/Fornecedores
export const getSuppliers = () => api.get(`${API}/auth/portfolio-suppliers`) // (cliente) retorna todos os Fornecedores
export const createPortfolio = (data) => api.post(`${API}/auth/portfolio`, data)
export const aceitePortfolio = (idRequest) => api.put(`${API}/auth/portfolio/${idRequest}`)
export const recusaPortfolio = (idRequest) => api.delete(`${API}/auth/portfolio/${idRequest}`)
export const statusPedido = (idEmpresa) => api.get(`${API}/auth/status-request/${idEmpresa}`)

// {
//     "company_id": 1,
//     "supplier_id": 10
// }
export const aceiteDoCliente = (idRequest) => api.put(`${urlCustomer}/${idRequest}`)
export const recusaDoCliente = (idRequest) => api.delete(`${urlSupplier}/${idRequest}`)
export const createCustomer = (suppier) => api.post(urlSupplier, suppier) // cliente pedindo para seguir cliente ou fornecedor

