export const status = {
  created: 'created',
  canceled: 'canceled',
  pending: 'pending',
  approved: 'approved',
  paid: 'paid',
  sent: 'sent',
  received: 'received',
}

export const statusEnumToText = {
  created: 'criado',
  canceled: 'cancelado',
  pending: 'pendente',
  approved: 'aprovado',
  paid: 'pago',
  sent: 'enviado',
  received: 'recebido',
}
