import React from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter } from "reactstrap";
import { status } from "../../../uteis/orders";

export function ModalCancelar({ open, order, setOrder, reason, setReason, cancelar }) {
  return (
    <Modal isOpen={open} toggle={() => setOrder(null)} size="sm" centered>
      <ModalBody>
        Qual o motivo desse cancelamento?
        <Input
          value={reason}
          onChange={(e) => {
            setReason(e.target.value);
          }}
          type="text"
          placeholder="Motivo do cancelamento"
        />
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => setOrder(null)}>
          Fechar
        </Button>
        <Button
          onClick={() => {
            cancelar({
              id: order.id,
              status_description: reason,
              status: status.canceled
            });
            setOrder(null);
          }}
        >
          Confirmar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
