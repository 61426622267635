import { useState, useEffect, useCallback } from "react";
import { addTagClient, removeTagClient } from "../../../services/portfolios";
import {
  createTag,
  getTags as getTagsAPI,
  getTag as getTagAPI,
  deleteTag as deleteTagApi,
  getCustomersByTag as getCustomersByTagAPI,
  updateTag,
} from "../../../services/tags";
import { notification } from "../../../uteis/notifications";

export const initialTagValues = {
  name: "",
  discount: undefined,
};
export function usePrecificacao() {
  const [loading, setLoading] = useState(false);
  const [tag, setTag] = useState(initialTagValues);
  const [tags, setTags] = useState([]);
  const [customersTag, setCustomersTag] = useState([]);
  const [alertData, setAlertData] = useState(null);

  const getTag = useCallback(async (tagId) => {
    const tagApi = await getTagAPI(tagId);
    setTag(tagApi.data.resposta.conteudo.tag);
  }, []);

  const getTags = useCallback(async () => {
    const tagsApi = await getTagsAPI();
    setTags(tagsApi.data.resposta.conteudo.tags.data);
  }, []);

  const getCustomersByTag = useCallback(async (tagId) => {
    const customersTagApi = await getCustomersByTagAPI(tagId);
    setCustomersTag(customersTagApi.data.resposta.conteudo.customers.data);
  }, []);

  const deleteTag = async (tagId, callbackFN = null) => {
    await deleteTagApi(tagId)
      .then((response) => {
        getTags();
        notification(
          "Sucesso!",
          response.data.resposta.conteudo.message,
          "success"
        );
      })
      .catch((error) => {
        if (error && error.response?.data) {
          const messages = error.response.data.resposta.conteudo.messages;
          Object.keys(messages).forEach((key) => {
            messages[key].forEach((message) => {
              notification("Ops!!", message, "danger");
            });
          });
          return;
        }
        notification("Ops!!", "Erro interno no servidor!", "danger");
      })
      .finally(() => {
        if (callbackFN) {
          callbackFN(null);
        }
      });
  };
  const handleSubmit = async (values) => {
    return await (values.id ? updateTag(values) : createTag(values)).then(
      (response) => {
        setTag(initialTagValues);
        getTags();
      }
    );
  };

  const addDiscount = async ({ tag_id, portfolio_id, customer_id }) => {
    return await addTagClient({ tag_id, portfolio_id, customer_id });
  };
  const removeDiscount = async (portfolioId) => {
    return await removeTagClient(portfolioId);
  };

  const handleRemoveDiscount = (tagPortfolioId, callbackFn) => {
    const dataAlert = {
      title: "Tem certeza que deseja Remover o desconto desse cliente?",
      confirmBtnText: "Sim, tenho!",
      confirmBtnBsStyle: "danger",
      onConfirm: async () => {
        await removeDiscount(tagPortfolioId).then((response) => {
          const msg = response.data?.resposta?.conteudo?.message;
          notification("Sucesso", msg, "success");
          callbackFn();
        });
        setAlertData(null);
      },
      onCancel: () => {
        setAlertData(null);
      },
      danger: true,
    };
    setAlertData(dataAlert);
  };

  useEffect(() => {
    getTags();
  }, [getTags]);

  return {
    tag,
    tags,
    customersTag,
    alertData,
    setAlertData,
    setTag,
    setTags,
    getTag,
    getTags,
    setCustomersTag,
    handleSubmit,
    deleteTag,
    addDiscount,
    removeDiscount,
    getCustomersByTag,
    handleRemoveDiscount,
  };
}
