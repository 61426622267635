import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ApproveOrPay } from "../../../components/layout/buttons/ApproveOrPay";
import { Delete } from "../../../components/layout/buttons/Delete";
import { Header, Listagem } from "../../../components/pedidos";
import { usePedido } from "../../../hooks/usePedido";
import { linkImage } from "../../../uteis";
import { mountFullAddress } from "../../../uteis/functions";
import { status as statusEnum } from "../../../uteis/orders";
import { PedidoContainer } from "./PedidoContainer";

const MessageAwaitPayment = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  display: flex;
  align-items: center;
  > span {
    color: black;
    font-size: 0.75rem;
    margin-right: 5px;
  }
`;
const OrderActions = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
`;
const ViewPaymentVoucher = styled.a`
  text-decoration: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border: 1px solid;
  border-radius: 5px;
  margin: 0;
  padding: 0 0.5rem;
  color: ${(props) => props.theme.colors.primary};
  height: 30px;
`;

const DetailsDelivery = styled.div``;

const Fieldset = styled.fieldset`
  border: 1px solid #c5cdd3;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;
const FieldsetLegend = styled.legend`
  width: auto;
  font-size: 0.8rem;
  font-weight: 700;
`;

const getCompany = (pedido, clientes) =>
  clientes
    ? pedido.customer
    : pedido?.items.find((item) => item.company?.company_name).company;

export default function PedidoItem({
  pedido,
  clientes,
  setTitleStatus,
  currentCompanieId,
  setOpenModal,
  setOrder,
}) {
  const history = useHistory();
  const { setPedido } = usePedido();

  const handleImprimir = () => {
    setPedido(pedido);
  
    history.push("/pedidos/imprimir");
  }

  return (
    <PedidoContainer>
      <Header
        company={getCompany(pedido, clientes)}
        numeroPedido={pedido.id}
        data={pedido.created_at}
        enderecoEndrega={mountFullAddress(
          {
            address: pedido.delivery_address.address.address,
            neighborhood: pedido.delivery_address.address.neighborhood,
            complement: pedido.delivery_address.address.complement,
            city: pedido.delivery_address.address.city,
            uf: pedido.delivery_address.address.city.uf_with_country,
            postal_code: pedido.delivery_address.address.postal_code,
          },
          true
        )}
        enderecoFaturamento={mountFullAddress(
          {
            address: pedido.billing_address.address.address,
            neighborhood: pedido.billing_address.address.neighborhood,
            complement: pedido.billing_address.address.complement,
            city: pedido.billing_address.address.city,
            uf: pedido.billing_address.address.city.uf_with_country,
            postal_code: pedido.billing_address.address.postal_code,
          },
          true
        )}
        formaPagamento={pedido.payment.name}
        status={
          <MessageAwaitPayment>
            <span>Status: </span>{" "}
            {setTitleStatus({
              pedido,
              status: pedido.current_status.name,
              currentCompanieId,
            })}
          </MessageAwaitPayment>
        }
        totalValue={pedido.total_value}
        deliveryValue={pedido.delivery_address.value}
      >
        {pedido.delivery_details ? (
          <Fieldset>
            <FieldsetLegend>DETALHES DA ENTREGA</FieldsetLegend>
            <DetailsDelivery
              dangerouslySetInnerHTML={{
                __html: pedido.delivery_details.replace(
                  /(?:\r\n|\r|\n)/g,
                  "<br />"
                ),
              }}
            />
          </Fieldset>
        ) : null}

        <OrderActions>
          <ApproveOrPay
            type="button"
            style={{
              width: "auto",
            }}
            onClick={handleImprimir}
            isPay
          >
            Imprimir
          </ApproveOrPay>
  
          {!pedido.is_delivered ? (
            <>
              {!pedido?.payment_company_method?.payment_voucher ? (
                <Delete
                  id={`delete-${pedido.id}`}
                  title="Cancelar pedido"
                  type="button"
                  onClick={() => {
                    setOpenModal("cancelar");
                    setOrder(pedido);
                  }}
                  style={{
                    width: "auto",
                  }}
                >
                  Cancelar
                </Delete>
              ) : null}

              {pedido.supplier_id === currentCompanieId &&
              pedido.current_status.name === statusEnum.created ? (
                <ApproveOrPay
                  type="button"
                  style={{
                    width: "auto",
                  }}
                  onClick={() => {
                    const newPedido = JSON.stringify(pedido);
                    setOrder(JSON.parse(newPedido));
                    setOpenModal("aceitar");
                  }}
                >
                  Aprovar
                </ApproveOrPay>
              ) : null}

              {pedido.current_status.name === statusEnum.approved &&
              pedido.customer_id === currentCompanieId &&
              !pedido?.payment_company_method?.payment_voucher ? (
                <ApproveOrPay
                  type="button"
                  style={{
                    width: "auto",
                  }}
                  onClick={() => {
                    const newPedido = JSON.stringify(pedido);
                    setOrder(JSON.parse(newPedido));
                    setOpenModal("aceitar");
                  }}
                >
                  Pagar
                </ApproveOrPay>
              ) : null}

              {pedido.supplier_id === currentCompanieId &&
              pedido.payment_company_method?.payment_voucher &&
              pedido.current_status.name === statusEnum.approved ? (
                <ApproveOrPay
                  type="button"
                  style={{
                    width: "auto",
                  }}
                  onClick={() => {
                    setOpenModal("confirmar-pamento");
                    setOrder(pedido);
                  }}
                >
                  Confirmar pagamento
                </ApproveOrPay>
              ) : null}

              {pedido.supplier_id === currentCompanieId &&
              pedido.current_status.name === statusEnum.paid ? (
                <ApproveOrPay
                  type="button"
                  style={{
                    width: "auto",
                  }}
                  onClick={() => {
                    setOpenModal("confirmar-envio");
                    setOrder(pedido);
                  }}
                >
                  Confirmar envio
                </ApproveOrPay>
              ) : null}

              {!pedido.delivery_address.isDelivered &&
              pedido.delivery_address.details &&
              pedido.customer_id === currentCompanieId ? (
                <ApproveOrPay
                  type="button"
                  s
                  style={{
                    width: "auto",
                  }}
                  onClick={() => {
                    setOpenModal("confirmar-recebimento");
                    setOrder(pedido);
                  }}
                >
                  Confirmar recebimento
                </ApproveOrPay>
              ) : null}
            </>
          ) : null}

          {pedido.payment_company_method?.payment_voucher ? (
            <>
              <ViewPaymentVoucher
                href={linkImage(pedido.payment_company_method?.payment_voucher)}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa fa-eye t-plus-1 fa-fw fa-lg"></i>
                Ver comprovante
              </ViewPaymentVoucher>
            </>
          ) : null}
        </OrderActions>
      </Header>
      <Listagem
        listagemItemsCompany={pedido.items}
        // updateCarrinhoQuantidade={updateCarrinhoQuantidade}
        // remover={cancelar}
        isOrder
      />
    </PedidoContainer>
  );
}
