import { useCallback, useEffect, useState } from "react";
import { addPaymentVoucher, alterOrder, getOrders } from "../../../services/order";
import { notification } from "../../../uteis/notifications";

export function usePedidos({ type, getCustomer, ...rest }) {
  const [loading, setLoading] = useState(true);

  const [pedidos, setPedidos] = useState([]);

  const pegaPedidos = useCallback(async () => {
    setLoading(true);
    await getOrders({...rest, status: type}, getCustomer)
      .then((response) => {
        const rawPedido = response.data.resposta.conteudo.orders.data;

        const newPedidos = rawPedido.map((ped) => {
          const delivery = {
            address: ped.delivery_address.delivery,
            details: ped.delivery_address.delivery_details,
            value: ped.delivery_address.delivery_value,
            isDelivered: ped.delivery_address.is_delivered
          }
          const billing = {
            address: ped.billing_address.billing,
            details: ped.billing_address.delivery_details,
            value: ped.billing_address.delivery_value,
            isDelivered: ped.billing_address.is_delivered
          }
          const payment = {
            // payment: ped.payment_company_method.payment,
            name: ped.payment_company_method.payment.payment_method.name,
            fields: JSON.parse(ped.payment_company_method.payment.payment_method.fields),
            values: JSON.parse(ped.payment_company_method.payment_info),
          }
          return {
            ...ped,
            items: JSON.parse(ped.items),
            delivery_address: delivery,
            billing_address: billing,
            payment
          }
        });

        setPedidos(newPedidos);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  const alterarStatus = async (item) => {
    setLoading(true);

    await alterOrder(item.id, item)
      .then(async (response) => {
        await pegaPedidos();
        const pedido = response.data.resposta.conteudo;        
        notification("Sucesso", pedido.message, "success");
      })
      .catch((error) => notification("Erro", "Não foi possível cancelar esse pedido.", "danger"))
      .finally(() => setLoading(false));
  };

  const adicionarComprovante = async (item) => {
    setLoading(true);

    const file = item.payment_voucher[0];
    const newFile = new File([file], file.name, { type: file.name.type, lastModified: Date.now() });
  
    const comprovanteFormdata = new FormData();
    comprovanteFormdata.append('payment_voucher', newFile)
    comprovanteFormdata.append('id', item.id)

    await addPaymentVoucher(comprovanteFormdata)
      .then(async (response) => {
        await pegaPedidos();
        const pedido = response.data.resposta.conteudo;        
        notification("Sucesso", pedido.message, "success");
      })
      .catch((error) => notification("Erro", "Não foi possível cancelar esse pedido.", "danger"))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    pegaPedidos();
  }, [pegaPedidos]);

  return {
    loading, pedidos, alterarStatus, adicionarComprovante
  }
}
