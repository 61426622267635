import React, { useContext, useEffect } from "react";
// import ItemProcurar from "../../components/itemProcurar";
import ItemProcurar from "../../components/carteiras/cliente";
import Loader from "../../components/loader";
import { PageSettings } from "../../config/page-settings";

const MinhaCarteira = ({
  data,
  getData,
  loading,
  naoSeguir
}) => {
  const contextType = useContext(PageSettings);
  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Loader loading={loading} />
      <div className="row">
        <div className="col-md-12">
          <div className="result-container">
            <ul className="result-list">
              {
                !data || data.length === 0 ? (
                  <h4 className="mt-3">Nenhum Fornecedor</h4>
                ) : data.map(fornecedor => (
                  <ItemProcurar
                    key={fornecedor.id}
                    type={'fornecedor'}
                    item={fornecedor}
                    companyId={fornecedor.company_id}
                    naoSeguir={naoSeguir}
                    getItems={getData}
                  />
                ))
              }
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinhaCarteira;
