import styled from "styled-components";
import { Base } from "./Base";

export const ApproveOrPay = styled(Base)`
  background: ${(props) => props.isPay ? props.theme.colors.primary : props.theme.colors.success};
  height: 30px;
  &:hover {
    opacity: .9;
  }
`;
