import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Button, CardBody, Col, Collapse, Row } from "reactstrap";
import styled from "styled-components";
import { DeleteTag } from "../../components/carteiras/cliente";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import ModalTagForm from "./form";
import { usePrecificacao } from "./hooks/usePrecificacao";

const ListTags = styled.ul`
  width: 100%;
  & li {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 5px;
    margin-bottom: 0.5rem;
  }
`;
const ContentListTag = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 200px;
  flex: 1;
`;

const BadgeTag = styled.span`
  border-radius: 0;
  font-size: 0.9rem;
  width: 75px;
  padding: 10px;
  border-radius: 3px;
  background: #0a243c;
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
  min-width: 250px;
`;

const ButtonClients = styled.button`
  border: 1px solid ${(props) => props.theme.colors.blue5};
  &:hover {
    background-color: ${(props) => props.theme.colors.gray1};
  }
`;

const CustomerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: .8rem;
  border-bottom: 1px solid ${(props) => props.theme.colors.blue5};;
  margin-bottom: 1rem;
  position: relative;
`;

const VerticalLine = styled.div`
  width: 1px;
  background: ${(props) => props.theme.colors.gray3};
  height: 25px;
`;

export default function Precificacao() {
  const [deletar, setDeletar] = useState(null);
  const [modalFormTag, setModalFormTag] = useState(false);
  const {
    tags,
    tag,
    customersTag,
    alertData,
    setTag,
    setTags,
    getTag,
    getTags,
    handleSubmit,
    deleteTag,
    getCustomersByTag,
    handleRemoveDiscount
  } = usePrecificacao();

  const contextType = useContext(PageSettings);

  const toggleModalFiltro = () => setModalFormTag(!modalFormTag);

  const confirmar = async () => await deleteTag(deletar, setDeletar);

  const handleOpenTag = async (tagId) => {
    await getCustomersByTag(tagId);
    const newTags = tags.map(tag => {
      const originalOpen = tag.open;
      if (tag.open === true) {
        tag.open = false;
      }
      if (tag.id === tagId) {
        tag.open = !originalOpen;
      }

      return tag;
    })
    setTags(newTags);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={() => setDeletar(null)}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}

      {alertData && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText={alertData.confirmBtnText}
          confirmBtnBsStyle={alertData.confirmBtnBsStyle ?? "danger"}
          cancelBtnBsStyle={alertData.cancelBtnBsStyle ?? "default"}
          title={alertData.title}
          onConfirm={alertData.onConfirm}
          onCancel={alertData.onCancel}
          {...alertData}
        >
          {alertData.content}
        </SweetAlert>
      )}

      <TitlePage
        title="Precificação"
        desc="Aqui você gerencia seus descontos."
        icon="fas fa-tags"
      />
      <div className="row">
        <div className="col-md-12">
          <Row className="justify-content-end mt-3">
            {modalFormTag && (
              <ModalTagForm
                modal={modalFormTag}
                toggle={toggleModalFiltro}
                tag={tag}
                setTag={setTag}
                handleSubmit={handleSubmit}
              />
            )}
            <Col md="4" lg="4" xl="2">
              <button
                className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
                onClick={() => toggleModalFiltro(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>
        </div>
        <ListTags className="list-group">
          {tags.length ? (
            tags.map((tag) => (
              <>
                <li key={tag.id} className="list-group-item">
                  <ContentListTag>
                    <BadgeTag className="mr-2 badge badge-pill">
                      {tag.discount}%
                    </BadgeTag>
                    <strong>{tag.name}</strong>
                  </ContentListTag>
                  <Actions>
                    <ButtonClients
                      title="Clientes"
                      className="btn"
                      onClick={() => {
                        handleOpenTag(tag.id);
                      }}
                    >
                      {tag.customers_count} Cliente(s)
                    </ButtonClients>

                    <VerticalLine className="mr-2 ml-2" />

                    <Button
                      title="Editar"
                      className="btn btn-success btn-icon btn-circle btn-lg m-3"
                      onClick={async () => {
                        await getTag(tag.id);
                        toggleModalFiltro();
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </Button>
                    <Button
                      title="Excluir"
                      className="btn btn-danger btn-icon btn-circle btn-lg m-3"
                      onClick={() => setDeletar(tag.id)}
                    >
                      <i className="fa fa-times"></i>
                    </Button>
                  </Actions>
                </li>
                <Collapse
                  isOpen={tag.open}
                  className="mb-2"
                  style={{
                    background: "white",
                    marginTop: "-14px",
                  }}
                >
                  <CardBody>
                    <div>
                      {customersTag.length &&
                      customersTag.some(
                        (customer) => customer.tag_id === tag.id
                      ) ? (
                        customersTag.map((customer) => (
                          <CustomerContainer>
                            <Link to={`/empresa/${customer.company.id}`}>
                              <i className="far fa-eye"></i>{" "}
                              {customer.company.company_name}
                            </Link>
                            <DeleteTag
                              {...{
                                handleRemoveDiscount,
                                tagPortfolioId: customer.id,
                                getItems: () => getTags(),
                                hiddenTagName: true,
                              }}
                            />
                          </CustomerContainer>
                        ))
                      ) : (
                        <CustomerContainer>
                          Sem Clientes com essa tag
                        </CustomerContainer>
                      )}
                    </div>
                  </CardBody>
                </Collapse>
              </>
            ))
          ) : (
            <h3>Nenhum desconto criado!</h3>
          )}
        </ListTags>
      </div>
    </>
  );
}
