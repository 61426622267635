import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";
// import ItemProcurar from "../../components/itemProcurar";
import ItemProcurar from "../../components/carteiras/cliente";
import Loader from "../../components/loader";
import { usePrecificacao } from "../precificacao/hooks/usePrecificacao";

const MinhaCarteira = ({
  data,
  getData,
  loading,
  setLoading,
  naoSeguir
}) => {
  const {
    tags,
    alertData,
    setAlertData,
    addDiscount,
    handleRemoveDiscount,
  } = usePrecificacao();

  return (
    <>
      <Loader loading={loading} />
      {alertData && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText={alertData.confirmBtnText}
          confirmBtnBsStyle={alertData.confirmBtnBsStyle ?? "danger"}
          cancelBtnBsStyle={alertData.cancelBtnBsStyle ?? "default"}
          title={alertData.title}
          onConfirm={alertData.onConfirm}
          onCancel={alertData.onCancel}
          {...alertData}
        >
          {alertData.content}
        </SweetAlert>
      )}
      <div className="row">
        <div className="col-md-12">
          <div className="result-container">
            <ul className="result-list">
              {!data || data?.length === 0 ? (
                <h4 className="mt-3">Nenhum Cliente</h4>
              ) : (
                data.map((cliente) => (
                  <ItemProcurar
                    key={cliente.id}
                    type={"comprador"}
                    item={cliente}                    
                    companyId={cliente.company_id}
                    naoSeguir={naoSeguir}
                    getItems={getData}
                    tagPortfolioId={cliente.tag_portfolio_id}
                    tagName={cliente.tag_name}
                    discount={cliente.discount}
                    addDiscount={addDiscount}
                    tags={tags}
                    setAlertData={setAlertData}
                    loadData={getData}
                    handleRemoveDiscount={handleRemoveDiscount}
                    setLoading={setLoading}
                  />
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MinhaCarteira;
