import React, { useContext, useEffect, useState } from "react";
import { Col, FormGroup, Input, Row } from "reactstrap";
import Produto from "../../../components/cotacao/produto";
import Topo from "../../../components/cotacao/topo";
import Loader from "../../../components/loader";
import { PageSettings } from "../../../config/page-settings";
import ModalProduto from "../parts/modalProduto";

import Frete from "../../..//components/cotacao/frete";
import AreaDePreco from "../../../components/cotacao/areaDePreco";
import TitlePage from "../../../components/pages/title";
import CotarModal from "../cotarModal";
const Cotar = ( ) => {
  const [loading, setLoading] = useState(true);
  const [modal, setModal] =  useState(false);
  const [modalProduto, setModalProduto] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipOpenFOB, setTooltipOpenFOB] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const toggleFOB = () => setTooltipOpenFOB(!tooltipOpenFOB);
  const contextType = useContext(PageSettings);
  const toggleModal = () =>{
      console.log("sd")
      setModal(!modal)
  }
  const toggleModalProduto = () => setModalProduto(!modalProduto)
  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
	  < ModalProduto modal={modalProduto}  toggle={toggleModalProduto}/>
      <Loader loading={loading} />
      <TitlePage title="Minhas cotações" desc="Cotação respondida." icon="fal fa-box" />
      <CotarModal toggle={toggleModal} setLoading={setLoading} modal={modal}/>
      <div className="invoice p-20">
		<Topo respondida={true} />
		<Produto disabled={true} toggleModal={toggleModalProduto}/>
		<Produto disabled={true}  toggleModal={toggleModalProduto}/>
		<div className="invoice-price mt-4">
			<AreaDePreco disabled />
		</div>
		<div className="invoice-price mt-1">
			<Frete disabled toggle={toggle} toggleFOB={toggleFOB} tooltipOpen={tooltipOpen} tooltipOpenFOB={tooltipOpenFOB} />
		</div>
		<Row>
			<Col xs={12} className="mt-4">
				<div className="sub-price">
					<FormGroup>
					<small>Forma de pagamento </small>
					<Input disabled type="textarea" name="email" value="Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat. Praesent mattis interdum arcu eu feugiat."  placeholder="Forma de pagamento" />
					</FormGroup>
				</div>
			</Col>

			<Col xs={12} className="mt-1">
				<div className="sub-price">
					<FormGroup>
					<	small>prazo de entrega </small>
					<Input disabled value="Lorem ipsum dolor sit amet, consectetuer adipiscing elit volutpat. Praesent mattis interdum arcu eu feugiat." type="textarea" name="email"  placeholder="Forma de pagamento" />
					</FormGroup>
				</div>
			</Col>
		</Row>
		<div className="invoice-price mt-4">
			<button disabled className="btn btn-block btn-lg btn-success">
			Respondida 
			</button>
		</div>

		<Row>
			<Col>
				<h5> Válidade até: 22/02/2222 </h5>
			</Col>
		</Row>
		<div className="invoice-footer">
			<p className="text-center m-b-5 f-w-600">
				Obrigado pela sua solicitação
			</p>
		</div>
		<hr />
		{/* <Conversas /> */}
	</div>
				
    </>
  );
};

export default Cotar;
