import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import { recusaPortfolio } from "../../services/portfolios";

import { notification } from "../../uteis/notifications";
import Cliente from './cliente';
import Fornecedor from './fornecedor';
import { useWallet } from "./hooks/useWallet";
import Solicitacoes from './solicitacoes';

const MinhaCarteiraHome = () => {
  const contextType = useContext(PageSettings);
  const user = useSelector(state => state.auth.user);
  const [activeTab, setActiveTab] = useState("1");
  const [typeUser, setTypeUser] = useState(2);

  const {
    wallet, 
    loading,
    setLoading,
    setHasChange,
    getClientes,
    getFornecedores,
    getSolicitacoes,
  } = useWallet({
    isSupplier: typeUser === 1
  });

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const naoSeguir = async (id) => {
    await recusaPortfolio(id)
      .then(async response => {
        notification("Sucesso!", response.data?.resposta?.conteudo?.message, "success")
        setHasChange(true);
      })
      .catch(error => {
        if (error && error.response?.data?.resposta?.conteudo?.messages) {
          const messages = error.response.data.resposta.conteudo.messages
          Object.keys(messages).forEach(key => {
            messages[key].forEach(message => {
              notification("Ops!!", message, "danger")
            })
          })
          return
        }

        notification("Ops!!", "Erro interno no servidor!", "danger")
      })
  }  

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()

    setTypeUser(parseInt(user.companies[0].profile))
    setActiveTab(parseInt(user.companies[0].profile) === 1 ? "1" : "2")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TitlePage title="Minha carteira" icon="fa fa-wallet" />
      <div className="row">
        <div className="col-12 pt-3 pb-3">
          <Nav tabs className="nav-pills">
            {
              typeUser === 1 && (
                <NavItem className="m-3">
                  <NavLink
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Carteira de clientes
                  </NavLink>
                </NavItem>
              )
            }
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  toggle("2");
                }}
              >
                Carteira de fornecedor
              </NavLink>
            </NavItem>
            <NavItem className="m-3">
              <NavLink
                className={classnames({ active: activeTab === "3" })}
                onClick={() => {
                  toggle("3");
                }}
              >
                Solicitações pendentes
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      </div>

      <TabContent activeTab={activeTab} style={{ marginBottom: 30 }}>
        {
          typeUser === 1 && (
            <TabPane tabId="1">
              <Cliente
                data={wallet.clients}
                getData={getClientes}
                loading={loading}
                setLoading={setLoading}
                setHasChange={setHasChange}
                naoSeguir={naoSeguir}
              />
            </TabPane>
          )
        }
        <TabPane tabId="2">
          <Fornecedor
            data={wallet.suppliers}
            getData={getFornecedores}
            loading={loading}
            setLoading={setLoading}
            setHasChange={setHasChange}
            naoSeguir={naoSeguir}
          />
        </TabPane>
        <TabPane tabId="3">
          <Solicitacoes
            data={wallet.pendingRequests}
            getData={getSolicitacoes}
            loading={loading}
            setLoading={setLoading}
            setHasChange={setHasChange}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default MinhaCarteiraHome;
