import api from "../api";
import { API } from '../../config/api.config'


export const getTag = (tagId) => api.get(`${API}/auth/tags/${tagId}`);
export const getTags = (params) => api.get(`${API}/auth/tags`, {
    params
});
export const createTag = (values) => api.post(`${API}/auth/tags`, values);
export const updateTag = (values) => api.put(`${API}/auth/tags/${values.id}`, values);
export const deleteTag = (tagId) => api.delete(`${API}/auth/tags/${tagId}`);
export const getCustomersByTag = (tagId) => api.get(`${API}/auth/tags/${tagId}/customers`);
