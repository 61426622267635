import React, { useMemo, useState } from "react";
import { Button, FormGroup, Input, Label } from "reactstrap";
import styled from "styled-components";
import { Listagem } from "../../../components/pedidos";
import { TagDanger } from "../../../components/tag";
import { useCarrinho } from "../../../hooks/useCarrinho";
import { createOrder } from "../../../services/order";
import { mountFullAddress } from "../../../uteis/functions";
import { notification } from "../../../uteis/notifications";
import { status as statusEnum } from "../../../uteis/orders";
import { PedidoContainer } from "../../pedidos/components/PedidoContainer";
import { useFormasPagamento } from "../../pedidos/hooks/useFormasPagamento";

const CarrinhoCompanyFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
`;

const AddressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  > div {
    flex: 1;
    margin: 0;
    min-width: 350px;

    @media (max-width: 768px) {
      min-width: 300px;
    }
  }
`;

const CompoObrigatorio = styled.span`
  color: ${(props) => props.theme.colors.danger};
  font-weight: 600;
`

export function CarrinhoCompany({ companyId, carrinho, enderecos, defaultAddress }) {
  const [disableButtonFazerPedido, setDisableButtonFazerPedido] = useState();
  const {
    updateCarrinhoQuantidade,
    removeItemCompanyCarrinho,
    updateCarrinhoEndereco,
    removeCompanyCarrinho,
  } = useCarrinho();

  const carrinhoCompany = useMemo(() => {
    return {
      ...carrinho,
      enderecoFaturamentoId:  defaultAddress?.id,
      enderecoEntregaId: defaultAddress?.id
    }
  }, [carrinho, defaultAddress])

  const {
    formasPagamentoEmpresa,
  } = useFormasPagamento({ companyId });

  const enderecosFormated = enderecos.map((endereco) => ({
    ...endereco,
    fullAddress: mountFullAddress(
      {
        address: endereco.address,
        neighborhood: endereco.neighborhood,
        complement: endereco.complement,
        city: endereco.city,
        uf: endereco.city.uf_with_country,
        postal_code: endereco.postal_code,
      },
      true
    ),
  }));
  const getFullAddress = (id) =>
    enderecosFormated.find((end) => end.id === Number(id))?.fullAddress;

  const handleFazerPedido = async (item) => {
    await createOrder({
      supplier_id: item.companyId,
      items: item.items,
      delivery_address_id: item.enderecoEntregaId,
      billing_address_id: item.enderecoFaturamentoId,
      payment_company_method_id: item.formaPagamentoId,
      status: statusEnum.created,
    })
      .then(() => {
        removeCompanyCarrinho(companyId);
        notification("Sucesso", "Pedido efetuado com sucesso!", "success");
      })
      .catch((error) => {
        setDisableButtonFazerPedido(false);
        notification("Erro", "Algo deu errado.", "danger");
      });
  };

  return (
    <PedidoContainer>
      <h3>
        {
          carrinhoCompany?.items.find((item) => item.company?.company_name)
            .company.company_name
        }
      </h3>
      {
        !formasPagamentoEmpresa.length ?
          <TagDanger>Essa empresa não tem nenhuma forma de pagamento cadastrada ou ativa.</TagDanger>
        : null
      }
      <AddressWrapper>
        <FormGroup>
          <Label for="endereco-faturamento">Endereço de faturamento</Label>
          <Input
            id="endereco-faturamento"
            type="select"
            name="endereco-faturamento"
            placeholder="Endereço de faturamento"
            value={carrinhoCompany.enderecoFaturamentoId}
            onChange={(event) => {
              updateCarrinhoEndereco({
                ...carrinhoCompany,
                enderecoFaturamentoId: event.target.value,
                enderecoFaturamentoCompleto: getFullAddress(event.target.value),
              });
            }}
            required
            disabled={disableButtonFazerPedido}
          >
            <option value={0}>Selecione o endereço...</option>
            {enderecosFormated.map((endereco) => (
              <option key={endereco.id} value={endereco.id}>
                {endereco.fullAddress}
              </option>
            ))}
          </Input>
          {
            [carrinhoCompany.enderecoFaturamentoId].some(i => !i || i === '0') ?
              <CompoObrigatorio>Campo obrigatório</CompoObrigatorio>
            : null
          }
        </FormGroup>
        <FormGroup>
          <Label for="endereco-entrega">Endereço de entrega</Label>
          <Input
            id="endereco-entrega"
            type="select"
            name="endereco-entrega"
            placeholder="Endereço de entrega"
            value={carrinhoCompany.enderecoEntregaId}
            onChange={(event) => {
              updateCarrinhoEndereco({
                ...carrinhoCompany,
                enderecoEntregaId: event.target.value,
                enderecoEntregaCompleto: getFullAddress(event.target.value),
              });
            }}
            required
            disabled={disableButtonFazerPedido}
          >
            <option value={0}>Selecione o endereço...</option>
            {enderecosFormated.map((endereco) => (
              <option key={endereco.id} value={endereco.id}>
                {endereco.fullAddress}
              </option>
            ))}
          </Input>
          {
            [carrinhoCompany.enderecoEntregaId].some(i => !i || i === '0') ?
              <CompoObrigatorio>Campo obrigatório</CompoObrigatorio>
            : null
          } 
        </FormGroup>

        {formasPagamentoEmpresa.length ? (
          <FormGroup>
            <Label for="forma-pagamento">Forma de Pagamento</Label>
            <Input
              id="forma-pagamento"
              type="select"
              name="forma-pagamento"
              placeholder="Forma de Pagamento"
              value={carrinhoCompany.formaPagamentoId}
              onChange={(event) => {
                updateCarrinhoEndereco({
                  ...carrinhoCompany,
                  formaPagamentoId: event.target.value,
                });
              }}
              required
              disabled={disableButtonFazerPedido}
            >
              <option value={0}>Selecione a forma de pagamento...</option>
              {formasPagamentoEmpresa.map((forma) => {
                return (
                  <option key={forma.id} value={forma.id}>
                    {forma.payment_method.name}
                  </option>
                );
              })}
            </Input>
          {
            [carrinhoCompany.formaPagamentoId].some(i => !i || i === '0') ?
              <CompoObrigatorio>Campo obrigatório</CompoObrigatorio>
            : null
          }
          </FormGroup>
        ) : null}
      </AddressWrapper>

      <Listagem
        listagemItemsCompany={carrinhoCompany.items}
        alteraQuantidade={updateCarrinhoQuantidade}
        remover={removeItemCompanyCarrinho}
        disabled={disableButtonFazerPedido}
      />

      <CarrinhoCompanyFooter>
        <Button
          onClick={() => {
            setDisableButtonFazerPedido(true);
            handleFazerPedido(carrinhoCompany);
          }} 
          disabled={
            disableButtonFazerPedido ||
            [
              carrinhoCompany.enderecoEntregaId,
              carrinhoCompany.enderecoFaturamentoId,
              carrinhoCompany.formaPagamentoId
            ].some(i => !i || i === '0')
          }
        >
          {disableButtonFazerPedido ? "Criando pedido..." : "Fazer pedido"}
          
        </Button>
      </CarrinhoCompanyFooter>
    </PedidoContainer>
  );
}
