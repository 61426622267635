import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { status as statusEnum } from "../../../uteis/orders";

const Declaration = styled.div`
  font-size: 1rem;
  line-height: normal;
  > span {
    font-weight: 600;
  }
`;
const DetailsDeliveryField = styled.div`
  margin-top: 1rem;
`

export function ModalConfirmarEnvio({
  open,
  order,
  setOrder,
  confirmarEnvio,
  deliveryDetails,
  setDeliveryDetails
}) {
  const {id, customer: { company_name }} = order;
  return (
    <Modal isOpen={open} toggle={() => setOrder(null)} size="md" centered>
      <ModalBody>
        <Declaration>
          Detalhes da entrega referente ao pedido <span>nº {id}</span> da
          empresa <span>{company_name}</span>.
        </Declaration>

        <p style={{lineHeight: "normal", marginTop: "0.5rem"}}>
          Forneça os dados da empresa que irá fazer a entrega, bem como dados de rastreio e demais informações importantes para a realização dessa entrega.
        </p>

        <DetailsDeliveryField>
          <strong>Detalhes do envio da entrega</strong>
          <textarea
            name="detalhes-entrega"
            type="textarea"
            placeholder="Detalhes do envio da entrega"
            value={deliveryDetails}
            onChange={(e) => setDeliveryDetails(e.target.value)}
            style={{ width: "100%" }}
          />
        </DetailsDeliveryField>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => setOrder(null)}>
          Fechar
        </Button>
        <Button
          onClick={() => {
            confirmarEnvio({
              id: order.id,
              status: statusEnum.sent,
              delivery_details: deliveryDetails
            });
            setOrder(null);
          }}
        >
          Confirmar de envio
        </Button>
      </ModalFooter>
    </Modal>
  );
}
