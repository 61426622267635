import React from "react";
import { Link } from "react-router-dom";
import { URL_TO_IMAGES } from "../../config/api.config";

const ItemProcurar = ({ type, item, aceitar, setIsRecusar }) => {
  return (
    <li>
      <Link
        to={`/empresa/${item.company_id}`}
        className="result-image result-image-empresa"
      >
        <img className="rounded-corner" src={item?.company_logo ? URL_TO_IMAGES + '/' + item?.company_logo.replace('public', '') : '/assets/img/gallery/gallery-52.jpg'} alt="Imagem do Logotipo" />
      </Link>
      <div className="result-info">
        <h4 className="title">
          <Link to={`/empresa/${item.company_id}`}>
            {item?.company_name}
          </Link>
        </h4>
        <div>
          Quer adicionar você à sua carteira.
          {/* quer te adicionar como 
          {
            type === "ambos" ? (
              <>
                <Badge className="ml-1 mr-1"> fornecedor</Badge>
                e
                <Badge className="ml-1"> cliente</Badge>
              </>
            ) : (
              <Badge className="ml-2">{type === "fornecedor" ? "fornecedor" : "cliente"}</Badge>
            )
          } */}
        </div>

        <div className="btn-row">
          <button
            className="btn btn-success btn-circle mt-3"
            onClick={() => aceitar(item.id, item.profile === "1" ? "fornecedor" : "cliente")}
          >
            Aceitar
          </button>
          <button
            className="ml-1 btn btn-danger btn-circle mt-3"
            onClick={() => setIsRecusar({ idRequest: item.id, type: item.profile === "1" ? "fornecedor" : "cliente" })}
          >
            Recusar
          </button>
        </div>
      </div>
      <div className="result-price">
        <Link to={`/empresa/${item.company_id}`} className="btn btn-yellow btn-block">
          Ver perfil
        </Link>
      </div>
    </li>
  );
};

export default ItemProcurar;
