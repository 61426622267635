import { store } from "react-notifications-component";
import { toast } from "react-toastify";

const notification = (title, msg, type, duration = 5000) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration,
      onScreen: true,
      pauseOnHover: true
    }
  });
}

const notificationToast = (message, type, duration = 5000) => toast[type](
  message,
  {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: true,
    duration
  }
)

export {
  notification,
  notificationToast
};
