import React from "react";
import Produto from "./../pages/catalogoDeProdutos";
import Servicos from "./../pages/catalogoServicos";
import CadastrarServico from "./../pages/catalogoServicos/cadastrar";
import EditarServico from "./../pages/catalogoServicos/editar";
import Config from "./../pages/configuracao";
import Home from "./../pages/home/index";
import CadastroEmpresa from "./../pages/perfilEmpresa";
import ProcuraAvancada from "./../pages/procurarAvancada";
import ProcurarFornecedor from "./../pages/procurarFornecedor";
import TrocarSenha from "./../pages/trocarSenha";

import CadastrarProduto from "./../pages/catalogoDeProdutos/cadastrar";
import CopiarProduto from "./../pages/catalogoDeProdutos/copiar";
import EditarProduto from "./../pages/catalogoDeProdutos/editar";
import LogIn from "./../pages/login/Index";
import ProcurarCliente from "./../pages/procurarFornecedor/cliente";
import ProcurarProduto from "./../pages/procurarFornecedor/produto";
import ProcurarServico from "./../pages/procurarFornecedor/servico";
import ProdutoSingle from "./../pages/produtoFinal";

import CopiarServico from "./../pages/catalogoServicos/copiar";

import ConversaCliente from "./../pages/avaliacao/AvaliacaoCliente";
import AvaliacaoCliente from "./../pages/avaliacao/cliente";
import MinhaAvaliacao from "./../pages/avaliacao/minhaAvaliacao";
import ConversaMinhaAvaliacao from "./../pages/avaliacao/minhaAvaliacaoConversa";
import VisualizarEmpresa from "./../pages/empresa";

import RegiaoDeAtendimento from "../pages/regiaoDeAtendimento";

import MinhaCarteira from "../pages/minhaCarteira";

import Cotar from "../pages/cotacao/cotar";
// minhas cotacoes
import CotacaoMinhaListaPendentes from "../pages/cotacao/minhasCotacoes/pendente";
import CotacaoMinhaListaRespondida from "../pages/cotacao/minhasCotacoes/respondida";
// visualizacao cotacao
import VisualizacaoCotarPendente from "../pages/cotacao/minhasCotacoes/aguardandoResposta";
import VisualizacaoCotarResposta from "../pages/cotacao/minhasCotacoes/cotarResposta";

// cotacoes clientes
import CotacaoClienteistaPendentes from "../pages/cotacao/clientes/pendente";
import CotacaoClienteistaRespondida from "../pages/cotacao/clientes/respondida";

import Assinatura from "../pages/assinatura";
import Cadastrar from "../pages/cadastro";
import ResponderCotacao from "../pages/cotacao/clientes/responder";
import ResponderCotacaoRespondida from "../pages/cotacao/clientes/responderRespondida";
import Redefinir from "../pages/login/redefinirSenha";

import { Carrinho } from "../pages/carrinho";
import { Enderecos } from "../pages/enderecos";
import { Cancelados, Configuracao, CriadosAprovados } from "../pages/pedidos";
import { PrintOrder } from "../pages/pedidos/PrintOrder";
import Precificacao from "../pages/precificacao";
import Variacao from "../pages/variacao";
import VariacaoProduto from "../pages/variacaoProduto";
import VariacaoServico from "../pages/variacaoServico";
import { status } from "../uteis/orders";

const routes = [
  {
    path: "/",
    title: "Página Inicial",
    exact: true,
    component: () => <Home />,
  },
  {
    path: "/trocar-senha",
    title: "Trocar senha",
    exact: true,
    component: () => <TrocarSenha />,
  },
  {
    path: "/cadastroEmpresa",
    title: "Página Inicial",
    exact: true,
    component: () => <CadastroEmpresa />,
  },
  {
    path: "/configuracoes",
    title: "Configuraçoes",
    exact: true,
    component: () => <Config />,
  },

  {
    path: "/procurar/fornecedor",
    title: "Procurar Fornecedor",
    exact: true,
    component: () => <ProcurarFornecedor />,
  },
  {
    path: "/procurar/cliente",
    title: "Procurar cliente",
    exact: true,
    component: () => <ProcurarCliente />,
  },
  {
    path: "/procurar/produto",
    title: "Procurar produto",
    exact: true,
    component: () => <ProcurarProduto />,
  },
  {
    path: "/procurar/servico",
    title: "Procurar serviço",
    exact: true,
    component: () => <ProcurarServico />,
  },
  {
    path: "/procurar/avancada",
    title: "Procurar Avançada",
    exact: true,
    component: () => <ProcuraAvancada />,
  },

  {
    path: "/produto/:id",
    title: "Produto",
    exact: true,
    component: () => <ProdutoSingle />,
  },
  {
    path: "/servico/:id",
    title: "Serviço",
    exact: true,
    component: () => <ProdutoSingle type="servico" />,
  },
  {
    path: "/empresa/:id?",
    title: "Fornecedor ",
    exact: true,
    component: () => <VisualizarEmpresa />,
  },

  {
    path: "/catalogo/produtos",
    title: "Produtos",
    exact: true,
    component: () => <Produto />,
  },

  {
    path: "/cadastrar/produto",
    title: "Cadastrar Produto",
    exact: true,
    component: () => <CadastrarProduto />,
  },

  {
    path: "/cadastrar/produto/:id",
    title: "Copiar Produto",
    exact: true,
    component: () => <CopiarProduto />,
  },

  {
    path: "/editar/produto/:id",
    title: "Editar Produto",
    exact: true,
    component: () => <EditarProduto />,
  },

  {
    path: "/catalogo/servicos",
    title: "Serviços",
    exact: true,
    component: () => <Servicos />,
  },

  {
    path: "/cotacao/cotar/:idCompany",
    title: "Cotar",
    exact: true,
    component: () => <Cotar />,
  },

  {
    path: "/cotacao/clientes/pendentes",
    title: "Cotação - Aguardando",
    exact: true,
    component: () => <CotacaoClienteistaPendentes />,
  },

  {
    path: "/cotacao/clientes/respondida",
    title: "Cotação - Respondida",
    exact: true,
    component: () => <CotacaoClienteistaRespondida />,
  },

  {
    path: "/cotacao/minhascontacao/pendentes",
    title: "Cotação - Aguardando",
    exact: true,
    component: () => <CotacaoMinhaListaPendentes />,
  },

  {
    path: "/cotacao/minhascontacao/respondida",
    title: "Cotação - Respondida",
    exact: true,
    component: () => <CotacaoMinhaListaRespondida />,
  },

  {
    path: "/cotacao/minhascontacao/visualizar-cotacao/:id",
    title: "Cotação",
    exact: true,
    component: () => <VisualizacaoCotarPendente />,
  },

  {
    path: "/cotacao/minhascontacao/visualizar-respondida",
    title: "Cotação",
    exact: true,
    component: () => <VisualizacaoCotarResposta />,
  },

  {
    path: "/cotacao/cliente/responder/:id",
    title: "Responder - cotação",
    exact: true,
    component: () => <ResponderCotacao />,
  },

  {
    path: "/cotacao/cliente/respondida/:id",
    title: "Responder - cotação",
    exact: true,
    component: () => <ResponderCotacaoRespondida />,
  },

  {
    path: "/cadastrar/servico",
    title: "Cadastrar novo serviço",
    exact: true,
    component: () => <CadastrarServico />,
  },
  {
    path: "/cadastrar/servico/:id",
    title: "Copiar Serviço",
    exact: true,
    component: () => <CopiarServico />,
  },

  {
    path: "/avaliacao/minhas-avaliacoes",
    title: "Minhas avaliações",
    exact: true,
    component: () => <MinhaAvaliacao tipo="respondidas" />,
  },

  {
    path: "/avaliacao/clientes",
    title: "Avaliações Clientes",
    exact: true,
    component: () => <AvaliacaoCliente tipo="nao-respondidas" />,
  },
  {
    path: "/avaliacao/minhas-avaliacoes/:id",
    title: "Sua conversa com o cliente",
    exact: true,
    component: () => <ConversaMinhaAvaliacao />,
  },
  {
    path: "/avaliacao/clientes/:id",
    title: "Sua conversa com o cliente",
    exact: true,
    component: () => <ConversaCliente />,
  },
  {
    path: "/editar/servico/:id",
    title: "Editar Serviço",
    exact: true,
    component: () => <EditarServico />,
  },

  {
    path: "/regiao-de-atendimento",
    title: "Região de Atendimento",
    exact: true,
    component: () => <RegiaoDeAtendimento />,
  },

  {
    path: "/minha-carteira",
    title: "Minha carteira",
    exact: true,
    component: () => <MinhaCarteira />,
  },
  {
    path: "/assinatura",
    title: "Sua assinatura",
    exact: true,
    component: () => <Assinatura />,
  },
  {
    path: "/precificacao",
    title: "Precificação",
    exact: true,
    component: () => <Precificacao />,
  },
  {
    path: "/variacao",
    title: "Variação",
    exact: true,
    component: () => <Variacao />,
  },
  {
    path: "/produto/:id/variacao",
    title: "Variação Produto",
    exact: true,
    component: () => <VariacaoProduto />,
  },
  {
    path: "/servico/:id/variacao",
    title: "Variação Serviço",
    exact: true,
    component: () => <VariacaoServico />,
  },
  {
    path: "/carrinho",
    title: "Carrinho",
    exact: true,
    component: () => <Carrinho />,
  },
  {
    path: "/pedidos/criados",
    title: "Criados",
    exact: true,
    component: () => <CriadosAprovados status={status.created} />,
  },
  {
    path: "/pedidos/aprovados",
    title: "Aprovados",
    exact: true,
    component: () => <CriadosAprovados status={status.approved} />,
  },
  {
    path: "/pedidos/imprimir",
    title: "Imprimir pedido",
    exact: true,
    component: () => <PrintOrder />,
  },
  {
    path: "/pedidos/cancelados",
    title: "Cancelados",
    exact: true,
    component: () => <Cancelados />,
  },
  {
    path: "/clientes-pedidos/criados",
    exact: true,
    component: () => <CriadosAprovados clientes />,
  },
  {
    path: "/clientes-pedidos/aprovados",
    exact: true,
    component: () => <CriadosAprovados status={status.approved} clientes />,
  },
  {
    path: "/clientes-pedidos/cancelados",
    title: "Cancelados",
    exact: true,
    component: () => <Cancelados clientes />,
  },
  {
    path: "/clientes-pedidos/configuracao",
    title: "Configuração",
    exact: true,
    component: () => <Configuracao />,
  },
  {
    path: "/enderecos",
    title: "Endereços",
    exact: true,
    component: () => <Enderecos />,
  },
];

const routesAuth = [
  {
    path: "/login",
    title: "Logar",
    exact: true,
    component: () => <LogIn />,
  },
  {
    path: "/login/:valid",
    title: "Logar",
    exact: true,
    component: () => <LogIn />,
  },
  {
    path: "/cadastro",
    title: "Logar",
    exact: true,
    component: () => <Cadastrar />,
  },
  {
    path: "/forgot-password",
    title: "Recuperar Senha",
    exact: true,
    component: () => <Redefinir />,
  },
];

export { routes, routesAuth };
