import React from "react";
import { moneyMask } from "../../../uteis/mask";
import {
  Variation,
  VariationActions,
  VariationDelete,
  VariationEdit,
  VariationName,
  VariationOption,
  VariationOptions,
} from "../../variacao/components";

export default function VariationListItem({
  prodVariation,
  callbackEdit,
  callbackDelete,
  isShow,
}) {
  return (
    <Variation isShow={isShow}>
      {!isShow ? (
        <VariationName>
          {moneyMask(prodVariation.price, true)}
          {!!callbackEdit || !!callbackDelete ? (
            <VariationActions>
              {!!callbackEdit ? (
                <VariationEdit onClick={callbackEdit}>
                  <i className="fa fa-edit"></i>
                </VariationEdit>
              ) : null}
              {!!callbackDelete ? (
                <VariationDelete onClick={callbackDelete}>
                  <i className="fa fa-times"></i>
                </VariationDelete>
              ) : null}
            </VariationActions>
          ) : null}
        </VariationName>
      ) : null}


      <VariationOptions>
        {prodVariation.products_variations.length > 0 ? (
          prodVariation.products_variations.map((varia) => (
            <VariationOption
              key={Math.random()}
              style={{
                margin: "5px",
                padding: "5px 10px",
                height: "auto",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <small>{varia.variation.parent.name}</small>
                <strong>{varia.variation.name}</strong>
              </div>
            </VariationOption>
          ))
        ) : (
          <h5 style={{ margin: 0 }}>Nenhuma opção cadastrada</h5>
        )}
      </VariationOptions>
    </Variation>
  );
}
