import React, { createContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

export const CarrinhoContext = createContext(null);

export function CarrinhoProvider(props) { 
  const [carrinho, setCarrinho] = useState(null);

  const notification = (message, type) => toast[type](
    message,
    {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: true,
    }
  )

  const addCarrinho = (item) => {
    const keyCompanyId = `${item.company_id}`;
    const carrinhoCompanyItens = carrinho && !!carrinho[keyCompanyId] ? carrinho[keyCompanyId] : {items: []}

    if (carrinhoCompanyItens?.items
        .some(itemCarrinho => (
          itemCarrinho.id === item.id && itemCarrinho?.variationSelected === item?.variationSelected
        ))
      ) {
      notification("Este item já consta no seu carrinho", "warning")
      return
    }

    const valorTotal = item.selectedPrice;

    const newCarrinho = {
      ...carrinho,
      [keyCompanyId]: {
        ...carrinhoCompanyItens,
        items: [...carrinhoCompanyItens?.items || [], {...item, valorTotal, quantidade: 1}]
      }
    }
    notification("Item adicionado com sucesso ao seu carrinho", "success");

    setCarrinho(newCarrinho);
    localStorage.setItem('carrinho', JSON.stringify(newCarrinho));    
  }

  const updateCarrinhoQuantidade = (item) => {
    const keyCompanyId = `${item.company_id}`;
    const carrinhoCompanyItens = !!carrinho[keyCompanyId] ? carrinho[keyCompanyId] : []

    const valorTotal = Number((item.selectedPrice * item.quantidade).toFixed(2));

    const newCarrinhoCompanyItens = carrinhoCompanyItens.items.map(carrinho => {
      if (carrinho.id !== item.id || carrinho.variationSelected?.id !== item.variationSelected?.id) {
        return carrinho
      }

      return {...item, valorTotal}
    })

    const newCarrinhoCompany = {
      ...carrinho,
      [keyCompanyId]: {
        ...carrinhoCompanyItens,
        items: newCarrinhoCompanyItens || []
      }
    }

    setCarrinho(newCarrinhoCompany);
    localStorage.setItem('carrinho', JSON.stringify(newCarrinhoCompany));
  }

  const removeCompanyCarrinho = companyId => {
    const newCompanyCarrinho = carrinho;
    delete newCompanyCarrinho[companyId];
    
    setCarrinho(newCompanyCarrinho);
    localStorage.setItem('carrinho', JSON.stringify(newCompanyCarrinho));

    if (Object.keys(newCompanyCarrinho).length === 0) {
      setCarrinho(null);
      localStorage.removeItem('carrinho');
    }
  }

  const removeItemCompanyCarrinho = (itemCarrinho) => {
    // #1 - carrinho da empresa
    const companyCarrinho = carrinho[itemCarrinho.company_id];

    // #2 - remove do carrinho da empresa o itemCarrinhos
    const newCarrinhoItems = companyCarrinho.items
      .filter(item => item.id !== itemCarrinho.id || (!!item?.variationSelected && item.variationSelected?.id !== itemCarrinho.variationSelected?.id));

    if (!newCarrinhoItems.length) {
      removeCompanyCarrinho(itemCarrinho.company_id);
      return;
    }
    
    // #3 - remove a empresa do carrinho global
    delete carrinho[itemCarrinho.company_id];
    const newGlobalCarrinho = {...carrinho};
    
    // #4 - logica que seta o carrinho da empresa sem o item da exclusão
    const newCompanyCarrinho = companyCarrinho ? {
      ...newGlobalCarrinho,
      [`${itemCarrinho.company_id}`]: {
        ...companyCarrinho,
        items: newCarrinhoItems
      }
    } : newGlobalCarrinho;

    setCarrinho(newCompanyCarrinho);
    localStorage.setItem('carrinho', JSON.stringify(newCompanyCarrinho));

    if (Object.keys(companyCarrinho.items).length === 0) {
      setCarrinho(null);
      localStorage.removeItem('carrinho');
    }

    notification("Item removido do carrinho", "success");
  }

  const updateCarrinhoEndereco = (item) => {    
    const newCarrinhoCompany = {
      ...carrinho,
      [item.companyId]: {...item}
    }

    setCarrinho(newCarrinhoCompany);
    localStorage.setItem('carrinho', JSON.stringify(newCarrinhoCompany));
  }

  const carrinhoTotal = useMemo(() => {
    const carrinhoStorage = JSON.parse(localStorage.getItem('carrinho'));
    if (carrinho && !!carrinhoStorage) {
      const carrinhoItens = Object.keys(carrinho).reduce((accumulator, currentValue) => carrinho[currentValue].items.length + accumulator, 0);
      return carrinhoItens;
    }
    return 0;
  }, [carrinho]);

  useEffect(() => {
    window.navigation.addEventListener("navigate", (event) => {
      if (window.location.pathname.includes("login")) {
        setCarrinho(null)
      }
    });
  });

  useEffect(() => {
    const carrinhoStorage = JSON.parse(localStorage.getItem('carrinho'));
    if (!carrinho && !!carrinhoStorage) {
      setCarrinho(carrinhoStorage)
      return;
    }
  }, [carrinho]);

  return <CarrinhoContext.Provider
    value={{
      carrinho,
      carrinhoTotal,
      addCarrinho,
      removeItemCompanyCarrinho,
      updateCarrinhoQuantidade,
      updateCarrinhoEndereco,
      removeCompanyCarrinho
    }}
  >
    {props.children}
  </CarrinhoContext.Provider>
}
