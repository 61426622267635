import React, { useContext, useEffect, useState } from 'react'

import back1 from "../../../assets/img/backgrounds/1back.jpg"
import back2 from "../../../assets/img/backgrounds/2back.jpg"
import back3 from "../../../assets/img/backgrounds/3back.jpg"

import sidebar1 from "../../../assets/img/backgrounds/sidebar1.jpg"
import sidebar2 from "../../../assets/img/backgrounds/sidebar2.jpg"
import sidebar3 from "../../../assets/img/backgrounds/sidebar3.jpg"

import sideBarBack1 from "../../../assets/img/backgrounds/sidebar1.jpg"
import { PageSettings } from '../../../config/page-settings'
import { getBackgrounds, updateBackground } from '../../../services/background'
import { store } from 'react-notifications-component'

export default ({setLoading, data,...props}) => {
    const [backPerfilEmpresa, setBackPerfilEmpresa] = useState(back1)
    const [backProduto, setBackProduto] = useState(back1)
    const [backServico, setBackServico] = useState(back1)
    const [backSidebar, setBackSidebar] = useState(sideBarBack1)
    const backs = [back1, back2, back3]
    const backsSidebar = [sidebar1, sidebar2, sidebar3]

    const types = {
        'background-perfil-empresa': 'perfil_empresa',
        'background-produto': 'produtos',
        'background-servico': 'servicos',
        'background-sidebar': 'sidebar'
    }
    
    const contextType = useContext(PageSettings);

    const editBackground = async (storageItem, backItem) => {
        setLoading(true)

        const dataToSave = {
            company_id: data.companies[0].id,
            type: types[storageItem],
        }

        if (storageItem === "background-sidebar") {
            localStorage.setItem(storageItem, backsSidebar[backItem])
            dataToSave.name = backsSidebar[backItem].replace('/static/media/', '')
        } else {
            localStorage.setItem(storageItem, backs[backItem])
            dataToSave.name = backs[backItem].replace('/static/media/', '')
        }
        
        await updateBackground(data.companies[0].id, dataToSave)
            .then(response => {
                store.addNotification({
                    title: "Background atualizado!",
                    message: response.data.resposta.conteudo.message,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 10000,
                        onScreen: true
                    }
                })
            })
            .catch(err => console.log(err))
            .finally(() => setLoading(false))

        setBacks()
        contextType.handleSetChangeBackground(true);
    }

    const setBacks = () => {
        setBackPerfilEmpresa(localStorage.getItem('background-perfil-empresa') || backs[0])
        setBackProduto(localStorage.getItem('background-produto') || backs[0])
        setBackServico(localStorage.getItem('background-servico') || backs[0])
        setBackSidebar(localStorage.getItem('background-sidebar') || backsSidebar[0])
    }

    const pegaBackgrounds = async (idCompany) => {
        setLoading(true)
        await getBackgrounds(idCompany)
            .then(response => {})
            .catch(err => console.log(err))
            .finally(() => {
                setBacks()
                setLoading(false)
            })
    }
    
    useEffect(() => {
        pegaBackgrounds(data.companies[0].id)
    },[])

    return (
        <div className="mudar-fundo-container">
            <div className="page-perfil-empresa">
                <h4>Página Perfil da Empresa</h4>
                <div>
                    <h6>Plano de fundo atual</h6>
                    {
                        backs.map((back, index) => {
                            return <img
                                key={index}
                                src={`${back}`}
                                className={`img-thumbnail ${backPerfilEmpresa && backPerfilEmpresa.includes(back.split('/').pop()) && 'select'}`}
                                alt="Imagem de fundo"
                                onClick={() => editBackground('background-perfil-empresa', index)}
                            />
                        })
                    }
                </div>
            </div>
            

            {props.profile === "1" ?
            <>
                <hr />
                <div className="page-produto">
                    <h4>Página de Produto </h4>
                    <div>                    
                        <h6>Plano de fundo atual</h6>
                        {
                            backs.map((back, index) => {
                                return <img
                                    key={index}
                                    src={`${back}`}
                                    className={`img-thumbnail ${backProduto && backProduto.includes(back.split('/').pop()) && 'select'}`}
                                    alt="Imagem de fundo"
                                    onClick={() => editBackground('background-produto', index)}
                                />
                            })
                        }
                    </div>
                </div>
            </>

            : null}

            {props.profile === "1" ?
            <>
                <hr />
                <div className="page-servico">
                    <h4>Página de Serviço </h4>
                    <div>                    
                        <h6>Plano de fundo atual</h6>
                        {
                            backs.map((back, index) => (
                                <img
                                    key={index}
                                    src={`${back}`}
                                    className={`img-thumbnail ${backServico && backServico.includes(back.split('/').pop()) && 'select'}`}
                                    alt="Imagem de fundo"
                                    onClick={() => editBackground('background-servico', index)}
                                />
                            ))
                        }
                    </div>
                </div>     
            </>
            :null}
    
            <hr />
            <div className="page-sidebar">
                <h4>Sidebar</h4>
                <div>
                    <h6>Plano de fundo atual</h6>
                    {
                        backsSidebar.map((back, index) => {
                            return <img
                                key={index}
                                src={`${back}`}
                                className={`img-thumbnail ${backSidebar && backSidebar.includes(back.split('/').pop()) && 'select'}`}
                                alt="Imagem de fundo"
                                onClick={() => editBackground('background-sidebar', index)}
                            />
                        })
                    }
                </div>
            </div> 
        </div>
    )
}
