import React, { createContext, useState } from "react";

export const PedidoContext = createContext(null);

export function PedidoProvider(props) { 
  const [pedido, setPedido] = useState(null);

  return <PedidoContext.Provider
    value={{
      pedido,
      setPedido
    }}
  >
    {props.children}
  </PedidoContext.Provider>
}
