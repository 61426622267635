import React from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row } from "reactstrap"
const Topo = ( {info} ) => {
  return(
    <Col md="12">
    <Card>
        <CardBody>
          <Row>
            <Col md="12" lg="3" className="align-items-center justify-content-center justify-content-md-start jus d-flex mb-4 mb-lg-0 ">
              <div className="mr-2">
                <img src={require("../../assets/img/company/img-1.png")} alt="logo empresa" className="rounded-corner avatar-sm " />
              </div>
              <div>
                <p className="mb-0 text-muted">Seja bem-vindo</p>
                <h4 className="mb-0">{info.name}</h4>
              </div>
            </Col>
            <Col md="12" lg="6" className="mb-2 mb-lg-0">
              <Row className="justify-content-around">
                <Col md="3" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3">Visitas</p>
                  <h2>{info.visits}</h2>
                </Col >
                <Col md="3" className="item-dados text-center mb-3 mb-lg-0 ">
                  <p className="mb-0 text-muted mt-3">Cotações</p>
                  <h2>{info.quotations}</h2>
                </Col >
              </Row>

            </Col>
            <Col md="6" lg="3" className="text-center  text-md-right">
            <p className="mb-0 text-muted text-md-right ">Plano: <strong style={{ color: '#1a237e' }}>Gratuito</strong></p>
              <p className="mb-0 text-muted text-md-right " ></p>
              <p className="mb-0 text-muted text-md-right ">
                <Link className="btn btn btn-dark" to="/assinatura" type="button" >
                  <i className="fa fa-cog"></i> Mudar
                </Link>
              </p>

            </Col>
          </Row>
        </CardBody>
    </Card>
  </Col>
  )
}

export default  Topo
