import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import styled from "styled-components";
import Loader from "../../components/loader";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import { useCarrinho } from "../../hooks/useCarrinho";
import { getAdresses } from "../../services/adresses";
import { CarrinhoCompany } from "./components/CarrinhoCompany";

const CarrinhoListWrapper = styled.div``;

export function Carrinho() {
  const contextType = useContext(PageSettings);
  const { carrinho } = useCarrinho();
  const [loading, setLoading] = useState(true);
  
  const [enderecos, setEnderecos] = useState([]);

  const pegaEnderecos = useCallback(async () => {
    setLoading(true);
    await getAdresses()
      .then((response) => {
        setEnderecos(response.data.resposta.conteudo.adresses);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  const defaultAddress = useMemo(() => {
    const isDefaultAddress = enderecos.find(address => address.default);
    return isDefaultAddress || enderecos[0];
  }, [enderecos])

  useEffect(() => {
    pegaEnderecos();
  }, [pegaEnderecos])

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader loading={loading} />

      <TitlePage
        title="Carrinho"
        desc="Aqui você gerencia seu carrinho."
        icon="fa fa-shopping-cart"
      />
      <div className="row">
        <div className="col-md-12">
          <div className="mb-2">
            <Link
              to="/enderecos"
              style={{ color: "#0085ff", textDecoration: "underline" }}
            >
              Cadastrar novo endereço
            </Link>
          </div>
          <CarrinhoListWrapper>
            {carrinho && Object.keys(carrinho).length ? (
              Object.keys(carrinho)?.map((companyId) => (
                <CarrinhoCompany
                  key={companyId}
                  companyId={companyId}
                  carrinho={{ ...carrinho[companyId], companyId }}
                  enderecos={enderecos}
                  defaultAddress={defaultAddress}
                />
              ))
            ) : (
              <h2>Nenhum item no carrinho...</h2>
            )}
          </CarrinhoListWrapper>
        </div>
      </div>
    </>
  );
}
