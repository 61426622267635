import styled from "styled-components";

export const TagDanger = styled.div`
  margin-bottom: 1rem;
  background: ${(props) => props.theme.colors.danger};
  padding: .5rem;
  padding-left: .8rem;
  border-radius: 5px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.white};
`
