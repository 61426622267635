const meusPedidos = (path = 'pedidos') => [
  { path: `/${path}/criados`, title: path === "pedidos" ? "Criados" : "Solicitados" },
  { path: `/${path}/aprovados`, title: "Aprovados" },
  // { path: `/${path}/pendentes`, title: "Pendentes" },
  { path: `/${path}/cancelados`, title: "Cancelados" },
]

export const menus = [
  { path: "/empresa", icon: "fa fa-eye", title: "Perfil da empresa" },
  { path: "/", icon: "fas fa-chart-area", title: "Dashboard", exact: true },
  {
    path: "/regiao-de-atendimento",
    icon: "fa fa-globe-americas",
    title: "Região de atendimento",
  },
  {
    path: "/catalogos",
    icon: "fa fa-th",
    title: "Catalogos",
    children: [
      { path: "/catalogo/produtos", title: "Produtos" },
      { path: "/catalogo/servicos", title: "Serviços" },
    ],
  },
  {
    path: "/pedidos",
    icon: "fa fa-shopping-cart",
    title: "Meus Pedidos",
    children: meusPedidos(),
  },
  {
    path: "/clientes-pedidos",
    icon: "fa fa-cart-arrow-down",
    title: "Pedidos de Clientes",
    children: [...meusPedidos('clientes-pedidos'), { path: "/clientes-pedidos/configuracao", title: "Configuração" }]
  },
  {
    path: "/precificacao",
    icon: "fas fa-tags",
    title: "Precificação",
  },
  {
    path: "/enderecos",
    icon: "fa fa-map-marker",
    title: "Endereços",
  },
  {
    path: "/variacao",
    icon: "fas fa-boxes",
    title: "Variações",
  },
  {
    path: "/avaliacao/:idFornecedor",
    icon: "far fa-star",
    title: "Avaliações",
    children: [
      { path: "/avaliacao/clientes", title: "Avaliações de Clientes" },
      { path: "/avaliacao/minhas-avaliacoes", title: "Minhas Avaliações" },
    ],
  },
  { path: "/minha-carteira", icon: "fa fa-wallet", title: "Minha carteira" },
  {
    path: "/procurar/avancada",
    icon: "fab fa-simplybuilt",
    title: "Pesquisar",
  },

  {
    path: "/cotacao/recebida",
    icon: "fas fa-file-invoice",
    title: "Minhas Cotações",
    children: [
      { path: "/cotacao/minhascontacao/pendentes", title: "Pendentes" },
      { path: "/cotacao/minhascontacao/respondida", title: "Respondidas" },
    ],
  },

  {
    path: "/cotacao/recebida",
    icon: "fas fa-file-invoice-dollar",
    title: "Cotações de Cliente",
    children: [
      { path: "/cotacao/clientes/pendentes", title: "Pendentes" },
      { path: "/cotacao/clientes/respondida", title: "Respondidas" },
    ],
  },
];

export const menusComprador = [
  { path: "/empresa", icon: "fa fa-eye", title: "Perfil da empresa" },
  { path: "/", icon: "fas fa-chart-area", title: "Dashboard", exact: true },
  {
    path: "/avaliacao/minhas-avaliacoes",
    icon: "far fa-star",
    title: "Minhas avaliações",
  },
  {
    path: "/enderecos",
    icon: "fa fa-map-marker",
    title: "Endereços",
  },
  {
    path: "/pedidos",
    icon: "fa fa-shopping-cart",
    title: "Pedidos",
    children: meusPedidos(),
  },
  { path: "/minha-carteira", icon: "fa fa-wallet", title: "Minha carteira" },
  {
    path: "/procurar/avancada",
    icon: "fab fa-simplybuilt",
    title: "Pesquisar",
  },
  {
    path: "/cotacao/recebida",
    icon: "fas fa-file-invoice",
    title: "Minhas Cotações",
    children: [
      { path: "/cotacao/minhascontacao/pendentes", title: "Pendentes" },
      { path: "/cotacao/minhascontacao/respondida", title: "Respondidas" },
    ],
  },
];
