import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Col, Row } from "reactstrap";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import {
  DeleteOption,
  Variation,
  VariationActions,
  VariationDelete,
  VariationEdit,
  VariationName,
  VariationOption,
  VariationOptions,
} from "./components";
import ModalVariacaoForm from "./form";
import { useVariacao } from "./hooks/useVariacao";

export default function Variacao() {
  const [deletar, setDeletar] = useState(null);
  const [modalVariacao, setModalVariacao] = useState(false);
  const {
    variacao,
    variacoes,
    alertData,
    setVariacao,
    getVariacao,
    handleSubmit,
  } = useVariacao();

  const contextType = useContext(PageSettings);

  const toggleModalFiltro = () => {
    setModalVariacao(!modalVariacao);
  };

  const confirmar = async () => {
    await handleSubmit(deletar);
    setDeletar(null);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={() => setDeletar(null)}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}

      {alertData && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText={alertData.confirmBtnText}
          confirmBtnBsStyle={alertData.confirmBtnBsStyle ?? "danger"}
          cancelBtnBsStyle={alertData.cancelBtnBsStyle ?? "default"}
          title={alertData.title}
          onConfirm={alertData.onConfirm}
          onCancel={alertData.onCancel}
          {...alertData}
        >
          {alertData.content}
        </SweetAlert>
      )}

      <TitlePage
        title="Variações"
        desc="Aqui você gerencia suas variações."
        icon="fas fa-boxes"
      />
      <div className="row">
        <div className="col-md-12">
          <Row className="justify-content-end mt-3">
            {modalVariacao && (
              <ModalVariacaoForm
                open={modalVariacao}
                toggle={toggleModalFiltro}
                variacao={variacao}
                setVariacao={setVariacao}
                handleSubmit={handleSubmit}
              />
            )}
            <Col md="4" lg="4" xl="2">
              <button
                className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
                onClick={() => toggleModalFiltro()}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                Adicionar
              </button>
            </Col>
          </Row>
        </div>
        <div className="col-md-12">
          {variacoes.length > 0 ? (
            variacoes.map((varia) => (
              <Variation>
                <VariationName>
                  {varia.name}
                  <VariationActions>
                    <VariationEdit
                      onClick={async () => {
                        await getVariacao(varia.id);
                        toggleModalFiltro();
                      }}
                    >
                      <i className="fa fa-edit"></i>
                    </VariationEdit>
                    <VariationDelete
                      onClick={() => setDeletar({ ...varia, active: false })}
                    >
                      <i className="fa fa-trash"></i>
                    </VariationDelete>
                  </VariationActions>
                </VariationName>
                <VariationOptions>
                  {varia?.options.length > 0 ? (
                    varia?.options.map((item) => (
                      <VariationOption>
                        {item.name}
                        <DeleteOption
                          onClick={async () =>
                            await handleSubmit({ ...item, active: false })
                          }
                        >
                          <i className="fa fa-times"></i>
                        </DeleteOption>
                      </VariationOption>
                    ))
                  ) : (
                    <h5 style={{ margin: 0 }}>Nenhuma opção cadastrada</h5>
                  )}
                </VariationOptions>
              </Variation>
            ))
          ) : (
            <VariationName>Nenhuma variação criada!</VariationName>
          )}
        </div>
      </div>
    </>
  );
}
