import styled from "styled-components";

export const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;
  height: 30px;
  padding: 5px 0px 5px 10px;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) => props.theme.colors.blue4};
  margin-bottom: 10px;
  margin-right: 10px;
  border-radius: 3px;
`;

export const DeleteOption = styled.button`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  border: none;
  align-items: center;
  height: 30px;
  margin-left: 10px;
  margin-bottom: -10px;
  margin-top: -10px;
  background: ${(props) => props.theme.colors.danger};
  &:hover {
    background: ${(props) => props.theme.colors.danger2};
  }
  border-radius: 3px;
`;

export const Variation = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: column;
  max-width: 500px;
  background: ${(props) =>
    props.isShow ? "transparent" : props.theme.colors.white};
  margin-bottom: ${(props) => (props.isShow ? 0 : "10px")};
  padding: ${(props) => (props.isShow ? 0 : "10px 5px")};
  border-radius: 5px;
  gap: 1rem;
`;
export const VariationName = styled.h3`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  margin-right: 10px;
`;
export const VariationOptions = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  min-width: 300px;
`;
export const VariationOption = styled(Option)`
  margin: 5px;
`;
export const VariationActions = styled.div`
  display: flex;
`;
export const VariationEdit = styled(DeleteOption)`
  color: ${(props) => props.theme.colors.white};
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${(props) => props.theme.colors.primary};
  &:hover {
    background: ${(props) => props.theme.colors.blue3};
  }
`;
export const VariationDelete = styled(VariationEdit)`
  margin-right: 10px;
  background: ${(props) => props.theme.colors.danger};
  &:hover {
    background: ${(props) => props.theme.colors.danger2};
  }
`;
