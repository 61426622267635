import React, { useContext, useEffect } from "react";
import Loader from "../../../components/loader";
import TitlePage from "../../../components/pages/title";
import { PageSettings } from "../../../config/page-settings";

export function BasePedidos(props) {
  const contextType = useContext(PageSettings);

  useEffect(() => {
    window.scrollTo(0, 0);
    contextType.closeMobileSiderBar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loader loading={props.loading} />

      <TitlePage
        title={props.titulo}
        desc={props.descricao}
        icon="fa fa-shopping-cart"
      />
      <div className="row">
        <div className="col-md-12">
          {props.children}
        </div>
      </div>
    </>
  );
}
