import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCompanie } from "../../../services/companies";
import { getOtherProducts } from "../../../services/product";
import { getOtherServices } from "../../../services/service";
import { statusPedido as statusPedidoService } from "../../../services/portfolios";
import { notification } from "../../../uteis/notifications";
import { createEvaluations } from "../../../services/evaluations";

function useGetData() {
    const [listaProdutos, setListaProdutos] = useState({ data: [] })
    const [listaServicos, setListaServicos] = useState({ data: [] })
    const [empresa, setEmpresa] = useState({})
    const [statusPedido, setStatusPedido] = useState({})
    const [carregandoStatus, setCarregandoStatus] = useState(true)
    const [loading, setLoading] = useState(true);

    const { user, current_companie } = useSelector(state => state.auth)

    const currentIdCompany = useCallback(() => {
        const urlParamInt = parseInt(window.location.pathname.split('/').pop() || '')
        return !isNaN(urlParamInt) ? urlParamInt : user.companies[0].id
    }, [user.companies])

    const getProdutos = useCallback(
        async (params = {}) => {
            setLoading(true);
            await getOtherProducts({ ...params, company_id: currentIdCompany() })
                .then((response) => {
                    setListaProdutos({
                        currentUrlProducts: response.request.responseURL,
                        ...response.data.resposta.conteudo.products,
                    });
                })
                .catch((error) => {
                    if (error && error.response?.data) {
                        const messages = error.response.data.resposta.conteudo.messages;
                        Object.keys(messages).forEach((key) => {
                            messages[key].forEach((message) => {
                                // avisar("Ops!!", message, "danger")
                            });
                        });
                        return;
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        },
        [currentIdCompany]
    );
    const getServicos = useCallback(async (params = {}) => {
        setLoading(true)
        await getOtherServices({ ...params, company_id: currentIdCompany() }).then(response => {
            setListaServicos({
                currentUrlServices: response.request.responseURL,
                ...response.data.resposta.conteudo.services
            })
        })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            // avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
            })
            .finally(() => {
                setLoading(false)
            })
    }, [currentIdCompany])

    const getEmpresa = async (empresaId) => {
        setLoading(true)
        await getCompanie(empresaId).then(response => {
            setEmpresa(response.data.resposta.conteudo.empresa)
        })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            notification("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                notification("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const getStatus = async (empresaId) => {
        await statusPedidoService(empresaId).then(response => {
            setStatusPedido(response.data.resposta.conteudo.statusRequest)
        })
            .catch(error => {
                setStatusPedido({})
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            notification("Ops!!", message, "danger")
                        })
                    })
                    return
                }
            })
            .finally(() => {
                setCarregandoStatus(false)
            })
    }

    const createComent = async ({ title, comment, stars }, callback) => {
        if (current_companie.id === empresa.id)
            return notification("Você não pode avaliar sua própria empresa", "Tente avaliando outra empresa", "danger")
        const input = {
            company_id: empresa.id,
            title: title,
            description: comment,
            status: "ACTIVE",
            stars: String(stars)
        }
        if (!comment) return notification("Texto do comentário obrigatório.", "Texto não pode ser vazio", "danger")
        await createEvaluations(input)
            .then(response => {
                notification("Comentário adicionado", "Comentário adicionado com sucesso.", "success")
                if (callback) callback()
                getEmpresa(empresa.id)
            })
            .catch(err => {
                if (err && err.response && err.response.data?.resposta?.conteudo?.message) {
                    notification("Algo deu errado!", err.response.data.resposta.conteudo.message, "danger")
                }
            })
    }

    const runServices = useCallback(async () => {
        setLoading(true);
        Promise.all([
            getProdutos(),
            getServicos(),
            getEmpresa(currentIdCompany()),
            getStatus(currentIdCompany()),
        ]).finally(() => setLoading(false));
    }, [currentIdCompany, getProdutos, getServicos]);

    useEffect(() => {
        runServices()
    }, [runServices]);

    return {
        listaProdutos,
        listaServicos,
        empresa,
        statusPedido,
        carregandoStatus,
        loading,
        setLoading,
        runServices,
        getEmpresa,
        getProdutos,
        getServicos,
        createComent
    }
}

export { useGetData }