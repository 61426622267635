import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";

const ItemProcurar = ({ item, companyId }) => {
  if (!item) return null;

  return (
    <li>
      <Link
        to={`/empresa/${companyId}`}
        className="result-image"
        style={{
          backgroundImage: "url(/assets/img/gallery/gallery-52.jpg)",
        }}
      ></Link>
      <div className="result-info">
        <h4 className="title">
          <Link to={`/empresa/${companyId}`}>{item.company_name}</Link>
        </h4>
        <p className="location">
          <Badge>
            {parseInt(item.profile) === 1 ? "fornecedor" : "cliente"}
          </Badge>
        </p>
        <p className="desc">{item.description}</p>
        <div className="btn-row">
          <Link
            to={`/empresa/${companyId}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Analytics"
          >
            <i className="far fa-star"></i> 3/4
          </Link>
          <Link
            to={`/empresa/${companyId}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            <i className="far fa-eye"></i> {item.hit || 0}
          </Link>
        </div>
      </div>
      <div className="result-price">
        <Link
          to={`/empresa/${companyId}`}
          className="btn btn-yellow btn-block"
        >
          Ver perfil
        </Link>
      </div>
    </li>
  );
};

export default ItemProcurar;
