import React, {useState, useCallback} from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import 'react-datetime/css/react-datetime.css';
import Cropper from 'react-easy-crop'
import getCroppedImg from './crop'

const ModalCrop = ({modal, toggle, sendFile, foto})=>{
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
      setCroppedAreaPixels(croppedAreaPixels)

    }, [])

    const recortarImagem = async (foto, croppedAreaPixels, rotation) => {
        try {
          const croppedImage = await getCroppedImg(
            foto,
            croppedAreaPixels,
            rotation
          )
          sendFile(croppedImage)
        } catch (e) {
          console.error(e)
        }
      }
    
    return(
        <Modal isOpen={modal} toggle={toggle} centered={true}>
            <ModalHeader toggle={toggle}>Recorte a Imagem</ModalHeader>
            <ModalBody style={{minHeight: 350}} className="p-2">
            <Cropper
                image={foto}
                crop={crop}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                />
            </ModalBody>
            <ModalFooter>
                <Button className="mt-2" onClick={()=>recortarImagem(foto, croppedAreaPixels, 0)} color="primary"  >Salvar</Button>{' '}
            </ModalFooter>
        </Modal>
    )
}

export default ModalCrop