import { cepMask } from "./mask";

export function mountFullAddress({
  address,
  neighborhood,
  complement,
  city,
  uf,
  postal_code
}, onlyAddress) {
  if (
    !address &&
    !neighborhood &&
    !complement &&
    !city &&
    !uf &&
    !postal_code
  ) {
    return "Endereço: nenhum endereço cadastrado"
  }
  return `${onlyAddress ? "" : "Endereço: " }
    ${address ? address + ", " : ""}
    ${neighborhood ? neighborhood + ", " : ""}
    ${complement ? complement : ""}
    ${city ? " - " + city?.name + "/" : ""}
    ${uf ? uf?.sigla_uf + (postal_code ? ", " : "") : ""}
    ${uf?.country ? uf?.country.namePortuguese+" - " : ""}
    ${postal_code ? cepMask(postal_code) : ""}`
}

export function isSignUpNotCompleted(currentCompanie) {
  return !currentCompanie.city ||
    !currentCompanie.cnpj ||
    !currentCompanie.company_logo ||
    !currentCompanie.complement ||
    !currentCompanie.country ||
    !currentCompanie.company_name ||
    !currentCompanie.description ||
    !currentCompanie.economic_activities ||
    (!currentCompanie.email &&
      !currentCompanie.phone &&
      !currentCompanie.instagram &&
      !currentCompanie.facebook &&
      !currentCompanie.financial_phone &&
      !currentCompanie.linkedin &&
      !currentCompanie.twitter &&
      !currentCompanie.website &&
      !currentCompanie.youtube &&
      !currentCompanie.whatsapp) ||
    !currentCompanie.fantasy_name ||
    !currentCompanie.postal_code ||
    !currentCompanie.companies_types
};
