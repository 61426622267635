import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { PageSettings } from "../../config/page-settings";
import { getCompanie } from "../../store/auth/action";

import { Row } from "reactstrap";
import Loader from "../../components/loader";

import Topo from "./topo";
import TopoComprador from "./topoComprador";

import { useSelector } from "react-redux";


import { getInfoDashboad } from "../../services/dashboard";
import { isSignUpNotCompleted } from "../../uteis/functions";
import { DashboardInfoCards } from "./dashboardInfoCards";


const tipos = [
  { title: "Semana", linkto: "#", isActive: false },
  { title: "Mês", linkto: "#", isActive: true },
  { title: "Ano", linkto: "#", isActive: false }
];

const BuyerOrSeller = (profile, tipos, info) => {
  if( parseInt(profile) === 1){
    return(
      <>
      <Row>
        <Topo info={info}/>
        <DashboardInfoCards fornecedor={true}  info={info}/>
      </Row>
      </>
    )
  }else{
    return(
      <>
      <Row>
        <TopoComprador info={info}/>
      </Row>
      <Row >
        <DashboardInfoCards fornecedor={false} info={info} />
      </Row>
      </>
    )

  }
}
function Home() {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const dispatch = useDispatch()
  const {user, current_companie} = useSelector(state => state.auth)
  const [info, setInfo] = useState({ products: 0, services: 0, visits: 0, name: "", quotations: 0, totalQuoted: "0" , signUpCompleted: false, times: "00"}) 
  const loadInfo = async()=> {
    setLoading(true)
    const dashInfo = await getInfoDashboad()
    if(user)
      await dispatch(getCompanie(user.companies[0].id))
    setInfo({
      ...info,
      products: dashInfo.qtdProducts,
      quotations: dashInfo.qtdQuotations,
      totalQuoted: dashInfo.totalQuoted,
      services: dashInfo.qtdServices,
      name: user.companies[0].company_name,
      visits: dashInfo.numberVisits,
      times: dashInfo.responseTime,
      signUpCompleted: finalizouCadastro(current_companie)
    })
    setLoading(false)
  }

  const finalizouCadastro = () => {
    if (!current_companie) return;

    setInfo({
      ...info,
      signUpCompleted: !isSignUpNotCompleted(current_companie),
    });
  };

  useEffect(()=>{
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    loadInfo()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
    if(user)
      dispatch(getCompanie(user.companies[0].id))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(()=>{
    finalizouCadastro()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current_companie])

  return (
    <>
      <Loader loading={loading}/>

      <h1 className="page-header">
        <i className="fal fa-building mr-2 opacity-5 text-aqua"></i>
        Dashboard <br />
        <small>Suas principais informações apareceram aqui.</small>
      </h1>
      {BuyerOrSeller(user.companies[0].profile, tipos, info)}
    </>
  );
};

export default Home;
