import React, { useState } from "react";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { DeleteOption, Option } from "./components";
import { initialVariacaoValues } from "./hooks/useVariacao";

const ModalVariacaoForm = ({ open, toggle, variacao, setVariacao, handleSubmit }) => {
  const [option, setOption] = useState('');

  const removeOption = async (itemOrIndex) => {
    let options = [];

    options = variacao.options.filter((_, idx) => idx !== itemOrIndex);
    if (itemOrIndex?.id) {
      await handleSubmit({ ...itemOrIndex, active: false })
      options = variacao.options.filter((item) => item?.id !== itemOrIndex?.id);
    }

    setVariacao({
      ...variacao,
      options,
    })
  }
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className={"modal-dialog modal-dialog-right "}
    >
      <form
        onSubmit={(event) => {
          event.preventDefault();
          toggle();
          handleSubmit(variacao);
        }}
      >
        <ModalHeader toggle={toggle}>Adicionar nova Variação</ModalHeader>
        <ModalBody>
          <Row className="mt-2 mb-2">
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Nome</label>
                <Input
                  type="input"
                  name="name"
                  className="form-round"
                  value={variacao.name}
                  onChange={(event) =>
                    setVariacao({ ...variacao, name: event.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Opções</label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    name="discount"
                    className="form-control"
                    value={option}
                    onChange={(event) =>
                      setOption(event.target.value)
                    }
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-primary"
                      type="button"
                      id="button-addon2"
                      onClick={() => {
                        setVariacao({
                          ...variacao,
                          options: [...variacao.options, option],
                        })
                        setOption('');
                      }}
                    >
                      Adicionar
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12" style={{ display: 'flex', flexWrap: 'wrap' }}>
              { variacao?.options && variacao.options.map((item, index) => (
                <Option key={item?.id ?? index+item}>
                  {item?.name ?? item}
                  <DeleteOption type="button" onClick={() => removeOption(item?.id ? item : index)}>
                    <i className="fa fa-times"></i>
                  </DeleteOption>
                </Option>
              ))}
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success">
            Salvar
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              toggle();
              setVariacao(initialVariacaoValues);
            }}
          >
            Cancelar
          </Button>{" "}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalVariacaoForm;
