import React from "react";
import {
  Modal,
  ModalHeader,
  Button,
  ModalFooter,
  ModalBody,
  Input,
  Row,
} from "reactstrap";
import { porcentageFormat } from "../../uteis/mask";
import { initialTagValues } from "./hooks/usePrecificacao";

const ModalTagForm = ({ modal, toggle, tag, setTag, handleSubmit }) => {
  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      className={"modal-dialog modal-dialog-right "}
    >
      <form onSubmit={(event) => {
        event.preventDefault();
        toggle();
        handleSubmit(tag)
      }}>
        <ModalHeader toggle={toggle}>Adicionar nova Tag de Desconto</ModalHeader>
        <ModalBody>
          <Row className="mt-2 mb-2">
            <div className="col-md-12">
              <div className="form-group">
                <label className="opacity-5 mb-1">Nome</label>
                <Input
                  type="input"
                  name="name"
                  className="form-round"
                  value={tag.name}
                  onChange={(event) =>
                    setTag({ ...tag, name: event.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="opacity-5 mb-1">Desconto</label>
                <div className="input-group mb-2">
                  <input
                    type="text"
                    name="discount"
                    className="form-control"
                    value={tag.discount}
                    onChange={(event) =>
                      setTag({
                        ...tag,
                        discount: porcentageFormat(event.target.value),
                      })
                    }
                    maxLength={5}
                    required
                  />
                  <div className="input-group-prepend">
                    <span
                      id="discount-addon"
                      className="input-group-text"
                      style={{
                        borderTopRightRadius: '26px',
                        borderBottomRightRadius: '26px'
                      }}
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="primary">
            Adicionar
          </Button>{" "}
          <Button
            color="danger"
            onClick={() => {
              toggle();
              setTag(initialTagValues);
            }}
          >
            Cancelar
          </Button>{" "}
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ModalTagForm;
