import React from 'react';
import styled from "styled-components";

export const InputField = styled.input`
  border-radius: 5px;
  border: 1px solid;
  border-color: ${(props) => props.theme.colors.gray2};
  padding: 0.3rem 0.5rem;
`
const Fields = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;
export function MontarCampos({ fields, values, detalhesPagamento, setDetalhesPagamento }) {
  return (
    <Fields>
      {fields &&
        fields.map(({ name, type, field, label }) => {
          const value = values ? values[name] : undefined;
          switch (field) {
            case "input":
              return (
                <InputField
                  key={name+'-'+label}
                  {...{ name, type, placeholder: label }}
                  onChange={e => {
                    const newDetalhesPagamento = {...detalhesPagamento, [name]: e.target.value}
                    
                    if (!Boolean(e.target.value)) {
                      delete newDetalhesPagamento[name]
                    }

                    if (Object.keys(newDetalhesPagamento).length === 0) {
                      setDetalhesPagamento(null)
                      return
                    }
                    setDetalhesPagamento(newDetalhesPagamento)
                  }}
                  style={{ width: "100%" }}
                  value={value}
                  disabled={!!value}
                />
              );
            default:
              return "";
          }
        })}
    </Fields>
  );
}
