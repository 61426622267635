import React, { useEffect, useState } from "react"
import { useCallback } from "react"
import { Link } from "react-router-dom"
import { API } from "../../config/api.config"

const Pagination = (props) => {
    const { lastPage, currentPage, currentUrl, path, current_page, last_page,
        baseUrl, getItems, params, setPage } = props

    const [urls, setUrls] = useState([])

    const goTo = url => {
        if (setPage) {
            setPage(url)
        }
        params ? getItems({...params, page: url}) : getItems(url)
        if(window)
            window.scrollTo(0, 0)
    }

    const generateUrls = useCallback((numberPages) => {
        const urlObjects = []
        for (let index = 0; index < numberPages; index++) {
            const pageNumer = index+1;
            urlObjects.push({
                url: `${API}/${baseUrl}?page=${pageNumer}`,
                pageNumer
            })
        }
        setUrls(urlObjects)
    }, [baseUrl]);

    useEffect(() => {
        generateUrls(lastPage)
    }, [generateUrls, lastPage])

    return (
        <div className="d-flex flex-column align-items-center">
            <ul className="pagination pages-items pull-right justify-content-center ">
                {
                    urls.map(urlObject => (
                        <li key={urlObject.pageNumer} className={`page-item ${currentPage === urlObject.pageNumer && 'active'}`}>
                            <Link to={`#`} className={`page-link`} onClick={() => goTo(urlObject.pageNumer)}>
                                {urlObject.pageNumer}
                            </Link>
                        </li>
                    ))
                }
            </ul>
            <ul className="pagination pull-right justify-content-around flex-wrap ">
                <div className="d-flex m-2">
                    <li
                        className={`page-item ${((current_page || currentPage) === 1 || currentUrl === path) && 'disabled'}`}
                        style={{
                            width: '80px',
                            textAlign: 'center'
                        }}
                    >
                        <Link to={`#`} className="page-link" onClick={() => goTo(1)}>
                            Primeira
                        </Link>
                    </li>
                    <li
                        className={`page-item ${((current_page || currentPage) === 1 || currentUrl === path) && 'disabled'}`}
                        style={{
                            width: '80px',
                            textAlign: 'center'
                        }}
                    >
                        <Link to={`#`} className="page-link" onClick={() => goTo((current_page || currentPage)-1)}>
                            Anterior
                        </Link>
                    </li>
                </div>
                <div className="d-flex m-2">
                    <li
                        className={`page-item ${(current_page || currentPage) === (last_page || lastPage) && 'disabled'}`}
                        style={{
                            width: '80px',
                            textAlign: 'center'
                        }}
                    >
                        <Link to={`#`} className="page-link" onClick={() => goTo((current_page || currentPage)+1)}>
                            Próxima
                        </Link>
                    </li>
                    <li
                        className={`page-item ${(current_page || currentPage) === (last_page || lastPage) && 'disabled'}`}
                        style={{
                            width: '80px',
                            textAlign: 'center'
                        }}
                    >
                        <Link to={`#`} className="page-link" onClick={() => goTo(lastPage)}>
                            Última
                        </Link>
                    </li>
                </div>
            </ul>
        </div>
    )
}

export default Pagination