import React from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { moneyMask } from "../../../uteis/mask";
import { status as statusEnum } from "../../../uteis/orders";

const Declaration = styled.div`
  font-size: 1.2rem;
  line-height: normal;
  > span {
    font-weight: 600;
  }
`;

export function ModalConfirmarPagamento({
  open,
  order,
  setOrder,
  confirmarPagamento,
}) {
  const {id, total_value, customer: { company_name }} = order;
  return (
    <Modal isOpen={open} toggle={() => setOrder(null)} size="md" centered>
      <ModalBody>
        <Declaration>
          Declaro ter recebido <span>{moneyMask(total_value, true)}</span> da empresa <span>{company_name}</span>, referente ao pedido <span>nº {id}</span>.
        </Declaration>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => setOrder(null)}>
          Fechar
        </Button>
        <Button
          onClick={() => {
            confirmarPagamento({
              id: order.id,
              status: statusEnum.paid,
            });
            setOrder(null);
          }}
        >
          Confirmar declaração
        </Button>
      </ModalFooter>
    </Modal>
  );
}
