import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { API } from "../../config/api.config";
import { getCompanie as getCompanieAPI } from "../../services/companies";
import * as type from "./types";
function logInRequested(email, password){

    var formdata = new FormData();
    formdata.append("email", email);
    formdata.append("password", password);
    var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };

    return new Promise((resolve, reject)=>{
        return fetch(`${API}/auth/login`, requestOptions)
        .then(response => {
            if(response.status !== 200){
                reject("Usuario ou senha invalido")
            }else{
                resolve(response.json())
            }
        })
        .catch(err =>{
            reject("Usuario ou senha invalido")
        })
    })

}

function* logIn(action){
    const {email, senha, history} = action.payload
    try{
        const result = yield call(logInRequested, email, senha)
        localStorage.removeItem("access_token")
        localStorage.setItem("access_token", result.resposta.conteudo.access_token)
        localStorage.setItem("expires_at", result.resposta.conteudo.expires_at)
        localStorage.setItem("userInfo", JSON.stringify(result.resposta.conteudo.user))
        yield put({type: type.LOG_IN_SUCCESSED, payload: result.resposta.conteudo})

        yield put({type: type.CURRENT_COMPANIE, payload: result.resposta.conteudo.user.companies[0]})

        history.push("/")
    }catch(e){
        yield put({type: type.LOG_IN_FAILED, payload: e})
    }
}

function* logOut(action){
    const {history} = action.payload
    try{
        localStorage.removeItem("access_token")
        localStorage.removeItem("expires_at")
        localStorage.removeItem("userInfo")
        localStorage.removeItem("carrinho")

        yield put({type: type.LOG_OUT_SUCCESSED, payload: ""})
        history.push("/login")
    }catch(e){
        console.log("deslogar")
    }
}

function* getCompanie(action) {
    const { companieId } = action.payload
    try {
        const result = yield call(getCompanieAPI, companieId)
    
        yield put({type: type.CURRENT_COMPANIE, payload: result.data.resposta.conteudo.empresa})
    } catch (error) {
        console.log('Error', error);
    }
}

function* loginWatcher(){
    yield takeLatest(type.LOG_IN_REQUESTED, logIn)
}

function* logOutWatcher(){
    yield takeLatest(type.LOG_OUT_REQUESTED, logOut)
}

function* getCompanieWatcher(){
    yield takeLatest(type.COMPANIE_REQUESTED, getCompanie)
}

function* authSaga() {
    yield all([
        fork(loginWatcher),
        fork(logOutWatcher),
        fork(getCompanieWatcher),
    ]);
}


export default authSaga
