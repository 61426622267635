import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { status as statusEnum } from "../../uteis/orders";
import { BasePedidos } from "./components/BasePedidos";
import { ModalAceitar } from "./components/ModalAceitar";
import { ModalCancelar } from "./components/ModalCancelar";
import { ModalConfirmarEnvio } from "./components/ModalConfirmarEnvio";
import { ModalConfirmarPagamento } from "./components/ModalConfirmarPagamento";
import { ModalConfirmarRecebimento } from "./components/ModalConfirmarRecebimento";
import PedidoItem from "./components/PedidoItem";
import { usePedidos } from "./hooks/usePedidos";

const ListStatus = styled.div`
  display: grid;
  grid-auto-flow: row dense;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: .5em .5em;
  max-width: 768px;
  margin: 1.5em 1rem 2rem 1rem;

  @media screen and (max-width: 630px){
    grid-template-columns: 1fr;
    grid-template-rows: 1fr repeat(${props => props.totalItems});
  }
`;

function setTitleStatus({
  pedido,
  status,
  currentCompanieId
}) {
  if (status === statusEnum.canceled) {
    return "Pedido cancelado"
  }
  if (pedido?.payment_voucher && status === statusEnum.approved) {
    return "Aguardando confirmação de pagamento"
  }
  if (pedido?.payment_voucher && status === statusEnum.paid) {
    return "Aguardando envio do pedido"
  }

  if (
    (pedido.customer_id === currentCompanieId &&
      status === statusEnum.approved) ||
    (!pedido?.payment_voucher &&
      pedido.supplier_id === currentCompanieId &&
      status === statusEnum.approved)
  ) {
    return "Aguardando pagamento";
  }

  return "Aguardando aprovação"
}

const labelsOrder =  {
  [statusEnum.created]: {title: 'Aceitar', text: 'Aceitar', modalAceitarLabel: 'Forma do pagamento escolhido pelo cliente:'},
  [statusEnum.approved]: {title: 'Pagar', text: 'Pagar', modalAceitarLabel: 'Dados de pagamento do fornecedor:'},
  alterarComprovante: {title: 'Alterar comprovante', text: 'Alterar comprovante', modalAceitarLabel: 'Alterar o comprovante de pagamento:'},
}

export const statusOptionsSelect = [
  {text: "Aguardando pagamento do cliente", value: statusEnum.approved}, 
  {text: "Aguardando confirmação de pagamento pelo fornecedor", value: "wait-confirm-payment"}, 
  {text: "Aguardando envio por parte do fornecedor", value: statusEnum.paid},
  {text: "Aguardando entrega", value: "sent"},
  {text: "Entregue/Recebido", value: "received"},
]

export function CriadosAprovados({ clientes, status = 'created' }) {
  const [order, setOrder] = useState(null);
  const [reason, setReason] = useState();
  const [deliveryDetails, setDeliveryDetails] = useState();
  const [openModal, setOpenModal] = useState(null);

  const [statusView, setStatusView] = useState(status);
  
	const {current_companie} = useSelector(state => state.auth)

  const {
    loading: loadingPedidos,
    pedidos,
    alterarStatus,
    adicionarComprovante
  } = usePedidos({
    type: statusView,
    getCustomer: clientes,
  });

  return (
    <>
      {openModal && order ? (
        <>
          {openModal === "aceitar" ? (
            <ModalAceitar
              {...{
                order: order,
                setOrder: setOrder,
                reason,
                setReason,
                aprovar: alterarStatus,
                adicionarComprovante: adicionarComprovante,
                open: openModal === "aceitar",
                title: labelsOrder[status].modalAceitarLabel,
                currentCompanieId: current_companie.id,
              }}
            />
          ) : null}

          {openModal === "cancelar" ? (
            <ModalCancelar
              {...{
                open: openModal === "cancelar",
                order,
                setOrder,
                reason,
                setReason,
                cancelar: alterarStatus,
              }}
            />
          ) : null}

          {openModal === "confirmar-pamento" ? (
            <ModalConfirmarPagamento
              {...{
                open: openModal === "confirmar-pamento",
                order,
                setOrder,
                reason,
                setReason,
                confirmarPagamento: alterarStatus,
              }}
            />
          ) : null}

          {openModal === "confirmar-envio" ? (
            <ModalConfirmarEnvio
              {...{
                open: openModal === "confirmar-envio",
                order,
                setOrder,
                deliveryDetails,
                setDeliveryDetails,
                confirmarEnvio: alterarStatus,
              }}
            />
          ) : null}

          {openModal === "confirmar-recebimento" ? (
            <ModalConfirmarRecebimento
              {...{
                open: openModal === "confirmar-recebimento",
                order,
                setOrder,
                confirmarRecebimento: alterarStatus,
              }}
            />
          ) : null}
        </>
      ) : null}
      <BasePedidos
        titulo={`Pedidos ${
          status === statusEnum.approved ? "Aprovados" : "Criados"
        }`}
        descricao={`Aqui você gerencia os pedidos ${
          status === statusEnum.approved ? "Aprovados" : "Criados"
        }.`}
        loading={loadingPedidos}
      >
        {status === statusEnum.approved ? (
          <ListStatus totalItems={statusOptionsSelect.length}>
            {statusOptionsSelect.map(({ text, value }) => (
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="seleciona-status"
                  type="radio"
                  id={`seleciona-status-${value}`}
                  onChange={() => {
                    setStatusView(value);
                  }}
                  checked={statusView === value}
                />
                <label
                  style={{ paddingLeft: "20px" }}
                  htmlFor={`seleciona-status-${value}`}
                >
                  {text}
                </label>
              </div>
            ))}
          </ListStatus>
        ) : null}

        {pedidos.length ? (
          pedidos.map((pedido) => (
            <div>
              <PedidoItem
                pedido={pedido}
                clientes={clientes}
                status={statusView}
                setTitleStatus={setTitleStatus}
                currentCompanieId={current_companie.id}
                setOpenModal={setOpenModal}
                setOrder={setOrder}
              />
            </div>
          ))
        ) : (
          <h2>Nenhum pedido feito...</h2>
        )}
      </BasePedidos>
    </>
  );
}
