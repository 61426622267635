import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../config/api.config";
import { formatedVariationsPrice } from "../../uteis/formatedVariationsPrice";
import { moneyMask } from "../../uteis/mask";
import { AdicionarCarrinho } from "../adicionarCarrinho";

const btns = item => {
  return (
    <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.updateActive(item.produto);
        }}
        className="btn btn-warning btn-icon btn-circle btn-lg m-3"
        title={item.produto.active === 1 ? "DESATIVAR" : "ATIVAR"}

      >
        {
          item.produto.active === 1 ?
            <i className="fa fa-ban"></i> :
            <i className="fas fa-play"></i>
        }
      </Button>
      <Link
        to={`/editar/produto/${item.produto?.id}?name=${item.produto?.name}&desc=${item.produto?.description}`}
        className="btn btn-success btn-icon btn-circle btn-lg m-3"
        title="Editar"
      >
        <i className="fa fa-edit"></i>
      </Link>
      <Link
        to={`/cadastrar/produto/${item.produto?.id}?name=${item.produto?.name}&desc=${item.produto?.description}`}
        className="btn btn-primary btn-icon btn-circle btn-lg m-3"
        title="Copiar"
      >
        <i className="fa fa-clone"></i>
      </Link>
      <Link
        to={`/produto/${item.produto?.id}/variacao`}
        className="btn btn-info btn-icon btn-circle btn-lg m-3"
        title="Variações"
      >
        <i className="fas fa-boxes"></i>
      </Link>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setDeletar(item.produto)
        }}
        className="btn btn-danger btn-icon btn-circle btn-lg m-3"
        title="Excluir"
      >
        <i className="fa fa-times"></i>
      </Button>
    </div>
  )
}

const Produto = (props) => {
  const { produto, company } = props

  const image = produto.images[0]?.path ? `${URL_TO_IMAGES}/${produto.images.find(img => img.featured_image === 1)?.path.replace('public', '')}` : '/assets/img/default-product.svg'

  return (
    <li style={{
      border: produto.active === 1 ? 'none' : '2px solid #ff5b57'
    }}>
      <div
        to={`/produto/${produto.id}`}
        className="result-image"
        style={{
          backgroundImage: `url( ${image} )`,
          backgroundSize: "contain",
          backgroundColor: "white"
        }}

      >
      </div>
      <div className="result-info">
        <h4 className="title">
          <Link to={`/produto/${produto.id}`}>
            {produto.name}
          </Link>
        </h4>
        <span className="badge badge-secondary">{produto.category.name}</span>

        <div className="desc" dangerouslySetInnerHTML={{ __html: produto.description }}></div>

        <div className="btn-row">
          {
            produto.avg && (
              <Link
                to={`/produto/${produto.id}`}
                data-toggle="tooltip"
                data-container="body"
                data-title="Analytics"
              >
                <>
                  <i className="far fa-star"></i> {produto.avg}
                </>
              </Link>
            )
          }
          <Link
            to={`/produto/${produto.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            <i className="far fa-eye"></i> {produto?.hit || 0}
          </Link>

        </div>
      </div>
      <div className="result-price" style={{ zIndex: 4, paddingTop: 20 }}>
        {produto.active !== 1 && (
          <span
            className="badge badge-danger"
            style={{
              position: "absolute",
              top: 0,
              right: "-10px",
              paddingRight: "20px",
              fontSize: "13px",
              borderRadius: 0
            }}
          >
            DESATIVADO
          </span>
        )}

        <div className="d-sm-block align-self-end align-self-sm-end">
          {
            produto.price_type === "precification" ? (
              produto.variations_price ?
                formatedVariationsPrice(produto.variations_price) :
                moneyMask(produto.default_price, true)
              
            ) : null
          }
        </div>

        <div className="d-sm-block align-self-end align-self-sm-end">
          {props.isUser ? btns(props) : null}
        </div>

        {
          produto.price_type === "precification" && !props.isUser ? (
            <AdicionarCarrinho item={{...produto, company, selectedPrice: produto?.variations_price ? undefined : produto.default_price }} />
          ) : null
        } 

        {produto.price_type !== "precification" && !props.isUser && (
          <Link
            to={`/cotacao/cotar/${parseInt(produto.company_id)}?produto=${produto.id}`}
            className="btn btn-outline-primary btn-block">
            <i className="fa fa-plus mr-2"></i>
            Adicionar a Cotação
          </Link>
        )}

        <Link
          to={`/produto/${produto.id}`}
          className="btn btn-yellow btn-block"
          style={{ margin: 0 }}
        >
          {props.servico ? "Ver serviço" : "Ver produto"}
        </Link>
      </div>
    </li>
  );
};

export default memo(Produto);
