import React, { memo } from "react";
import { useSelector } from "react-redux";
import ItemProduto from "../../components/produto";
import SkeletonItem from "../../components/skeleton/skeleton-item";
import { Item } from "../../components/v2/produto/item";
const ListarProduto = ({ listaProdutos, setDeletar, updateActive }) => {
  const oldItem = false
  
  const { current_companie } = useSelector((state) => state.auth);
  if (!listaProdutos) return null

  return (
    <ul className="result-list">
      {
        listaProdutos ? (
          listaProdutos.map((produto => {
            return oldItem ? <ItemProduto
              key={produto.id}
              isUser={true}
              produto={produto}
              setDeletar={setDeletar}
              updateActive={updateActive}
            /> :
              <li style={{
                border: produto.active === 1 ? 'none' : '2px solid #ff5b57',
                position: 'relative'
              }}>
                {produto.active !== 1 && (
                  <span
                    className="badge badge-danger"
                    style={{
                      position: "absolute",
                      top: 0,
                      right: "-10px",
                      paddingRight: "20px",
                      fontSize: "13px",
                      borderRadius: 0,
                      zIndex: 1
                    }}
                  >
                    DESATIVADO
                  </span>
                )}
                <Item
                  item={{
                    ...produto,
                    nome: produto.name,
                    descricao: produto.description,
                    company: current_companie,
                    setDeletar: setDeletar,
                    updateActive: updateActive,
                    tipo: "produto"
                  }}
                  setarPosition={() => console.log('setarPosition')}
                />
              </li>
          }))
        ) : (
          <>
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
            <SkeletonItem />
          </>
        )
      }
    </ul>
  )
}

export default memo(ListarProduto)
