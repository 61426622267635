import styled from "styled-components";

export const PedidoContainer = styled.div`
  border-bottom: 1px solid #c5cdd3;
  border-color: ${(props) => props.theme.colors.gray2};
  margin-bottom: 1.5rem;

  background: white;
  padding: 1rem;
  border-radius: 10px;
  box-shadow: 5px 5px 5px 0px #80808042;
`;
