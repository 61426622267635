import React, { useContext, useEffect, useState } from "react";
import SweetAlert from 'react-bootstrap-sweetalert';
import { store } from "react-notifications-component";
import {
  Button,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from "reactstrap";
import Breadcrumb from "../../components/breakcumbs";
import ItemProduto from "../../components/itemProcurar";
import Loader from "../../components/loader";
import TitlePage from "../../components/pages/title";
import Pagination from "../../components/paginate/pagination";
import { PageSettings } from "../../config/page-settings";
import { getCompanies } from "../../services/companies";
import ModalFiltro from "./parts/modalFiltro";

const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const Home = () => {
    const [loading, setLoading] = useState(true);
    const [modalFiltro, setModalFiltro] = useState(false)
    const [splitButtonOpen, setSplitButtonOpen] = useState(false);
    const contextType = useContext(PageSettings);
    const [deletar, setDeletar] = useState(false)
    const [fornecedores, setFornecedores] = useState([])

    const toggleSplit = () => {
        setSplitButtonOpen(!splitButtonOpen);
    };

    const getFornecedores = async () => {
        await getCompanies().then(response => {
            setFornecedores(response.data.resposta.conteudo.companies)
        })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()

        getFornecedores()

        setTimeout(() => {
            setLoading(false);
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const confirmar = () => {
        console.log("confirmar")
        setDeletar(false)
    }
    const deletarItem = () => {
        console.log("confirmar")
        setDeletar(false)
    }

    const toggleModalFiltro = () => {
        setModalFiltro(!modalFiltro)
    }
    return (
        <>
            {(deletar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim, deletar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Você tem certeza?"
                    onConfirm={confirmar}
                    onCancel={deletarItem}
                >
                    Após essa ação Você não conseguira recuperar os dados.
                </SweetAlert>
            )}
            <Loader loading={loading} />

            <Breadcrumb list={[{ name: "Home", path: "/" }, { name: "Procurar fornecedor", path: "/cadastroEmpresa" }]} />
            <TitlePage title="Procurar fornecedor" desc="Pesquise por nome, setor de atividade" icon="fab fa-simplybuilt" />
            <div className="row">
                <ModalFiltro modal={modalFiltro} toggle={toggleModalFiltro} />
                <div className="col-md-12">
                    <div className="result-container">
                        <InputGroup size="lg" className="m-b-20">
                            <Input
                                placeholder="Pesquise o seu fornecedor..."
                                type="text"
                                className="input-white"
                            />
                            <InputGroupButtonDropdown
                                addonType="append"
                                isOpen={splitButtonOpen}
                                toggle={toggleSplit}
                            >
                                <Button color="success" >
                                    <i className="fa fa-search fa-fw"></i>
                                </Button>
                            </InputGroupButtonDropdown>
                        </InputGroup>
                        <button className="btn btn-yellow mb-3" onClick={toggleModalFiltro}>Pesquisa Avançada</button>
                        <ul className="result-list">
                            {
                                fornecedores.data ? (
                                    fornecedores?.data.map(fornecedor => {
                                        return <ItemProduto
                                            item={fornecedor}
                                            key={Math.random()}
                                        />
                                    })
                                ) : (
                                    <h2>Sem fornecedores...</h2>
                                )
                            }
                        </ul>
                        <div className="clearfix m-t-20">
                            <Pagination
                                firstPageUrl={fornecedores.first_page_url}
                                lastPageUrl={fornecedores.last_page_url}
                                nextPageUrl={fornecedores.next_page_url}
                                previousPageUrl={fornecedores.prev_page_url}

                                perPage={fornecedores.per_page}
                                lastPage={fornecedores.last_page}
                                currentPage={fornecedores.current_page}
                                currentUrl={fornecedores.currentUrlProducts}
                                path={fornecedores.path}

                                {...fornecedores}

                                baseUrl={'auth/companies'}
                                getItems={getCompanies}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
