import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import { useCarrinho } from "../../hooks/useCarrinho";
import Produto from "../../pages/produtoFinal/parts/produto";

export function AdicionarCarrinho({ item, toggleModalCarrinho }) {
  const [openModal, setOpenModal] = useState(false);
  const { addCarrinho } = useCarrinho();

  const handleAddCarrinho = () => {
    if (!item.selectedPrice && item.variations.length > 0) {
      setOpenModal(true);
      return;
    }
    if (toggleModalCarrinho) {
      toggleModalCarrinho();
    }

    addCarrinho(item);
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <Modal isOpen={openModal} toggle={toggleModal} size="lg" centered>
        <ModalBody>
          <Produto produto={item} empresa={item.company} type={"produto"} toggleModalCarrinho={toggleModal} />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggleModal}>
            Cancelar
          </Button>
        </ModalFooter>
      </Modal>

      <Button
        onClick={handleAddCarrinho}
        className="btn btn-secondary btn-block"
      >
        <i
          className="fa fa-cart-plus"
          aria-hidden="true"
          style={{
            marginRight: ".5rem",
            fontSize: "1rem",
          }}
        ></i>
        Adicionar ao Carrinho
      </Button>
    </>
  );
}
