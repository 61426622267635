import React from "react";
import { moneyMask } from "../../../uteis/mask";
import {
  Variation,
  VariationActions,
  VariationDelete,
  VariationEdit,
  VariationName,
  VariationOption,
  VariationOptions,
} from "../../variacao/components";

export default function VariationListItem({
  servVariation,
  callbackEdit,
  callbackDelete,
  isShow,
}) {
  return (
    <Variation isShow={isShow}>
      {!!callbackEdit || !!callbackDelete ? (
        <VariationActions>
          {!!callbackEdit ? (
            <VariationEdit onClick={callbackEdit}>
              <i className="fa fa-edit"></i>
            </VariationEdit>
          ) : null}
          {!!callbackDelete ? (
            <VariationDelete onClick={callbackDelete}>
              <i className="fa fa-times"></i>
            </VariationDelete>
          ) : null}
        </VariationActions>
      ) : null}

      {!isShow ? (
        <VariationName>{moneyMask(servVariation.price, true)}</VariationName>
      ) : null}

      <VariationOptions>
        {servVariation.services_variations.length > 0 ? (
          servVariation.services_variations.map((varia) => (
            <VariationOption
              style={{
                margin: "5px",
                padding: "5px 10px",
                height: "auto",
                borderRadius: "5px",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <small>{varia.variation.parent.name}</small>
                <strong>{varia.variation.name}</strong>
              </div>
            </VariationOption>
          ))
        ) : (
          <h5 style={{ margin: 0 }}>Nenhuma opção cadastrada</h5>
        )}
      </VariationOptions>
    </Variation>
  );
}
