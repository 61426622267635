import React, { useContext, useEffect } from "react";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";

// reactstrap components
import { useState } from "react";
import ReactNotification, { store } from 'react-notifications-component';
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Row
} from "reactstrap";

// core components
import 'react-notifications-component/dist/theme.css';
import { forgot } from "../../services/auth/forgot";
import { emailVerified } from "../../services/auth/user";
import { logIn } from "../../store/auth/action";
import { PageSettings } from './../../config/page-settings.js';
import RecuperarSenha from "./recuperarSenha";

const avisar = (title, msg, type) => {
  store.addNotification({
    title: title || "Usuário ou senha incorretos",
    message: msg || "Um código de verificação foi enviado para o seu email!",
    type: type || "danger",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: {
      duration: 7000,
      onScreen: true
    }
  });
}

function SignUp(props) {
  const contextType = useContext(PageSettings);
  const [recuperarSenha, setarRecuperarSenha] = React.useState(false)
  const [email, setEmail] = React.useState("");
  const [senha, setSenha] = React.useState("");
  const [loadingReset, setLoadingReset] = useState(false)
  const [valido, setValido] = useState(true)
  const [loadingValido, setLoadingValido] = useState(true)

  const params = useParams()
  const history = useHistory()

  const dispatch = useDispatch()
  const { err, loading } = useSelector(state => state.auth)

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  const enviarDados = () => {
    if (!validateEmail(email.toLowerCase().trim()))
      return avisar("Error", "Preencha o campo email, ou formato do email não é válido", "danger")
    dispatch(logIn(email.toLowerCase().trim(), senha, props.history))
  }

  const f_email = async () => {
    if (!email || !validateEmail(email)) {
      return avisar("Erro ao cadastrar!", "Campo email obrigatório!", "danger")
    }

    setLoadingReset(true)

    await forgot(email)
      .then(response => {
        if (response.status !== 200) {
          setarRecuperarSenha(false)
          avisar("Recuperar senha", "Se o e-mail existir na nossa base, você recebera um e-mail com as orientações para altera-lo", "success")
        } else {
          setarRecuperarSenha(false)
          avisar("Recuperar senha", "Se o e-mail existir na nossa base, você recebera um e-mail com as orientações para altera-lo", "success")
        }
      })
      .catch(error => {
        if (error.response.data.resposta?.conteudo?.message) {
          avisar("Ops!!", error.response.data.resposta?.conteudo?.message, "danger")
        }
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            avisar("Ops!!", error.response.data.resposta?.conteudo?.error, "danger")
          }

          if (messages) {
            Object.keys(messages).forEach(key => {
              messages[key].forEach(message => {
                avisar("Ops!!", message, "danger")
              })
            })
          }
          return
        }

        avisar("Ops!!", "Erro interno no servidor!", "danger")
      })
      .finally(() => {
        setLoadingReset(false)
      })

  }

  const handleVerified = async () => {
    setLoadingValido(true)
    await emailVerified()
      .then(response => {
        const message = response?.data?.resposta?.conteudo?.message
        if (message) {
          if (message === "Email já verificado.") {
            setValido(true)
            history.push('/login')
          } else {
            avisar("Sucesso", response.data.resposta.conteudo.message, "success")
          }
        }
      })
      .catch(err => {
        if (err && err.response.status === 401) {
          history.push('/login')
        } else {
          avisar("Error", "Erro interno no servidor, tente de novo mais tarde.", "danger")
        }
      })
      .finally(() => setLoadingValido(false))
  }

  useEffect(() => {
    const URLParams = new URLSearchParams(window.location.search)
    setEmail(URLParams.get('email') || "")

    setValido(!(params.valid === 'nao-valido'))
    if (params.valid === 'nao-valido') {
      handleVerified()
    }

    contextType.handleSetPageSidebar(false);
    contextType.handleSetPageHeader(false);
    contextType.handleSetPageContentFullWidth(true);
    return () => {
      contextType.handleSetPageSidebar(true);
      contextType.handleSetPageHeader(true);
      contextType.handleSetPageContentFullWidth(false);
    }
  }, [])

  const login = () => {
    return (
      (
        <div>
          {err && <Alert color="danger">{err}</Alert>}

          {
            !loadingValido && (
              <Modal isOpen={!valido} centered>
                <ModalHeader toggle={() => setValido(!valido)} >
                  <h3>Sua conta ainda não foi ativada!</h3>
                </ModalHeader>
                <ModalBody
                  style={{
                    height: '130px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                  }}
                >
                  <h5>Para ativar, clique no link de ativação que enviamos para o seu e-mail.</h5>
                  <Button block color="secondary" onClick={handleVerified}>Reenviar link de validação da minha conta</Button>
                </ModalBody>
              </Modal>
            )
          }

          <InputGroup className={"input-group-focus"}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fal fa-envelope"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder="Email..." value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="input-lg"
            ></Input>
          </InputGroup>

          <InputGroup className={"input-group-focus mt-3"}>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <i className="fal fa-lock"></i>
              </InputGroupText>
            </InputGroupAddon>
            <Input
              placeholder="Senha"
              type="password"
              onChange={(e) => setSenha(e.target.value)}
            ></Input>
          </InputGroup>
          <Button
            className="btn btn-lg btn-lg bg-primary text-white btn-block mt-5"
            color="primary"
            onClick={enviarDados}
            disabled={loading}
            size="lg"
          >
            {loading ? "Carregando" : "Entrar"}
          </Button>

          <h5 className="text-center cursor-pointer mt-3 light" onClick={() => setarRecuperarSenha(!recuperarSenha)}>
            Esqueci minha senha
          </h5>
        </div>
      )
    )
  }
  return (
    <>
      <ReactNotification />
      <div
        className="section section-signup"
        style={{
          backgroundImage: "url(" + require("../../assets/img/logar-on-top.svg") + ")," + "url(" + require("../../assets/img/logar.svg") + ")",

        }}
      >
        <Container fluid>
          <Row>
            <Col md={11} className="d-flex justify-content-center justify-content-md-end">
              <Card className="card-signup shadow-none" >
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <Link to="/"><img src={require("../../assets/img/logo@2x.png")} style={{ maxWidth: 100 }} alt="Logo Achei Fornecedor" /></Link>
                    <CardTitle tag="p">
                      {recuperarSenha ? "Qual o seu e-mail cadastrado?" : 'Seja Bem-vindo!'}
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    {
                      recuperarSenha ?
                        <RecuperarSenha
                          setEmail={setEmail}
                          f_email={f_email}
                          loading={loadingReset}
                        />
                        :
                        login()
                    }
                  </CardBody>
                  <CardFooter className="text-center pb-0">
                    <h5 className="text-center">
                      Ainda não tem conta? <Link to="/cadastro"><strong>Cadastre-se</strong></Link>
                    </h5>
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Row>
          <div className="col text-center">

          </div>
        </Container>
      </div>
    </>
  );
}

export default withRouter(SignUp);
