import { useCallback, useEffect, useState } from "react";
import { createImages, getService as getServiceAPI } from "../../../services/service";
import {
  createVariationsServices as createVariationsServicesAPI,
  getVariationsServices,
  updateVariationsServices as updateVariationsServicesAPI,
} from "../../../services/variationsService";
import { orderBy } from "../../../uteis";
import { notification } from "../../../uteis/notifications";

export function useVariacaoServico({ id }) {
  const [loading, setLoading] = useState(false);
  const [servico, setServico] = useState();
  const [servicoVariacoes, setServicoVariacoes] = useState([]);

  const getServico = useCallback(async () => {
    if (id) {
      await getServiceAPI(id)
        .then((response) => {
          const resProd = response.data.resposta.conteudo.service;
          setServico(resProd);
        })
        .catch((error) => {
          console.log("Erro ao buscar serviço", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const getServicoVariacoes = useCallback(async () => {
    if (id) {
      await getVariationsServices(id)
        .then((response) => {
          const resProd = response.data.resposta.conteudo.serviceVatiations;

          const newData = resProd.data.map(prodVar => {
            const idProdVar = prodVar.id;
            const images = prodVar?.services_images.filter(img => img?.variations_service?.variation_service_id === idProdVar);

            return {
              ...prodVar,
              services_images: images
            }
          })
          setServicoVariacoes({...resProd, data: newData});
        })
        .catch((error) => {
          console.log("Erro ao buscar serviço", error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const createVariationsServices = async (formdatData, callback) => {
    await createVariationsServicesAPI(formdatData)
      .then(async (response) => {
        if (response) {
          notification(
            "Variação do serviço salva com sucesso!",
            "Sua variação do serviço foi salva e já esta disponivel na plataforma",
            "success"
          );
        }

        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages;

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            notification(
              "Ops!!",
              error.response.data.resposta?.conteudo?.error,
              "danger"
            );
          }

          if (messages) {
            Object.keys(messages).forEach((key) => {
              messages[key].forEach((message) => {
                notification("Ops!!", message, "danger");
              });
            });
          }
          return;
        }

        notification("Ops!!", "Erro interno no servidor!", "danger");
      });
  };

  const updateVariationsServices = async (id, formdatData, callback) => {
    formdatData.append("id", id);
    formdatData.append("_method", "PUT");
    await updateVariationsServicesAPI(id, formdatData)
      .then(async (response) => {
        notification(
          "Variação do serviço salva com sucesso!",
          "Sua variação do serviço foi salva e já esta disponivel na plataforma",
          "success"
        );
        if (callback) {
          callback();
        }
      })
      .catch((error) => {
        if (error && error.response?.data?.resposta) {
          const messages = error.response.data?.resposta?.conteudo?.messages;

          if (error.response.data.resposta?.conteudo?.error && !messages) {
            notification(
              "Ops!!",
              error.response.data.resposta?.conteudo?.error,
              "danger"
            );
          }

          if (messages) {
            Object.keys(messages).forEach((key) => {
              messages[key].forEach((message) => {
                notification("Ops!!", message, "danger");
              });
            });
          }
          return;
        }

        notification("Ops!!", "Erro interno no servidor!", "danger");
      });
  };

  const savePhotos = async (valores, isEdit) => {
    const filesOrder = orderBy(valores.photos, "order");

    const currentImages = {
      current_images: [],
    };
    filesOrder.forEach(async (file, index) => {
      if (file?.id) {
        currentImages.current_images.push(`id: ${file.id}, order: ${index}`);
        return;
      }
      file.order = index;
      if (servico.id) {
        const newImages = new FormData();
        newImages.append("service_id", servico.id);
        newImages.append("images[]", file);
        await createImages(newImages).then((response) => {
          currentImages.current_images.push(
            `id: ${response.data.resposta.conteudo.images[0].id}, order: ${file.order}`
          );
        });
      }
    });

    return currentImages;
  };

  useEffect(() => {
    getServico();
    getServicoVariacoes();
  }, [getServico, getServicoVariacoes]);

  return {
    servico,
    servicoVariacoes,
    getServicoVariacoes,
    createVariationsServices,
    updateVariationsServices,
  };
}
