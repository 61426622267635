import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import styled from "styled-components";
import { Delete } from "../../components/layout/buttons/Delete";
import { Edit } from "../../components/layout/buttons/Edit";
import Loader from "../../components/loader";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import { deleteAddress, getAdresses } from "../../services/adresses";
import { mountFullAddress } from "../../uteis/functions";
import { notification } from "../../uteis/notifications";
import { FormEndereco } from "./FormEndereco";

const AddressList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.8rem;
`
const AddressItem = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  background: white;
  box-shadow: 8px 5px 10px 0 #80808021;
  border-radius: 5px;
  padding: 15px;
  font-weight: 600;
  position: relative;
`
const Padrao = styled.div`
  width: 100px;
  position: absolute;
  top: -5px;
  font-weight: 700;
  height: 17px;
  background: #0a243c;
  color: white;
  padding: 0;
  font-size: .7rem;
  border-radius: 3px;
  text-align: center;
`

export function Enderecos() {
  const [loading, setLoading] = useState(false);
  const [enderecos, setEnderecos] = useState([]);
  const [modal, setModal] = useState(false);
  const [editData, setEditData] = useState(null);
  const contextType = useContext(PageSettings);

  const toggle = () => {
    setModal(!modal);
  }

  const pegaEnderecos = useCallback(async () => {
    setLoading(true);
    await getAdresses()
      .then((response) => {
        setEnderecos(response.data.resposta.conteudo.adresses);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  const handleDelete = async (id) => {
    setLoading(true);
    await deleteAddress(id)
      .then((response) => {
        if (response) {
          pegaEnderecos();
          notification("Sucesso", "Endereco excluido com sucesso!", "success");
        }
      })
      .finally(() => setLoading(false));
  };
  
  const handleAddOrEdit = async (address) => {
    if (address) {
      setEditData(address);
    } else {
      setEditData(null);
    }
    toggle();
  }

  const enderecosFormated = enderecos.map((endereco) => ({
    ...endereco,
    fullAddress: mountFullAddress(
      {
        address: endereco.address,
        neighborhood: endereco.neighborhood,
        complement: endereco.complement,
        city: endereco.city,
        uf: endereco.city.uf_with_country,
        postal_code: endereco.postal_code,
      },
      true
    ),
  }));

  useEffect(() => {
    pegaEnderecos();
  }, [pegaEnderecos])

  useEffect(() => {
    contextType.closeMobileSiderBar()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Loader loading={loading} />

      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>{editData ? 'Alterar' : 'Cadastrar novo'} endereço</ModalHeader>
        <ModalBody>
          <FormEndereco
            pegaEnderecos={pegaEnderecos}
            isEditData={!!editData}
            editData={editData}
            setEditData={setEditData}
            toggle={toggle}
          />
        </ModalBody>
      </Modal>

      <TitlePage
        title="Endereços"
        desc="Gerenciamento dos meus endereços"
        icon="fa fa-shopping-cart"
      />
      <Row>
        <Col md="4" lg="4" xl="2">
          <button
            className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
            onClick={() => handleAddOrEdit()}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            Adicionar
          </button>
        </Col>
        <div className="col-md-12">
          <AddressList>
            {enderecosFormated.map(endereco => (
              <AddressItem key={endereco.id}>
                {
                  !!endereco.default ? <Padrao>Padrao</Padrao> : null
                }
                {endereco.fullAddress}
                <Delete onClick={() => handleDelete(endereco.id)}>
                  <i className="fa fa-times"></i>
                </Delete>
                <Edit onClick={() => handleAddOrEdit(endereco)}>
                  <i className="fa fa-edit"></i>
                </Edit>
              </AddressItem>
            ))}
          </AddressList>
        </div>
      </Row>
    </>
  );
}
