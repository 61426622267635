// https://www.freecodecamp.org/news/build-a-custom-pagination-component-in-react/
import React, { useEffect } from "react";
import styled from "styled-components";
import { DOTS, usePagination } from "./hooks/usePagination";

const List = styled.ul`
  display: flex;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
`;
const Item = styled.li`
  &.active {
    background: #0a243c;
    color: white;
  }
  &.disabled {
    background: transparent;
  }

  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #6f8293;
  background-color: #ffffff;
  border: 1px solid #dee2e6;
  cursor: pointer;
`;

const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentPage])
  
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  
  const lastPage = paginationRange[paginationRange.length - 1];

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  return (
    <List>
      <Item
        className={`${currentPage === 1 && "disabled"}`}
        onClick={onPrevious}
      >
        <i className="fa fa-chevron-left" aria-hidden="true"></i>
      </Item>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return <Item key={pageNumber}><i className="fa fa-ellipsis-h" aria-hidden="true"></i></Item>;
        }

        return (
          <Item
            key={pageNumber}
            className={`${pageNumber === currentPage && "active"} `}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </Item>
        );
      })}
      <Item
        className={`${currentPage === lastPage && "disabled"}`}
        onClick={onNext}
      >
        <i className="fa fa-chevron-right" aria-hidden="true"></i>
      </Item>
    </List>
  );
};

export default Pagination;
