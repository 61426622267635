import React from 'react';


function pages(props) {
  return (
    <h1 className="page-header">
        <i className={`fal ${props.icon || "fa-building"} mr-2 opacity-5 text-aqua`}></i>
        {props.title} <br />
        <small>
        {" "}
        <div className="mt-2" dangerouslySetInnerHTML={{__html: props.desc}}></div>
        </small>
    </h1>
  );
}

export default pages;