import React from 'react';
import { InputGroup, Label } from 'reactstrap';
import styled from "styled-components";
import { URL_TO_IMAGES } from '../../config/api.config';
import { moneyMask } from '../../uteis/mask';
import { Delete } from '../layout/buttons/Delete';

const List = styled.div`
  padding: 0;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;  
  margin-bottom: 1rem;
`;
const ListItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: relative;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.gray1};
  /* margin-bottom: 0.5rem; */
  border-radius: 10px;
  max-width: 390px;

  @media (max-width: 425px) {
    width: 100%;
  }
`;

const Form = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 2rem;

  > div {
    @media (max-width: 425px) {
      width: 100%;
      justify-content: space-between;
    }
  }
`;
const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  input {
    width: 5rem;
  }

  @media (max-width: 1024px) {
    > .form-group {
      width: 100%;
    }
  }
`;
const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  
  h3 {
    font-size: 1rem;
  }

  h6 {
    margin: 0;
    font-weight: 500;
  }

  > div {
    width: 150px;
  }

  > img {
    width: 75px;
  }
`;

const ButtonAction = styled.button`
  background: none;
  border: 1px solid lightgray;
  padding: 0;
  width: 34px;

  :hover {
    background-color: ${(props) => props.theme.colors.gray1};
  }
`
export const CarrinhoDelete = styled(Delete)`
  display: flex;
  gap: 0.5rem;

  /* > div {
    display: none;
  }
  @media (max-width: 1024px) {
    width: 100%;
    > div {
      display: block;
    }
  } */
`;
const ItemTotal = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-direction: column;

  > h4 {
    margin: 0;
  }
`;

const Variations = styled.div`
  border: 1px solid;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`
const VariationItemParent = styled.span`
  background: ${(props) => props.theme.colors.blue5};
  color: ${(props) => props.theme.colors.white};
  padding: 0 6px;
`
const VariationItemValue = styled.span`
  flex: 1;
  padding-right: 6px;
`

export const formatesVariations = (variationSelected) => {
  if (!variationSelected) {
    return;
  }
  const variations = (
    variationSelected?.products_variations
      ? variationSelected?.products_variations
      : variationSelected
  ).map((item) => (
    <Variations key={item.variation.parent.name + "-" + item.variation.name}>
      <VariationItemParent>{item.variation.parent.name}</VariationItemParent>
      <VariationItemValue>{item.variation.name}</VariationItemValue>
    </Variations>
  ));

  return variations;
};

const getImage = (item) => {
  if (item?.variationSelected) {
    const varImg = item?.variationSelected?.products_images.find(img => img.featured_image);
    return varImg ? `${URL_TO_IMAGES}${varImg?.path.replace('public', '')}` : "/assets/img/gallery/gallery-52.jpg";
  }
  return `${item.imagem || "/assets/img/gallery/gallery-52.jpg"}`
}


export function Listagem({ listagemItemsCompany, alteraQuantidade, remover, isOrder, disabled }) {
  return (
    <List>
      {listagemItemsCompany.map((item) => {
        return (
          <ListItem key={`${item.company_id} - ${item.id} - ${item?.variationSelected?.id}`}>
            <Form>
              <Header to={`/${item.tipo}/${item.id}`}>
                <img
                  src={getImage(item)}
                  alt="Foto do Item"
                />
                <div>
                  <h3 className="text-muted">{item.nome}</h3>
                  <h6 className="text-muted">
                    Código: {item?.code}
                  </h6>
                  <h6 className="text-muted">
                    Categoria: {item?.category?.name}
                  </h6>
                  <h6 className="text-muted">Marca: {item?.brand?.name}</h6>
                </div>
                {!isOrder ? (
                  <Delete
                    id={`delete-${item.id}`}
                    title="Excluír item"
                    type="button"
                    onClick={() => remover(item)}
                    isOrder={isOrder}
                    disabled={disabled}
                  >
                    <i className="fa fa-times"></i>
                  </Delete>
                ) : null}
              </Header>
              
              <div style={{
                display: "flex",
                flexWrap: "wrap",
                gap: ".5rem",
              }}>
                {formatesVariations(item?.variationSelected)}
              </div>

              <FormGroup className="mb-2">
                <div>
                  <Label for="unitprice">
                    Preço: {moneyMask(item.selectedPrice, true)}
                  </Label>
                  <InputGroup>
                    {/* <Label for="quantidade">Quantidade</Label> */}
                    {
                      !isOrder ? (
                        <ButtonAction
                          outline
                          color="secondary"
                          onClick={() => {
                            if (item.quantidade === 1) {
                              return;
                            }
                            alteraQuantidade({
                              ...item,
                              quantidade: item.quantidade - 1,
                            });
                          }}
                          disabled={disabled}
                        >
                          -
                        </ButtonAction>
                      ) : null
                    }
                    <div style={{
                      display: "flex",
                      flexDirection: "column"
                    }}>
                      {
                        isOrder ? (
                         "Quantidade:"
                        ) : null
                      }

                      <input
                        id="quantidade"
                        type="text"
                        name="Quantidade"
                        placeholder="Quantidade"
                        min={1}
                        value={item.quantidade}
                        onChange={(event) => {
                          const quantidade = Number(event.target.value);
                          alteraQuantidade({
                            ...item,
                            quantidade,
                          });
                        }}
                        disabled={isOrder || disabled}
                      />
                    </div>
                    {
                      !isOrder ? (
                        <ButtonAction
                          outline
                          onClick={() => {
                            alteraQuantidade({
                              ...item,
                              quantidade: item.quantidade + 1,
                            });
                          }}
                          disabled={disabled}
                        >
                          +
                        </ButtonAction>
                      ) : null
                    }
                  </InputGroup>
                </div>

                <ItemTotal>
                  Total:
                  <h4>{moneyMask(item.valorTotal, true)}</h4>
                </ItemTotal>
              </FormGroup>
            </Form>
          </ListItem>
        );
      })}
    </List>
  );
}
