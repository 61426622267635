import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Badge, Button } from "reactstrap";
import { URL_TO_IMAGES } from "../../../config/api.config";
import { formatedVariationsPrice } from "../../../uteis/formatedVariationsPrice";
import { mountFullAddress } from "../../../uteis/functions";
import { moneyMask } from "../../../uteis/mask";
import { AdicionarCarrinho } from "../../adicionarCarrinho";

const btns = (item) => {
  return (
    <div className="d-flex mb-2 justify-content-end" style={{ zIndex: 4 }}>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.updateActive(item);
        }}
        className="btn btn-warning btn-icon btn-circle btn-lg m-3"
        title={item.active === 1 ? "Desativar" : "Ativar"}
      >
        {item.active === 1 ? (
          <i className="fa fa-ban"></i>
        ) : (
          <i className="fas fa-play"></i>
        )}
      </Button>
      <Link
        to={`/editar/produto/${item.id}?name=${item.name}&desc=${item.description}`}
        className="btn btn-success btn-icon btn-circle btn-lg m-3"
        title="Editar"
      >
        <i className="fa fa-edit"></i>
      </Link>
      <Link
        to={`/cadastrar/produto/${item.id}?name=${item.name}&desc=${item.description}`}
        className="btn btn-primary btn-icon btn-circle btn-lg m-3"
        title="Copiar"
      >
        <i className="fa fa-clone"></i>
      </Link>
      <Link
        to={`/produto/${item.id}/variacao`}
        className="btn btn-info btn-icon btn-circle btn-lg m-3"
        title="Variações"
      >
        <i className="fas fa-boxes"></i>
      </Link>
      <Button
        onClick={(e) => {
          e.stopPropagation();
          item.setDeletar(item);
        }}
        className="btn btn-danger btn-icon btn-circle btn-lg m-3"
        title="Excluir"
      >
        <i className="fa fa-times"></i>
      </Button>
    </div>
  );
};

export function Item({ item, setarPosition }) {
  const { current_companie } = useSelector((state) => state.auth);
  const type = item.tipo;
  const companyId = item.company_id ?? item.company?.id;
  const isCurrentCompany = current_companie.id === item.company_id;

  const imagePath = item.images[0]?.path
    ? item.images
        .find((img) => img.featured_image === 1)
        ?.path.replace("public", "")
    : undefined;

  const variationImagePath = item?.variations
    ? item?.variations[0]?.products_images
        .find((img) => !!img.featured_image)
        ?.path.replace("public", "")
    : null;

  const image =
    imagePath || variationImagePath
      ? `${URL_TO_IMAGES}/${variationImagePath || imagePath}`
      : null;

  return (
    <>
      <Link
        onClick={() => setarPosition()}
        to={`/${type}/${item.id}`}
        className="result-image"
        style={{
          backgroundImage: `url(${
            image || "/assets/img/gallery/gallery-52.jpg"
          })`,
        }}
      ></Link>
      <div className="result-info">
        <h4 className="title">
          <Link onClick={() => setarPosition()} to={`/${type}/${item.id}`}>
            {item.nome}{" "}
            <small className="text-muted">
              Categoria: {item?.category?.name} | Marca: {item?.brand?.name}
            </small>
          </Link>
        </h4>
        <p className="location">
          {type === "servico" ? (
            <Badge className="bg-green">{"Serviço"}</Badge>
          ) : (
            <Badge className="bg-orange">{"Produto"}</Badge>
          )}
        </p>

        <div
          className="desc"
          dangerouslySetInnerHTML={{ __html: item.descricao }}
        ></div>

        <div className="btn-row">
          {item.estrelas && (
            <Link
              onClick={() => setarPosition()}
              to={`/${type}/${item.id}`}
              data-toggle="tooltip"
              data-container="body"
              data-title="Analytics"
            >
              <>
                <i className="far fa-star"></i> {item.estrelas}
              </>
            </Link>
          )}
          <Link
            onClick={() => setarPosition()}
            to={`/${type}/${item.id}`}
            data-toggle="tooltip"
            data-container="body"
            data-title="Tasks"
          >
            <i className="far fa-eye"></i> {item.hit || 0}
          </Link>
        </div>
        {item?.company && (
          <div className="text-muted mt-3">
            Fornecedor:
            <Link
              onClick={() => setarPosition()}
              to={`/empresa/${companyId}`}
              className="ml-2"
            >
              {item.company?.company_name ? item.company.company_name : ""}
            </Link>
            <br />
            <p className="text-muted mt-3">
              {mountFullAddress({
                address: item.address,
                neighborhood: item.neighborhood,
                complement: item.complement,
                city: item.city,
                uf: item.uf,
                postal_code: item.postal_code,
              })}
            </p>
          </div>
        )}
      </div>
      <div className="result-price" style={{ justifyContent: "space-between" }}>
        <div className="d-sm-block align-self-end align-self-sm-end">
          {item.price_type === "precification"
            ? item.variations_price
              ? formatedVariationsPrice(item.variations_price)
              : moneyMask(item.default_price, true)
            : null}
        </div>

        <div style={{ width: "100%" }}>
          <div className="d-sm-block align-self-end align-self-sm-end">
            {isCurrentCompany ? btns(item) : null}
          </div>

          {!isCurrentCompany &&
          type === "produto" &&
          (item?.price_type !== "cotation" ||
            (item?.price_type === "precification" &&
              !!item?.variations_price)) ? (
            <AdicionarCarrinho
              item={{
                ...item,
                selectedPrice: item?.variations_price
                  ? undefined
                  : item.default_price,
              }}
            />
          ) : null}

          {!isCurrentCompany && item?.price_type !== "precification" ? (
            <Link
              to={`/cotacao/cotar/${parseInt(item.company_id)}?produto=${
                item.id
              }`}
              className="btn btn-outline-primary btn-block"
            >
              <i className="fa fa-plus mr-2"></i>
              Adicionar a Cotação
            </Link>
          ) : null}

          <Link
            onClick={() => setarPosition()}
            to={`/${type}/${item.id}`}
            className="btn btn-yellow btn-block"
          >
            Ver produto
          </Link>
        </div>
      </div>
    </>
  );
}
