import React, { memo, useCallback, useContext, useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router";
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
  Row,
} from "reactstrap";
import Loader from "../../components/loader";
import TitlePage from "../../components/pages/title";
import { PageSettings } from "../../config/page-settings";
import {
  getDataRequested,
  setPageRequested,
  setPageSuccess,
  setTypeRequested,
} from "../../store/pesquisa/action";
import ModalFiltro from "./parts/modalFiltro";
import Result from "./parts/result";

const Home = (props) => {
  const [modalFiltro, setModalFiltro] = useState(false);
  const [splitButtonOpen, setSplitButtonOpen] = useState(false);
  const [deletar, setDeletar] = useState(false);
  const contextType = useContext(PageSettings);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [countFilters, setCountFilters] = useState(null);

  const {
    search: { params, currentPage, ...dados },
    auth: { current_companie },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const toggleSplit = () => {
    setSplitButtonOpen(!splitButtonOpen);
  };
  const confirmar = () => {
    setDeletar(false);
  };
  const deletarItem = () => {
    setDeletar(false);
  };

  const setarPosition = () => {
    var doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    localStorage.setItem("top-serach", top);
  };
  const toggleModalFiltro = () => {
    setModalFiltro(!modalFiltro);
  };

  const pegaResultados = useCallback(async (newParams = {}) => {
    dispatch(
      getDataRequested({
        ...params,
        ...newParams,
      })
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, params, currentPage]);

  useEffect(() => {
    const filters = [
      "atividade",
      "tipo",
      "pais",
      "uf",
      "cidade",
    ]
    setCountFilters(filters.filter(filter => params[filter])?.length);
  }, [params])

  useEffect(() => {
    setSearchKeyword(params?.keyword || '');
    pegaResultados({ pesquisar: params?.pesquisar, page: 1 });
  }, [params.keyword, params.pesquisar, pegaResultados]);

  useEffect(() => {
    if (dados.result) {
      window.scrollTo(0, localStorage.getItem("top-serach"));
    }
    contextType.closeMobileSiderBar();

    return () => {
      setarPosition();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setType = (type) => {
    dispatch(setTypeRequested({ ...params, pesquisar: type }));
    dispatch(setPageRequested(1));
  };
  const handleKeyword = (keyword) => {
    dispatch(setTypeRequested({ ...params, keyword }));
  }
  const setPage = (page) => {
    dispatch(setPageSuccess(page));
  }

  return (
    <>
      {deletar && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Sim, deletar!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Você tem certeza?"
          onConfirm={confirmar}
          onCancel={deletarItem}
        >
          Após essa ação Você não conseguira recuperar os dados.
        </SweetAlert>
      )}
      <Loader loading={dados.loading} />

      <TitlePage
        title="Pesquisa avançada"
        desc="Encontre Fornecedores, Produtos, Serviços e Compradores"
        icon="fab fa-simplybuilt"
      />
      <div className="row">
        {modalFiltro && (
          <ModalFiltro
            modal={modalFiltro}
            toggle={toggleModalFiltro}
          />
        )}
        <div className="col-md-12">
          <Row className="align-items-start mt-3 filtros-pesquisa">
            <Col md="8" lg="8" xl="10">
              <InputGroup size="lg" className="m-b-20">
                <Input
                  placeholder=""
                  type="text"
                  className="input-white"
                  value={searchKeyword}
                  onChange={(e) => setSearchKeyword(e.target.value)}
                />
                <InputGroupButtonDropdown
                  addonType="append"
                  isOpen={splitButtonOpen}
                  toggle={toggleSplit}
                >
                  <Button
                    color="success"
                    onClick={() => handleKeyword(searchKeyword)}
                  >
                    <i className="fa fa-search fa-fw"></i>
                  </Button>
                  {params?.keyword && (
                    <Button
                      color="danger"
                      onClick={() => {
                        setSearchKeyword("")
                        handleKeyword("");
                      }}
                    >
                      <i className="fa fa-times fa-fw"></i>
                    </Button>
                  )}
                </InputGroupButtonDropdown>
              </InputGroup>
            </Col>
            <Col md="4" lg="4" xl="2" className="container-botao-pesquisa">
              <button
                className="btn btn-yellow mb-3 btn-lg btn-block text-truncate"
                onClick={toggleModalFiltro}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                Filtros
                {
                  countFilters && countFilters > 0 ? (
                    <span
                      style={{
                        background: "#0a243c",
                        padding: "0px 10px",
                        borderRadius: "10px",
                        color: "white",
                        position: "absolute",
                        top: "-10px",
                        right: "10px",
                      }}
                    >
                      {countFilters}
                    </span>
                  ) : ""
                }
              </button>
            </Col>

            <Col className="form-group d-flex pesquisa-por">
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="fornecedor"
                  onChange={() => {
                    setType("fornecedor");
                  }}
                  checked={params?.pesquisar === "fornecedor"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="fornecedor">
                  Fornecedores
                </label>
              </div>

              { current_companie.profile !== '2' ? (
                <div className="checkbox checkbox-css mr-2">
                  <input
                    name="tipo-pesquisa"
                    type="radio"
                    id="clientes"
                    onChange={() => {
                      setType("clientes");
                    }}
                    checked={params?.pesquisar === "clientes"}
                  />
                  <label style={{ paddingLeft: "20px" }} htmlFor="clientes">
                    Compradores
                  </label>
                </div>
              ) : null }

              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="produtos"
                  onChange={() => {
                    setType("produtos");
                  }}
                  checked={params?.pesquisar === "produtos"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="produtos">
                  Produtos
                </label>
              </div>
              <div className="checkbox checkbox-css mr-2">
                <input
                  name="tipo-pesquisa"
                  type="radio"
                  id="servicos"
                  onChange={() => {
                    setType("servicos");
                  }}
                  checked={params?.pesquisar === "servicos"}
                />
                <label style={{ paddingLeft: "20px" }} htmlFor="servicos">
                  Serviços
                </label>
              </div>
            </Col>
          </Row>
          {/* */}
          <Result
            setarPosition={setarPosition}
            pegaResultados={pegaResultados}
            params={params}
            resultados={dados}
            currentPage={currentPage}
            setPage={setPage}
          />
        </div>
      </div>
    </>
  );
};

export default memo(withRouter(Home));
