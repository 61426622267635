import React, { useMemo, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { clearMask, moneyMask } from "../../../uteis/mask";
import { status } from "../../../uteis/orders";
import { InputField, MontarCampos } from "./MontarCampos";

const PaymentVoucher = styled.div`
  padding: .5rem;
  border-radius: 5px;
  border: 1px solid red;
  border-color: ${props => props.comprovantePagamento ? props.theme.colors.success : props.theme.colors.danger} ;
  label {
    line-height: normal;
  }
`
const TotalValue = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  text-align: right;
  > strong:last-child {
    font-size: 1rem;
  }
`

export function ModalAceitar({ title, open, order, setOrder, aprovar, adicionarComprovante, currentCompanieId }) {
  const [detalhesPagamento, setDetalhesPagamento] = useState();
  const [comprovantePagamento, setComprovantePagamento] = useState();
  const [valorEntrega, setValorEntrega] = useState(order.delivery_address.value || null);

  const valorTotalComEntrega = useMemo(() => {
    return moneyMask(order.total_value + Number(clearMask(valorEntrega || 0, true)), true);
  }, [order.total_value, valorEntrega]);

  const isSupplier = order.supplier_id === currentCompanieId

  return (
    <Modal isOpen={open} toggle={() => setOrder(null)} size="sm" centered>
      <ModalBody>
        {title}
        <div>
          <strong>{order.payment.name}</strong>
          <MontarCampos
            fields={order.payment.fields}
            values={order.payment.values}
            detalhesPagamento={detalhesPagamento}
            setDetalhesPagamento={setDetalhesPagamento}
          />
          {
            isSupplier && order.current_status.name === status.created ?
              <>
                <strong>Valor da entrega</strong>
                <InputField
                  name="valor-entrega"
                  type="text"
                  placeholder="Valor da entrega"
                  value={valorEntrega}
                  onChange={(e) => setValorEntrega(moneyMask(e.target.value))}
                  style={{ width: "100%" }}
                />
                
              </>
            : null
          }
          {
            valorTotalComEntrega ? 
              <TotalValue>
                <strong>Valor total do pedido com a entrega:</strong>
                <strong>{valorTotalComEntrega}</strong>
              </TotalValue>
             : null
          }
        </div>
        {
          !isSupplier && order.current_status.name === status.approved ?
            <PaymentVoucher comprovantePagamento={comprovantePagamento}>
              <label htmlFor="comprovante-pagamento">
                <strong>Por favor, anexar o comprovante de pagamento a baixo:</strong>
              </label>
              <input
                name="comprovante-pagamento"
                type="file"
                placeholder="Comprovante de pagamento"
                onChange={(e) => {
                  setComprovantePagamento(e.target.files)
                }}
                style={{ width: "100%" }}
                accept="image/*,.pdf"
              />
            </PaymentVoucher>
          : null
        }
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => setOrder(null)}>
          Fechar
        </Button>
        <Button
          onClick={() => {
            if (comprovantePagamento) {
              adicionarComprovante({
                id: order.id,
                // payment_info: detalhesPagamento,
                payment_voucher: comprovantePagamento,
              })
            } 
            if (detalhesPagamento && !comprovantePagamento) {
              aprovar({
                id: order.id,
                payment_info: detalhesPagamento,
                delivery_value: clearMask(valorEntrega, true),
                status: status.approved,
              });
            }
            setOrder(null);
          }}
          disabled={
            (order.customer_id === currentCompanieId && !comprovantePagamento) ||
            (order.supplier_id === currentCompanieId && !detalhesPagamento )
          }
        >
          Aceitar
        </Button>
      </ModalFooter>
    </Modal>
  );
}
