import React, { useContext, useEffect, useRef, useState } from "react";
import Loader from "../../components/loader";

import lottie from "lottie-web";
import animation from "../../assets/json/gift2";
import { PageSettings } from "../../config/page-settings";


import { Col, Row } from "reactstrap";
import TitlePage from "../../components/pages/title";
import {
  Panel,
  PanelBody,
  PanelHeader,
} from "../../components/panel/panel.jsx";

const Assinatura = () => {
  const [loading, setLoading] = useState(true);
  const contextType = useContext(PageSettings);
  const container = useRef(null)

  useEffect(() => {
    window.scrollTo(0, 0)
    contextType.closeMobileSiderBar()
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: animation
    })
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      contextType.handleSetPageContentFullHeight(false);
      contextType.handleSetPageContentFullWidth(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  return (
    <>
      <Loader loading={loading} />
      <TitlePage icon="far fa-comments" title="Assinatura" desc="" />
      <Row>
        <Col sm={12}>
          <Panel theme="default">
            <PanelHeader>Sua assinatura</PanelHeader>
            <PanelBody>
              <h1 className="text-primary bold title"> Sempre Gratuito!</h1>
              <p className="text-black-50">
                Seu plano: <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong>.
              </p>
              <p className="text-black-50">
                Estamos preparando novidades emocionantes para o futuro! Em breve, iremos implementar dois novos planos, repletos de recursos adicionais e benefícios exclusivos, para aqueles que desejam um pouco mais.
              </p>
              <p className="text-black-50">
                Mas não se preocupe! O <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong> continuará disponível, permitindo que você continue utilizando a plataforma sem custo algum, mesmo após a introdução dos planos pagos.
              </p>
              <p className="text-black-50">
                Nosso objetivo é garantir que todos os usuários tenham uma experiência incrível no <strong>Achei Fornecedor</strong>, independentemente do plano escolhido.<br />
                Esperamos que você aproveite ao máximo a plataforma, seja no <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong> ou nos futuros planos pagos.
              </p>
                <h1 className="text-primary bold title"> Sempre Gratuito!</h1>
                <p className="text-black-50">
                Seu plano: <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong>.
                </p>
                <p className="text-black-50">
                  Estamos preparando novidades emocionantes para o futuro! Em breve, iremos implementar dois novos planos, repletos de recursos adicionais e benefícios exclusivos, para aqueles que desejam um pouco mais.
                </p>
                <p className="text-black-50">
                  Mas não se preocupe! O <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong> continuará disponível, permitindo que você continue utilizando a plataforma sem custo algum, mesmo após a introdução dos planos pagos.
                </p>
                <p className="text-black-50">
                  Nosso objetivo é garantir que todos os usuários tenham uma experiência incrível no <strong>Achei Fornecedor</strong>, independentemente do plano escolhido.<br />
                  Esperamos que você aproveite ao máximo a plataforma, seja no <strong style={{ color: '#1a237e' }}>Plano Gratuito</strong> ou nos futuros planos pagos.
                </p>
                  {/* 
                  <ul className="pricing-table pricing-col-4">
                    <li data-animation="true" >
                      <div className="pricing-container">
                        <h3>Starter</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">Grátis</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-inverse btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                    <li data-animation="true" >
                      <div className="pricing-container">
                        <h3>Basic (atual)</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">R$9.99</span>
                            <span className="price-tenure">(plano atual gratuido até 22/03/2021)</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>todos os beneficios do plano grátis</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-inverse btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                    <li className="highlight" >
                      <div className="pricing-container">
                        <h3>Premium (Recomendamos)</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">$19.99</span>
                            <span className="price-tenure text-inverse text-white">per month</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>todos os beneficios do plano basic</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-orange btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                    <li data-animation="true">
                      <div className="pricing-container">
                        <h3>Super Premium</h3>
                        <div className="price">
                          <div className="price-figure">
                            <span className="price-number">R$120.00</span>
                          </div>
                        </div>
                        <ul className="features">
                          <li>todos os beneficios do plano premium</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                          <li>Beneficio 1</li>
                        </ul>
                        <div className="footer">
                          <a href="#" className="btn btn-inverse btn-theme btn-block">Escolher</a>
                        </div>
                      </div>
                    </li>
                  </ul>
                  */}
              <div ref={container}></div>

            </PanelBody>
          </Panel>
        </Col>
      </Row>
    </>

  );
};

export default Assinatura;
