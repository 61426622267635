import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Alert, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import { deletarConta } from "../../../services/auth/user";
import { logOut } from "../../../store/auth/action";

import { toast, ToastContainer } from "react-toastify";

const ModalDeletar = ({modal, toggle, history, loading}) => {
  const dispatch = useDispatch();
  const avisar = async (token) => {

    setDisabled(true)

    try {
      const result = await deletarConta(token)
      if (result) {
        toast.success(
          "Sua conta foi deletada com sucesso.",
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false
          }
        )
        setTimeout(() => {
          dispatch(logOut(history));
        }, 2000);
      }
    } catch (err) {      
      toast.error(
        "Erro ao tentar deletar a conta, verifique o token enviado",
        {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false
        }
      )
    }
  }
  const [cod, setCod] = useState("")
  const [disabled, setDisabled] = useState(true)

  useEffect(()=>{
    setCod("")
    setDisabled(true)
  }, [modal])

  useEffect(()=>{
    if(cod.length > 4){
        setDisabled(false)
    }else{
        setDisabled(true)
    }
  }, [cod])

  return (
    <>
      <ToastContainer/>
      <Modal isOpen={modal} toggle={toggle}  size="lg" centered>
        <ModalHeader toggle={toggle}>Deletar conta</ModalHeader>
        <ModalBody>
        {loading ?
          <div className="text-center">
            <Spinner size="large" />
          </div>
          
        :
        <Alert color="danger">
            <h3>Confirmar ação.</h3>
            <p>digite o código de validação que foi enviado ao seu e-mail.</p>
            <Input color="danger" placeholder={"Código de validação"} value={cod} onChange={(e)=> setCod(e.target.value)}/>

        </Alert>
        }

        </ModalBody>
        <ModalFooter>
          <Button block color="danger" disabled={disabled} onClick={()=>avisar(cod)}>Confirmar</Button>
          <Button block color="secondary" onClick={toggle}>Cancelar</Button>

        </ModalFooter>
      </Modal>
    </>
  );
}

export default withRouter(ModalDeletar);
