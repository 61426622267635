import React from "react";
import styled from "styled-components";
import { Header, Listagem } from "../../components/pedidos";
import { TagDanger } from "../../components/tag";
import { status } from "../../uteis/orders";
import { BasePedidos } from "./components/BasePedidos";
import { PedidoContainer } from "./components/PedidoContainer";
import { usePedidos } from "./hooks/usePedidos";

const MotivoCancelamento = styled(TagDanger)`
  h6 {
    color: ${(props) => props.theme.colors.white};
    margin: 0;
  }
`

export function Cancelados({ clientes }) {
  const {
    loading: loadingPedidos,
    pedidos,
  } = usePedidos({
    type: status.canceled,
    getCustomer: !!clientes,
  });

  return (
    <BasePedidos
      titulo="Pedidos Cancelados"
      descricao="Aqui você gerencia os pedidos cancelados."
      loading={loadingPedidos}
    >
      {pedidos.length ? (
        pedidos.map((pedido) => (
          <PedidoContainer key={pedido.id}>
            <Header
              titulo={pedido?.items.find((item) => item.company?.company_name).company.company_name}
              numeroPedido={pedido.id}
              data={pedido.created_at}
            />
            <MotivoCancelamento>
              <h6>
                Motivo: {pedido?.status_history?.find((item) => item.status_id === pedido.current_status.id)?.description ?? 'Não específicado'}
              </h6>
            </MotivoCancelamento>
            <Listagem
              listagemItemsCompany={pedido.items}
              // updateCarrinhoQuantidade={updateCarrinhoQuantidade}
              // remover={cancelar}
              isOrder
              status={status.canceled}
            />
          </PedidoContainer>
        ))
      ) : (
        <h2>Nenhum pedido feito...</h2>
      )}
    </BasePedidos>
  );
}
