import lottie from "lottie-web";
import React, { useEffect, useRef, useState } from 'react';
import { Link } from "react-router-dom";
import { Card, CardBody, Col } from "reactstrap";
import animationInfo from "../../assets/json/info";
import animation from "../../assets/json/search";
import { getLastOrders } from "../../services/order";
import { LastOrdersCard } from "./components/lastOrdersCard";

export function DashboardInfoCards({ fornecedor, info }) {
  const container = useRef(null)
  const container2 = useRef(null)

  const [myLastOrders, setMyLastOrders] = useState([]);
  const [clietLastOrders, setClientLastOrders] = useState([]);

  const handleLastOrders = async () => {
    setMyLastOrders(await getLastOrders(0)
      .then((response) => {
        return response.data.resposta.conteudo.orders;
      })
      .catch(() => []));
    setClientLastOrders(await getLastOrders(1)
      .then((response) => {
        return response.data.resposta.conteudo.orders;
      })
      .catch(() => []));
  }

  useEffect(() => {
    handleLastOrders()
  }, [])

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: animation
    })
    lottie.loadAnimation({
      container: container2.current,
      renderer: "svg",
      autoplay: true,
      animationData: animationInfo
    })
  }, [])

  return (
    <React.Fragment>
      <Col md="6" lg="6">
        <Card className="mt-3">
          <CardBody>
            <h4 className="card-title mb-0">
              {fornecedor ? "Encontre um comprador" : "Encontre um fornecedor"}
            </h4>
            <p className="text-muted">
              Aproveite nossa plataforma avançada de pequisa
            </p>

            <div className="mt-4">
              <div className="d-flex justify-content-center">
                <div style={{ maxWidth: 266 }} ref={container}></div>
              </div>
            </div>
          </CardBody>

          <div className="card-footer bg-transparent border-top">
            <div className="text-center">
              <Link
                to="/procurar/avancada"
                className="btn btn-dark waves-effect waves-light"
              >
                {" "}
                Encontre {fornecedor ? "compradores" : "fornecedores"}
              </Link>
            </div>
          </div>
        </Card>
      </Col>
      <Col md="6" lg="6">
        <Card className="mt-3">
          <CardBody>
            <h4 className="card-title mb-0">
              {info.signUpCompleted
                ? "Atualize seu cadastro"
                : "Finalize seu cadastro"}
            </h4>
            <p className="text-muted">Ajude a ser encontrado na plataforma</p>
            <div className="mt-4">
              <div
                className="d-flex justify-content-center"
                style={{ maxHeight: "280px", overflowY: "scroll" }}
              >
                <div style={{ maxWidth: 320 }} ref={container2}></div>
              </div>
            </div>
          </CardBody>

          <div className="card-footer bg-transparent border-top">
            <div className="text-center">
              <Link
                to="/cadastroEmpresa"
                className="btn btn-dark waves-effect waves-light"
              >
                {info.signUpCompleted
                  ? "Atualize seu cadastro"
                  : "Finalizar cadastro"}{" "}
              </Link>
            </div>
          </div>
        </Card>
      </Col>

      {fornecedor ? (
        <Col md="6" lg="6">
          <LastOrdersCard
            title="Pedidos recebidos"
            subtitle="Meus 5 últimos pedidos recebidos aparecem aqui"
            tableData={clietLastOrders}
            client
          />
        </Col>
      ) : null}
      <Col md="6" lg="6">
        <LastOrdersCard
          title="Pedidos feitos"
          subtitle="Meus 5 últimos pedidos feitos aparecem aqui"
          tableData={myLastOrders}
        />
      </Col>
    </React.Fragment>
  );

}
