import React from "react";
import ItemProduto from "../../../components/itemProcurar/multiplos";
import PaginationV2 from "../../../components/paginate/paginationV2";

const Result = ({
  setarPosition,
  loading,
  resultados,
  params,
  pegaResultados,
  currentPage,
  setPage,
}) => {
  return (
    <div className="result-container">
      <ul className="result-list">
        {resultados && resultados?.result?.data.length >= 1 ? (
          resultados?.result.data?.map((item, index) => {
            return (
              <ItemProduto
                key={index}
                setarPosition={setarPosition}
                item={{
                  tipo: item.tipo,
                  nome: item.name || item.company_name || "",
                  estrelas: item.avg,
                  visualizacoes: 234,
                  categoria: item.categoria || "",
                  descricao: item.description || "",
                  id: item.id,
                  ...item,
                }}
              />
            );
          })
        ) : (
          <h2>Nenhum resultado...</h2>
        )}
      </ul>
      {loading && (
        <p className="text-center mb-5 mt-2">
          <i className="fas fa-circle-notch fa-spin  mr-1"></i>Carregando...
        </p>
      )}

      <div className="m-t-20">
        {resultados?.result?.data?.length >= 1 ?
          <PaginationV2
            onPageChange={(page) => pegaResultados({page})}
            totalCount={resultados.result?.total}
            siblingCount={1}
            currentPage={resultados.result?.current_page}
            pageSize={resultados.result?.per_page}
          />
        : null}
      </div>
    </div>
  );
};

export default Result;
