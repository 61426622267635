import React, { memo } from "react";
import ItemSevico from "../../components/servico";
import SkeletonItem from "../../components/skeleton/skeleton-item";
const ListarServico = ({listarSevico, setDeletar, updateActive})=>{
    
    if(!listarSevico) return null

    return(
        <ul className="result-list">
        {
            listarSevico ? (
                listarSevico.map((servico => {
                    return <ItemSevico
                        key={servico.id}
                        isUser={true}
                        servico={servico}
                        setDeletar={setDeletar}
                        updateActive={updateActive}
                    />
                }))
            ) : (
                    <>
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                        <SkeletonItem />
                    </>
                )
        }
    </ul>
    )
}

export default memo(ListarServico)
