import React, { useContext, useEffect, useState } from "react";
import Loader from "../../components/loader";

import SweetAlert from 'react-bootstrap-sweetalert';
import { store } from "react-notifications-component";
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  InputGroup,
  InputGroupButtonDropdown,
} from "reactstrap";
import TitlePage from "../../components/pages/title";
import Pagination from '../../components/paginate/pagination';
import { BASE_URL_SERVICES } from "../../config/api.config";
import { PageSettings } from "../../config/page-settings";
import { createService, deleteService, getServices } from '../../services/service';
import ListarServicoComponent from "./listarServico";
const avisar = (title, msg, type) => {
    store.addNotification({
        title: title || "Usuário ou senha incorretos",
        message: msg || "Um código de verificação foi enviado para o seu email!",
        type: type || "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
            duration: 5000,
            onScreen: true
        }
    });
}

const Home = () => {
    const [loading, setLoading] = useState(true)
    const [splitButtonOpen, setSplitButtonOpen] = useState(false)
    const contextType = useContext(PageSettings)
    const [deletar, setDeletar] = useState(false)
    const [listaServicos, setListaServicos] = useState([])

    const [searchname, setSearchname] = useState("")

    const toggleSplit = () => {
        setSplitButtonOpen(!splitButtonOpen);
    }

    const getServicos = async (currentUrl = null) => {
        setLoading(true)

        await getServices({ name: searchname, page: currentUrl }).then(response => {
            setListaServicos({
                currentUrlServices: response.request.responseURL,
                ...response.data.resposta.conteudo.services
            })
        })
            .catch(error => {
                if (error && error.response?.data?.resposta?.conteudo?.messages) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }

                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
            })
    }


    const updateActive = async (values) => {
        setLoading(true)
        const formdatServico = new FormData()
        formdatServico.append("_method", "put")

        const valueNotSendToBack = [
            'category',
            'brand',
            'unit_measurement',
            'created_at',
            'updated_at',
            'deleted_at',
            'images',
            'active'
        ]

        Object.keys(values).forEach(key => {
            if (valueNotSendToBack.includes(key)) {
                return
            }
            formdatServico.append([key], values[key])
        })

        const newActive = !values.active ? 1 : 0;
        formdatServico.append("active", newActive)

        return await createService(formdatServico, values.id)
            .then(_ => {
                getServicos()
                store.addNotification({
                    title: "Serviço atualizado com sucesso!",
                    message: `Serviço ${!values.active ? 'ATIVADO' : 'DESATIVADO'} com sucesso`,
                    type: "success",
                    insert: "top",
                    container: "top-right",
                    animationIn: ["animated", "fadeIn"],
                    animationOut: ["animated", "fadeOut"],
                    dismiss: {
                        duration: 5000,
                        onScreen: true
                    }
                })
            })
    }

    useEffect(() => {
        getServicos()
        window.scrollTo(0, 0)
        contextType.closeMobileSiderBar()
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const confirmar = async () => {
        setLoading(true)
        await deleteService(deletar.id)
            .then(response => {
                getServicos()
                avisar("Sucesso!", response.data.resposta.conteudo.message, "success")
            })
            .catch(error => {
                if (error && error.response?.data) {
                    const messages = error.response.data.resposta.conteudo.messages
                    Object.keys(messages).forEach(key => {
                        messages[key].forEach(message => {
                            avisar("Ops!!", message, "danger")
                        })
                    })
                    return
                }
                avisar("Ops!!", "Erro interno no servidor!", "danger")
            })
            .finally(() => {
                setLoading(false)
                setDeletar(null)
            })
    }
    const deletarItem = () => {
        console.log("confirmar")
        setDeletar(false)
    }
    useEffect(() => {
        if (!searchname) {
            getServicos()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchname])
    return (
        <>
            {(deletar &&
                <SweetAlert danger showCancel
                    confirmBtnText="Sim, deletar!"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title="Você tem certeza?"
                    onConfirm={confirmar}
                    onCancel={deletarItem}
                >
                    Após essa ação Você não conseguira recuperar os dados.
                </SweetAlert>
            )}
            <Loader loading={loading} />
            <TitlePage title="Serviços" desc="Edite e cadastre todos os seus serviços." icon="fal fa-box" />
            <div className="row">
                <div className="col-md-12">
                    <div className="result-container">
                        <InputGroup size="lg" className="m-b-20">
                            <Input
                                placeholder="Pesquise o seu serviço..."
                                type="text"
                                className="input-white"
                                value={searchname}
                                onChange={e => setSearchname(e.target.value)}
                            />
                            <InputGroupButtonDropdown
                                addonType="append"
                                isOpen={splitButtonOpen}
                                toggle={toggleSplit}
                            >
                                <Button color="primary" onClick={() => getServicos()}>
                                    <i className="fa fa-search fa-fw"></i>
                                </Button>
                                {
                                    searchname && (
                                        <Button color="danger"
                                            onClick={() => {
                                                setSearchname("")
                                            }}
                                        >
                                            <i className="fa fa-times fa-fw"></i>
                                        </Button>
                                    )
                                }
                                <Button color="success" tag={Link} to="/cadastrar/servico" >
                                    <i className="fa fa-plus fa-fw"></i>
                                </Button>
                            </InputGroupButtonDropdown>
                        </InputGroup>
                        <ListarServicoComponent
                            listarSevico={listaServicos.data}
                            setDeletar={setDeletar}
                            updateActive={updateActive}
                        />

                        <div className="clearfix m-t-20">
                            <Pagination
                                firstPageUrl={listaServicos.first_page_url}
                                lastPageUrl={listaServicos.last_page_url}
                                nextPageUrl={listaServicos.next_page_url}
                                previousPageUrl={listaServicos.prev_page_url}

                                perPage={listaServicos.per_page}
                                lastPage={listaServicos.last_page}
                                currentPage={listaServicos.current_page}
                                currentUrl={listaServicos.currentUrlProducts}
                                path={listaServicos.path}

                                {...listaServicos}

                                baseUrl={BASE_URL_SERVICES}
                                getItems={getServicos}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;
