import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";

function VariationField({ index, variacoes, valores, setValores }) {
  const [formVariacao, setFormVariacao] = useState({
    variacaoId: null,
    valorVariacaoId: null,
  });
  const [variacao, setVariacao] = useState(null);
  const [variacoesUsadasPais, setVariacoesUsadasPais] = useState([]);

  const handleOption = (event) => {
    const variacaoJson = variacao?.options.find(
      (vari) => vari.id === Number(event.target.value)
    );
    const newVariations = valores.variations;
    newVariations[valores.variations.length - 1] = variacaoJson;

    setValores({ ...valores, variations: newVariations });
  };

  useEffect(() => {
    setVariacoesUsadasPais(
      valores.variations.map((vari) =>
        !!vari.variation ? vari.variation.parent_id : vari.parent_id
      )
    );
  }, [valores.variations]);

  useEffect(() => {
    if (valores.edit) {
      const prodVari = valores.variations[index];

      if (!!prodVari?.id) {
        const currentVari = variacoes.find(
          (vari) => vari.id === prodVari.variation.parent_id
        );

        setVariacao(currentVari);
        setFormVariacao({
          variacaoId: prodVari.variation.parent_id,
          valorVariacaoId: prodVari.variation.id,
        });
      }
    }
  }, [index, valores.edit, valores.variations, variacoes]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#8080800f",
        padding: "10px",
        borderRadius: "5px",
        marginBottom: "10px",
        border: "1px solid #aaaaaa",
      }}
    >
      <div className="form-group">
        <label className="opacity-5 mb-1">Variação</label>
        <select
          className="form-control rounded"
          name="variacao"
          onChange={(e) => {
            if (e.target.value) {
              setFormVariacao(Number(e.target.value));
              setVariacao(
                variacoes.find((vari) => vari.id === Number(e.target.value))
              );
              return;
            }
            setVariacao(null);
          }}
          value={formVariacao.variacaoId}
        >
          <option value={0}>Selecione uma variação</option>
          {variacoes &&
            variacoes.map((vari) => {
              const emUso = variacoesUsadasPais.includes(vari.id);
              return (
                <option
                  key={vari.id}
                  value={vari.id}
                  disabled={!!emUso}
                  style={{
                    background: emUso ? "#e9ecef" : "#ffffff",
                  }}
                >
                  {vari.name}
                </option>
              );
            })}
        </select>
      </div>
      <div className="form-group">
        <label className="opacity-5 mb-1">Valor</label>
        <select
          className="form-control rounded"
          name="valor"
          onChange={handleOption}
          value={formVariacao.valorVariacaoId}
          disabled={!variacao}
        >
          <option>Selecione um valor</option>
          {variacao &&
            variacao?.options &&
            variacao.options.map((vari) => (
              <option key={vari.id} value={vari.id}>
                {vari.name}
              </option>
            ))}
        </select>
      </div>

      {valores.variations.length > 1 ? (
        <Button
          color="danger"
          onClick={() => {
            setValores({
              ...valores,
              variations: valores.variations.filter((_, idx) => idx !== index),
            });
          }}
        >
          Remover
        </Button>
      ) : null}
    </div>
  );
}

export { VariationField };
