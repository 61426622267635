import React from "react";
import "./style.sass";

function SwitchButton({
    handleChange,
    rounded,
    checked
}) {
  const hanldeCheckbox = () => {
    handleChange(!checked)
  }

  return (
    <label className="switch">
      <input
        type="checkbox"
        onChange={hanldeCheckbox}
        checked={checked}
        defaultValue={checked}
      />
      <span className={`slider ${rounded && "round"}`}></span>
    </label>
  );
}

export { SwitchButton };
