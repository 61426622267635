import React from "react";
import { Link } from "react-router-dom";
import { Badge } from "reactstrap";
import styled from "styled-components";
import { URL_TO_IMAGES } from "../../config/api.config";
import { notification } from "../../uteis/notifications";

const TagDiscount = styled.div`
  display: flex;
  font-size: 12px;
  background: ${(props) => props.theme.colors.blue5};
  position: absolute;
  z-index: 1;
  max-width: 250px;
  top: 0;
  right: -5px;
  border-radius: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 100%;
  color: ${(props) => props.theme.colors.gray1};
`;
const IconTagDiscount = styled.button`
  display: flex;
  justify-content: center;
  font-size: 1rem;
  border: none;
  align-items: center;
  width: ${(props) => props.hiddenTagName ? '40px' : '30px'};
  height: 30px;
  background: ${(props) => props.theme.colors.danger};
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: ${(props) => props.hiddenTagName ? '5px' : 0};
  border-bottom-right-radius: ${(props) => props.hiddenTagName ? '5px' : 0};
  &:hover {
    background: ${(props) => props.theme.colors.danger2};
  }
`;
const ContentTagDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 606;
`;

export const DeleteTag = ({
  handleRemoveDiscount,
  tagPortfolioId,
  getItems,
  tagName,
  discount,
  hiddenTagName,
}) =>
  hiddenTagName ? (
    <IconTagDiscount
      type="button"
      title="Excluir discount"
      onClick={() => handleRemoveDiscount(tagPortfolioId, getItems)}
      hiddenTagName
    >
      <i className="fa fa-times"></i>
    </IconTagDiscount>
  ) : (
    <TagDiscount>
      <IconTagDiscount
        type="button"
        title="Excluir discount"
        onClick={() => handleRemoveDiscount(tagPortfolioId, getItems)}
      >
        <i className="fa fa-times"></i>
      </IconTagDiscount>
      <ContentTagDiscount>
        <i className="mr-1 fal fa-tag"></i> {tagName} {discount}%
      </ContentTagDiscount>
    </TagDiscount>
  );

const ItemProcurar = ({
  item,
  type,
  naoSeguir,
  getItems,
  addDiscount,
  tagPortfolioId,
  tags,
  tagName,
  discount,
  setAlertData,
  loadData,
  handleRemoveDiscount,
  setLoading,
}) => {
  const hasDiscount = type !== "fornecedor" && tags.length && tagName && discount;
  return (
    <li>
      {hasDiscount ? (
        <DeleteTag {...{
          handleRemoveDiscount, tagPortfolioId, getItems, tagName, discount
        }}/>
      ) : null}
      <Link
        to={`/empresa/${item.company_id}`}
        className="result-image result-image-empresa"
      >
        <img
          className="rounded-corner"
          src={
            item?.company_logo
              ? URL_TO_IMAGES + "/" + item?.company_logo.replace("public", "")
              : "/assets/img/gallery/gallery-52.jpg"
          }
          alt="Imagem do Logotipo"
        />
      </Link>
      <div className="result-info">
        <h4 className="d-flex title">
          <Link to={`/empresa/${item.company_id}`}>
            {item?.company_name}
          </Link>
        </h4>
        <Badge className="">
          {type === "fornecedor" ? "fornecedor" : "cliente"}
        </Badge>

        <div className="btn-row">
          <button
            className="btn btn-black btn-circle mt-3"
            onClick={() => naoSeguir(item.id, getItems)}
          >
            Remover
          </button>
        </div>
      </div>
      <div className="result-price">
        {!!tags && !hasDiscount ? (
            <select
              className="form-control mb-2"
              name="discount"
              onChange={(e) => {
                const tag = tags.find(
                  (tag) => tag.id === Number(e.target.value)
                );
                const dataAlert = {
                  title: "Tem certeza que deseja Aplicar esse desconto?",
                  confirmBtnText: "Sim, tenho!",
                  confirmBtnBsStyle: "warning",
                  onConfirm: async () => {
                    await addDiscount({
                      tag_id: tag.id,
                      portfolio_id: item.id,
                      customer_id: item.company_id,
                    })
                      .then((response) => {
                        loadData();
                        const msg = response.data?.resposta?.conteudo?.message;
                        notification("Sucesso", msg, "success");
                      })
                      .catch((error) => {
                        if (
                          error &&
                          error.response?.data?.resposta?.conteudo?.messages
                        ) {
                          const messages =
                            error.response.data.resposta.conteudo.messages;
                          Object.keys(messages).forEach((key) => {
                            messages[key].forEach((message) => {
                              notification("Ops!!", message, "danger");
                            });
                          });
                          return;
                        }
                        notification(
                          "Ops!!",
                          "Erro interno no servidor!",
                          "danger"
                        );
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                    setAlertData(null);
                  },
                  onCancel: () => {
                    setAlertData(null);
                  },
                  warning: true,
                  content: () => (
                    <div
                      style={{
                        border: "1px solid #ffb236",
                        padding: "1rem",
                        textAlign: "left",
                        borderRadius: "5px",
                      }}
                    >
                      <div>
                        Nome: <strong>{tag.name}</strong>
                      </div>
                      <div>
                        Desconto: <strong>{tag.discount}%</strong>
                      </div>
                    </div>
                  ),
                };
                setAlertData(dataAlert);
              }}
              value={""}
            >
              <option value={""}>Aplicar desconto</option>
              {tags.map((tag) => (
                <option key={tag.id} className="p-5" value={tag.id}>
                  {tag.discount}% - {tag.name}
                </option>
              ))}
            </select>
            
          ) : null}
        <Link
          to={`/empresa/${item.company_id}`}
          className="btn btn-yellow btn-block"
        >
          Ver perfil
        </Link>
      </div>
    </li>
  );
};

export default ItemProcurar;
